/* eslint-disable react-hooks/exhaustive-deps */
import { CustomCheckbox, Modal, Pagination } from 'Components';
import { client, useAppDispatch, useT } from 'Config';
import { zeroFill } from 'Helpers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { mediaCampaign } from '../lib';

interface AssignAssetProps {
  campaignId: number;
  mediaTypeId: number;
  isOpen: boolean;
  onRequestClose: Function;
}

export default function AssignAsset({ campaignId, mediaTypeId, isOpen, onRequestClose }: AssignAssetProps) {
  const t = useT().mediaCampaign;
  const dispatch = useAppDispatch();
  const [searchAssetID, setSearchAssetID] = useState<string>();
  const [searchCampaignName, setSearchCampaignName] = useState<string>();
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 1,
    total_count: 0,
    total_pages: 0,
  });
  const [assets, setAssets] = useState<AssetWithCampaigns[]>();
  const [assetSelected, setAssetSelected] = useState<AssetWithCampaigns>();
  let typingTimerAssetID: NodeJS.Timeout; //timer identifier AssetID
  let typingTimerCampaignName: NodeJS.Timeout; //timer identifier CampaignName
  const doneTypingInterval = 1000; //time in ms (1 seconds)

  useEffect(() => {
    if (isOpen) {
      resetModal();
      applyAssetsFilter();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      applyAssetsFilter();
    }
  }, [searchAssetID, searchCampaignName]);

  const setPage = (page) => {
    setLoadingAssets(true);
    setAssetSelected(undefined);
    client
      .getAssetsList({ page: page, id: searchAssetID, campaign_name: searchCampaignName })
      .then(({ data }) => {
        setPagination(data.pagination_meta);
        setAssets(data.assets);
      })
      .finally(() => setLoadingAssets(false));
  };

  const applyAssetsFilter = () => {
    setLoadingAssets(true);
    setAssetSelected(undefined);
    client
      .getAssetsList({ id: searchAssetID, campaign_name: searchCampaignName })
      .then(({ data }) => {
        setPagination(data.pagination_meta);
        setAssets(data.assets);
      })
      .finally(() => setLoadingAssets(false));
  };

  const assignAsset = () => {
    if (assetSelected) {
      dispatch(mediaCampaign.assignAsset(campaignId, mediaTypeId, assetSelected.id)).finally(() => onCloseModal());
    }
  };

  const onCloseModal = () => {
    resetModal();
    onRequestClose();
  };

  const resetModal = () => {
    setSearchAssetID(undefined);
    setSearchCampaignName(undefined);
    setAssets(undefined);
    setAssetSelected(undefined);
  };

  const headerModal = (
    <div className="flex flex-col gap-2 items-start flex-1">
      <h3 className="text-xl font-extrabold break-all flex-1">{t.modalAssignAsset.title}</h3>
      <h4 className="text-base break-all flex-1">{t.modalAssignAsset.subtitle}</h4>
    </div>
  );

  const footerModal = (
    <>
      <div className="flex justify-end gap-2 pt-5 border-t border-solid border-gray-20">
        <button
          title={t.modalAssignAsset.no}
          aria-label={t.modalAssignAsset.no}
          className="py-2 px-4 whitespace-nowrap w-40 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
          onClick={() => onCloseModal()}
        >
          {t.modalAssignAsset.no}
        </button>

        <button
          id="button-submit"
          title={t.modalAssignAsset.yes}
          aria-label={t.modalAssignAsset.yes}
          disabled={!assetSelected || loadingAssets}
          onClick={() => assignAsset()}
          className="py-2 px-4 whitespace-nowrap w-40 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl disabled:cursor-default disabled:opacity-70"
        >
          {t.modalAssignAsset.yes}
        </button>
      </div>
    </>
  );
  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => onCloseModal()}
      bodyClasses="py-6 px-1 overflow-auto h-[100vh]"
      header={headerModal}
      footer={footerModal}
    >
      <div className="flex gap-[13px] flex-col">
        <div id={'assign-assets-form'} className="flex flex-col gap-y-4">
          <div className="inline-flex flex-row gap-x-8">
            <div className="flex flex-col w-[160px]">
              <h5 className="text-sm font-extrabold">{t.asset_id}</h5>
              <Input
                className="text-sm w-[160px] min-w-[160px]"
                active={!!searchAssetID}
                onChange={(e) => {
                  const searchAsset = e.currentTarget.value;
                  clearTimeout(typingTimerAssetID);
                  typingTimerAssetID = setTimeout(function () {
                    setSearchAssetID(searchAsset);
                  }, doneTypingInterval);
                }}
                placeholder={t.modalAssignAsset.search}
                title={`${t.modalAssignAsset.search} ${t.asset_id}`}
                name="assetID"
                defaultValue={searchAssetID || ''}
                autoComplete="off"
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-sm font-extrabold">{t.modalAssignAsset.first_created_for_action}</h5>
              <Input
                className="text-sm max-w-[415px] min-w-[220px]"
                active={!!searchCampaignName}
                onChange={(e) => {
                  const searchCampaign = e.currentTarget.value;
                  clearTimeout(typingTimerCampaignName);
                  typingTimerCampaignName = setTimeout(function () {
                    setSearchCampaignName(searchCampaign);
                  }, doneTypingInterval);
                }}
                placeholder={t.modalAssignAsset.search}
                title={`${t.modalAssignAsset.search} ${t.modalAssignAsset.first_created_for_action}`}
                name="campaignName"
                defaultValue={searchCampaignName || ''}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            {assets && !!assets.length ? (
              assets.map((asset, index) => (
                <Row
                  key={`assign-asset-${asset.id}-${index}`}
                  className="inline-flex flex-row gap-x-8"
                  aria-disabled={loadingAssets}
                  disabled={loadingAssets}
                >
                  <CustomCheckbox
                    checked={assetSelected?.id === asset.id}
                    classesLabel="items-start items-center w-[160px] min-w-[160px]"
                    style={{ height: 16, width: 16 }}
                    onChange={() => {
                      setAssetSelected(asset);
                    }}
                  >
                    {!loadingAssets ? (
                      <div className="text-sm whitespace-nowrap font-bold">{zeroFill(asset.id, 6, 'A-')}</div>
                    ) : (
                      <div className="flex-1 h-full bg-gray-20 "></div>
                    )}
                  </CustomCheckbox>
                  {!loadingAssets ? (
                    <div
                      className="text-sm break-all hover:cursor-pointer"
                      onClick={() => {
                        setAssetSelected(asset);
                      }}
                    >
                      {asset.campaigns.map((campaign, index) => (
                        <span key={`campaign_name_${index}`}>
                          {index > 0 && ', '}
                          <span className="font-bold">{campaign.campaign_name}</span>
                        </span>
                      ))}
                    </div>
                  ) : (
                    <div className="flex-1 h-[20px] bg-gray-20"></div>
                  )}
                </Row>
              ))
            ) : (
              <div className="text-xl text-gray-60 font-bold py-4">{t.modalAssignAsset.no_assets_found}</div>
            )}
          </div>
        </div>

        <Pagination
          currentPage={pagination.current_page}
          onSelect={setPage}
          totalCount={pagination.total_count}
          totalPages={pagination.total_pages}
        />
      </div>
    </Modal>
  );
}

const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-30 border-[1px] shadow rounded-lg p-2 focus:text-black`,
  active && tw`text-black border-gray-90`, // Then add conditional styles
]);

const Row = styled.div(({ disabled }: { disabled?: boolean }) => [
  disabled && tw`cursor-default pointer-events-none opacity-60`, // Then add conditional styles
]);
