import { useT } from 'Config';
import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface NewCommentProps {
  title: string;
  placeholder: string;
  onCancel?: () => void;
  onAddComment: (newComment: string) => void;
}

export default function NewComment({ title, placeholder, onCancel, onAddComment }: NewCommentProps) {
  const t = useT();
  const tCampaignComments = t.campaignComments;
  const [newComment, setNewComment] = useState<string>('');

  return (
    <div className="flex flex-col gap-y-2 bg-gray-0 p-2 border-[1px] rounded-lg border-gray-0 shadow min-w-[280px] sm:p-6">
      <h3 className="text-base font-extrabold text-gray-90">{title}</h3>
      <TextArea
        id="description"
        className="text-sm w-full max-w-[100%]"
        active={!!newComment}
        name="description"
        placeholder={placeholder}
        rows={2}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setNewComment(e.target.value);
        }}
        value={newComment}
      ></TextArea>
      <div className="flex flex-wrap flex-col justify-end gap-2 sm:flex-row">
        <button
          title={tCampaignComments.newComment.abort}
          aria-label={tCampaignComments.newComment.abort}
          className="py-2 px-4 w-40 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-lg disabled:opacity-70 disabled:cursor-default"
          onClick={() => {
            setNewComment('');
            onCancel && onCancel();
          }}
          disabled={!newComment && !onCancel}
        >
          {tCampaignComments.newComment.abort}
        </button>

        <button
          title={tCampaignComments.newComment.leave_comment}
          aria-label={tCampaignComments.newComment.leave_comment}
          className="py-2 px-4 w-40 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-lg disabled:opacity-70 disabled:cursor-default"
          disabled={!newComment}
          onClick={() => {
            setNewComment('');
            onAddComment(newComment);
          }}
        >
          {tCampaignComments.newComment.leave_comment}
        </button>
      </div>
    </div>
  );
}

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 shadow rounded-lg p-2 focus:text-black `,
  active && tw`text-black`, // Then add conditional styles
]);
