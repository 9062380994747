/* eslint-disable react-hooks/exhaustive-deps */
import { Page, Spinner } from 'Components';
import Trans from 'Components/Trans';
import { client, useAppDispatch, useAppSelector, useT } from 'Config';
import { zeroFill } from 'Helpers';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import OutputMediaHeader from './components/OutputMediaHeader';
import OutputMediaList from './components/OutputMediaList';
import TaskOverview from './components/TaskOverview';
import { mediaCampaign } from './lib';
import { mediaTypes } from 'Features/MediaTypes';
import FineData from './views/FineData';

export default function MediaCampaign({ viewFineData = false }: { viewFineData?: boolean }) {
  const t = useT();
  const dispatch = useAppDispatch();
  const params = useParams<{ campaignId: string; fineData: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const campaign = useAppSelector(mediaCampaign.getCampaign);
  const mediaTypesStore = useAppSelector(mediaTypes.selectMediaTypes);

  const campaignOutputMediaTypes = campaign?.output_media_types || [];
  const outputMediaTypesOrdered = mediaTypesStore
    .reduce((acc: CampaignMediaType[], mediaType) => {
      const campaignOutputMedia = campaignOutputMediaTypes.find((cam) => cam.id === mediaType.id);
      if (campaignOutputMedia) {
        acc.push(campaignOutputMedia);
      }
      return acc;
    }, [])
    .concat(campaignOutputMediaTypes.filter((campaignMediaType) => !mediaTypesStore.some((mediaType) => mediaType.id === campaignMediaType.id)));

  useEffect(() => {
    setLoading(true);
    dispatch(mediaCampaign.fetch(Number(params.campaignId)))
      .then(() => client.postVisit(Number(params.campaignId), 'desk'))
      .finally(() => setLoading(false));
  }, [params]);

  return (
    <Page key={'page-media'}>
      <Helmet>
        <title>{`${t.mediaCampaign.page_title}${viewFineData ? ` - ${t.mediaCampaign.fine_data}` : ''}`}</title>
      </Helmet>
      <div className="flex flex-row h-full w-full overflow-auto mx-[-10px] px-[10px]">
        {loading ? (
          <div className="flex h-40 mx-auto">
            <Spinner size={60} />
          </div>
        ) : campaign === undefined ? (
          <div className="flex flex-col justify-start items-start gap-2.5 py-8">
            <h2>
              <Trans
                id={`msg-no-campaign-found`}
                {...{ value: t.mediaCampaign.no_campaign_found, campaignId: zeroFill(params.campaignId, 6, 'E-') }}
              />
            </h2>
          </div>
        ) : viewFineData ? (
          <FineData campaign={{ ...campaign, output_media_types: outputMediaTypesOrdered }}></FineData>
        ) : (
          <>
            <div className="flex-1 flex flex-col border-r-2 border-gray-20 w-[70%] min-w-[650px]">
              <OutputMediaHeader campaign={campaign} />
              <OutputMediaList campaign={{ ...campaign, output_media_types: outputMediaTypesOrdered }} />
            </div>
            <div className="flex-none w-[30%] min-w-[300px]">
              <TaskOverview campaign={campaign} />
            </div>
          </>
        )}
      </div>
    </Page>
  );
}
