import './index.css';
import '@reach/menu-button/styles.css';

import React from 'react';
import { Menu, MenuList, MenuButton, MenuItem, /*MenuItems, MenuPopover,*/ MenuLink } from '@reach/menu-button';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface MenuProps {
  className?: string;
  containerClass?: string;
  Icon?: React.ReactNode;
  label: string;
  inline?: boolean;
  items: Array<
    | {
        label: string;
        href: string;
        props?: any;
      }
    | {
        label: string;
        onClick: () => void;
        props?: any;
      }
  >;
}

export function DropDownMenu(props: MenuProps) {
  const { Icon = '▾', label } = props;
  return (
    <Container inline={!!props.inline} className={props.containerClass}>
      <Menu>
        <MenuButton className={props.className}>
          {label} <span aria-hidden>{Icon}</span>
        </MenuButton>
        <MenuList portal={!props.inline}>
          {(props.items || []).map((item) =>
            'href' in item ? (
              <MenuLink as={NavLink} to={item.href} key={item.href} {...item.props}>
                <span aria-hidden>{Icon}</span>
                {item.label}
              </MenuLink>
            ) : (
              <MenuItem key={item.label} onSelect={item.onClick}>
                {item.label}
              </MenuItem>
            )
          )}
        </MenuList>
      </Menu>
    </Container>
  );
}

const Container = styled.div<{ inline: boolean }>`
  ${(props) =>
    props.inline &&
    `
    position: relative;
    z-index: 1;
  > button{
    height:100%;
  }

  [data-reach-menu-popover]{
    left:0;
    right:0;
  }
    
  `}
`;
