import PropTypes from 'prop-types';
import { Fragment } from 'react';

const parseString = (text) => {
  const ctx = text.match(/%{([^}]+)}/g);
  return ctx === null ? [] : ctx.map((res) => res.replace(/{|}|%/g, ''));
};

export const tReplace = (transValue, options) => {
  let str = transValue;
  if (typeof str !== 'string') {
    console.error('error in translation:' + transValue, options);
  }
  if (Array.isArray(str)) {
    return str;
  }
  const vars = parseString(str);
  return vars.length === 0 ? str : vars.reduce(relacePlaceholders(options), str);
};

function relacePlaceholders(options) {
  return function mapper(carry, item) {
    if (item in options === false) {
      return carry;
    }

    let keyword = `%{${item}}`;
    return carry.replace(keyword, options[item]);
  };
}

const Trans = (props) => {
  const { id, value, ...rest } = props;
  return <Fragment>{tReplace(value, rest)}</Fragment>;
};

Trans.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Trans;
