import { useEffect } from 'react';

declare global {
  interface Window {
    FreshworksWidget: any;
    fwSettings: { widget_id: string };
  }
}
export default function Scripts() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    const { REACT_APP_FRESHDESK_URL, REACT_APP_FRESHDESK_ID } = process.env;
    const script = document.createElement('script');

    if (REACT_APP_FRESHDESK_ID && REACT_APP_FRESHDESK_URL) {
      window.fwSettings = { widget_id: REACT_APP_FRESHDESK_ID };
      if (typeof window.FreshworksWidget !== 'function') {
        const n = (...args) => n.q.push(args);
        n.q = [];
        window.FreshworksWidget = n;
      }
      script.type = 'text/javascript';
      script.src = REACT_APP_FRESHDESK_URL;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
}
