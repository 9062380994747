import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Page, Spinner } from 'Components';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import styled from 'styled-components';
import { GlobalScrollBarStyles } from 'Components/StickyTable';
import OverviewHeader from './components/OverviewHeader';
import CampaignsTable from './components/CampaignsTable';
import { campaigns } from 'Features/Campaigns';

export default function HomePage() {
  const t = useT();
  const dispatch = useAppDispatch();
  const resetFilter = useAppSelector(campaigns.resetFilter);
  const [isFilterSet, toggleisFilterSet] = useState<boolean>(false);

  useEffect(() => {
    if (resetFilter) {
      dispatch(campaigns.actions.setToInitialState());
    } else {
      dispatch(campaigns.actions.setResetFilter(true));
    }
    toggleisFilterSet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t.homePage.title}</title>
      </Helmet>
      <GlobalScrollBarStyles />
      <Page>
        <Container className="flex flex-col gap-6 my-4 mt-10">
          {!isFilterSet ? (
            <Spinner size={60} strokeColor="var(--primary)" />
          ) : (
            <>
              <OverviewHeader />
              <CampaignsTable />
            </>
          )}
        </Container>
      </Page>
    </>
  );
}

const Container = styled.div``;
