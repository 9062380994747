import axios, { AxiosError } from 'axios';
import { authStore } from 'Features/Authentication/lib';
import * as Sentry from '@sentry/react';

import { API_TIMEOUT, API_URL, BACKEND_BASE_URL, SSO_URL } from '../Env';

export const api = axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
});

export const ssoApi = axios.create({
  baseURL: SSO_URL,
  timeout: API_TIMEOUT,
});

export const setupInterceptors = (store: any) => {
  let newTokenPromise: Promise<string> = Promise.resolve('');
  let isRefreshInProgress = false;

  const logout = () => store.dispatch(authStore.actions.logout());
  const getNewToken = async (refreshToken: string): Promise<string> => {
    const { data } = await api.post(
      '/resources/token',
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
      { baseURL: `${BACKEND_BASE_URL}/smart_sso_layer/api/v1` }
    );

    if (data.error) {
      // the request can be successfully completed but the data contains an error
      throw data;
    }

    store.dispatch(authStore.actions.set({ ...data }));
    return data.access_token as string;
  };

  api.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async function (axiosError: AxiosError) {
      console.error(axiosError);

      const { config: originalRequest, response } = axiosError;

      if (response?.status !== 401) {
        return Promise.reject(axiosError);
      }

      if (response?.status === 401) {
        Sentry.captureException(axiosError);
      }

      const refreshToken = authStore.selectRefreshToken(store.getState());
      if (originalRequest?.['isRetryAttempt'] || !Boolean(refreshToken)) {
        logout();
        return Promise.reject(axiosError);
      }

      try {
        if (!isRefreshInProgress) {
          newTokenPromise = getNewToken(refreshToken);
          isRefreshInProgress = true;
        }
        const newToken = await newTokenPromise;
        if (originalRequest) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          originalRequest['isRetryAttempt'] = true;
          try {
            return await axios(originalRequest);
          } catch (error) {
            logout();
            throw error;
          }
        }
      } catch (error) {
        logout();
        return Promise.reject(axiosError);
      } finally {
        isRefreshInProgress = false;
      }
    }
  );
};

api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
ssoApi.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
