import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { actions } from './model';

export const resetComments = () => actions.reset();

export const fetch =
  (
    campaignID: number,
    pagination?: {
      page?: number;
      per_page?: number;
      parent_comment_id?: number;
    },
    sort?: string
  ): AppAsyncThunk =>
  (dispatch, _getState) => {
    return client
      .getCampaignComments(campaignID, {
        sort: sort || '-updated_at',
        ...pagination,
      })
      .then(({ data }) => {
        dispatch(actions.set(data));
        data.comments.forEach((comment) => {
          dispatch(fetchChildren(campaignID, comment.id));
        });
      });
  };

export const fetchChildren =
  (campaignID: number, parentCommentId: number, page?: number): AppAsyncThunk =>
  (dispatch, _getState) => {
    dispatch(actions.loadingChildren({ parentCommentId: parentCommentId }));
    return client
      .getCampaignComments(campaignID, {
        parent_comment_id: parentCommentId,
        page: page,
      })
      .then(({ data }) => {
        dispatch(actions.setChildren({ comments: data, parentCommentId: parentCommentId }));
      });
  };

export const addNewComments =
  (campaignId: number, text: string, parentCommentId?: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client
      .postNewComment(campaignId, text, parentCommentId)
      .then(() => (parentCommentId ? dispatch(fetchChildren(campaignId, parentCommentId)) : dispatch(fetch(campaignId))));

export const deleteComments =
  (campaignId: number, commentId: number, parentCommentId: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client
      .deleteComment(campaignId, commentId)
      .then(() => (parentCommentId ? dispatch(fetchChildren(campaignId, parentCommentId)) : dispatch(fetch(campaignId))));

export const editComment =
  (campaignId: number, commentId: number, text: string, parentCommentId: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client
      .editComment(campaignId, commentId, text)
      .then(() => (parentCommentId ? dispatch(fetchChildren(campaignId, parentCommentId)) : dispatch(fetch(campaignId))));
