export * from './ScrollToTop';
export * from './style';
export * from './formaters';
export * from './breakpoint';
export * from './useQueryParams';

// check if file extention is a video or image
export const isVideo = (url: string) => {
  const ext = String(url.split('.').pop());
  return ['mp4', 'webm', 'ogg', 'ogv', 'mov', 'wmv', 'flv', 'avi'].includes(ext);
};
