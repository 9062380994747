import { CustomDropDown } from 'Components';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import { API_URL } from 'Env';
import { authStore } from 'Features/Authentication/lib';
import { attemptLogout } from 'Features/Authentication/lib/actions';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { ScaleIconUserFileUser } from '@telekom/scale-components-react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { AppNavbar } from './AppNavbar';
import { BrandBar } from './BrandBar';
import { ROLE_ADMIN, ROLE_EDITOR, ROLE_SUPER_ADMIN } from 'Components/constants';
import { useUserHasRights } from 'Components/hooks/useUserHasRights';

export function AppHeader() {
  const t = useT().appHeader;
  const location = useLocation();
  const SCROLL_THESHOLD = 50;
  const user = useAppSelector(authStore.selectUser);
  const [s, ss] = useState(document.documentElement.clientWidth < 800);
  const dispatch = useAppDispatch();
  const hasRights = useUserHasRights();

  const logout = useCallback(() => {
    dispatch(attemptLogout(true)).finally(() => window.location.reload());
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const node = window.pageYOffset;
      const forceHide = document.documentElement.clientWidth < 800;
      if (node > SCROLL_THESHOLD || forceHide) {
        ss(true);
      } else if (node < SCROLL_THESHOLD) {
        ss(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    let i: NodeJS.Timeout;
    function updateSize() {
      const forceHide = document.documentElement.clientWidth < 800;
      clearTimeout(i);
      i = setTimeout(() => {
        ss(forceHide ? true : window.pageYOffset > 15);
      }, 100);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      clearTimeout(i);
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return (
    <>
      <Container className={`${s ? 'hide' : ''} shadow-xl`}>
        <BrandBar hide={s} />
        <AppNavbar data={[]} hide={s}>
          <ul className="flex px-4">
            <li className="ring-black font-bold pt-2 px-4">
              <CustomDropDown
                label=""
                {...('/profile' === location.pathname && { classes: 'active' })}
                showChevron={false}
                itemsLabel={`${user?.first_name} ${user?.last_name}`}
                Icon={<ScaleIconUserFileUser size={30} />}
                items={[
                  { label: t.dropDownOptions.profile, href: '/profile' },
                  ...(hasRights ? [{ label: t.dropDownOptions.my_space, href: '/dashboard' }] : []),
                  ...(user?.role.toLowerCase() === ROLE_SUPER_ADMIN ||
                  user?.role.toLowerCase() === ROLE_ADMIN ||
                  user?.role.toLowerCase() === ROLE_EDITOR
                    ? [
                        {
                          label: t.dropDownOptions.admin,
                          href: `${API_URL || ''}`.split('/').slice(0, -3).join('/') + '/admin',
                          newTab: true,
                        },
                      ]
                    : []),
                  { label: t.dropDownOptions.logOut, onSelect: logout },
                ]}
              />
            </li>
          </ul>
        </AppNavbar>
      </Container>
    </>
  );
}
const Container = styled.div`
  position: fixed;
  display: flex;
  flex-flow: col nowrap;
  align-items: flex-end;
  padding-top: 68px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  top: 0;
  width: 100%;
  z-index: 99;

  + main {
    flex: 1;
    --navbar-height: 118px;
    position: relative;
    padding-top: var(--navbar-height);
    border-radius: 66px;
  }
  &.hide {
    position: fixed;
    padding-top: 4px;
  }
`;
