import { useAppDispatch, useAppSelector, useT } from 'Config';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import NewComment from '../components/NewComment';
import Comment from '../components/Comment';
import { campaignComments } from '../lib';
import { Spinner } from 'Components';
import { Pagination } from 'Components/Pagination';
import { ScaleIconNavigationLeft } from '@telekom/scale-components-react';

interface Props {
  campaign: Campaign;
}

export default function Comments({ campaign }: Props) {
  const t = useT();
  const tCampaignComments = t.campaignComments;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loadingComments, setLoading] = useState<boolean>(false);

  const pagination = useAppSelector(campaignComments.getPagination);
  const comments = useAppSelector(campaignComments.getComments);

  useEffect(() => {
    if (campaign.id) {
      setLoading(true);
      dispatch(campaignComments.fetch(campaign.id)).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.id]);

  const addNewComment = (commentText: string) => {
    commentText && dispatch(campaignComments.addNewComments(campaign.id, commentText));
  };

  const setPage = (page) => {
    setLoading(true);
    dispatch(
      campaignComments.fetch(campaign.id, {
        page: page,
      })
    ).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="flex flex-col justify-start items-start gap-2.5 py-8 pt-10 w-full">
      <button
        className="flex flex-nowrap gap-2 items-center text-2xl text-primary-500"
        title={t.global.back}
        aria-label={t.global.back}
        onClick={() => {
          navigate(`/campaign/${campaign.id}`);
        }}
      >
        <ScaleIconNavigationLeft size={16} />
        <span className="lowercase text-sm">{t.global.back}</span>
      </button>

      <div className="flex items-start flex-col justify-between pt-6 gap-4 text-left whitespace-nowrap w-full lg:flex-row lg:items-center">
        <h2 className="text-3xl text-gray-90 font-extrabold whitespace-pre-wrap">{tCampaignComments.page_title}</h2>
      </div>

      <div className="w-full">
        <NewComment
          title={tCampaignComments.newComment.write_comment_title}
          placeholder={tCampaignComments.newComment.write_comment}
          onAddComment={addNewComment}
        ></NewComment>
      </div>

      {loadingComments ? (
        <div className="flex h-40 mx-auto">
          <Spinner size={60} />
        </div>
      ) : (
        <div className="flex flex-col gap-2 w-full">
          {comments.map((comment) => (
            <Comment key={`comment-${comment.id}`} comment={comment} campaign={campaign}></Comment>
          ))}
          <Pagination
            currentPage={pagination.current_page}
            onSelect={setPage}
            totalCount={pagination.total_count}
            totalPages={pagination.total_pages}
          />
        </div>
      )}
    </div>
  );
}
