import MatomoGateway from 'Components/MatomoGateway';
import { SplashScreen } from 'Components/SplashScreen';
import { api, ssoApi, useAppDispatch, useAppSelector } from 'Config';
import { APP_UID, BACKEND_BASE_URL } from 'Env';
import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { authStore } from './lib';
import LoginPage from './views/loginPage';

export const AuthProvider: React.FC<{ App: React.ComponentType<any> }> = ({ App }) => {
  const isLoggedIn = useAppSelector(authStore.selectUser);
  const rehydrated = useAppSelector<any>((s) => s?._persist?.rehydrated);

  if (!rehydrated) return <SplashScreen />;
  api.defaults.headers.common.Authorization = isLoggedIn?.access_token ? `Bearer ${isLoggedIn?.access_token}` : undefined;
  ssoApi.defaults.headers.common.Authorization = isLoggedIn?.access_token ? `Bearer ${isLoggedIn?.access_token}` : undefined;
  const cookieBannerSettings = true;

  const appRoutes = (
    <Routes>
      <Route path="/oauth/callback" element={<OnSuccess />} />
      <Route path="*" element={isLoggedIn ? <App /> : <LoginPage />} />
    </Routes>
  );

  return <>{cookieBannerSettings ? <MatomoGateway>{appRoutes}</MatomoGateway> : { appRoutes }}</>;
};

function OnSuccess() {
  const code = window.location.search.slice(6);
  const [ready, toggle] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (code) {
      api
        .post<User | any>(
          'smart_sso_layer/api/v1/resources/token',
          {
            client_id: APP_UID,
            code,
            redirect_uri: `${window.location.protocol}//${window.location.host}/oauth/callback`,
            grant_type: 'authorization_code',
          },
          { baseURL: BACKEND_BASE_URL }
        )
        .then(({ data }) => {
          if (!data || !data.access_token) {
            localStorage.clear();
            sessionStorage.clear();
          } else if (data && data.access_token) {
            api.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
            const membership_id = jwtDecode<{ user: User }>(data.access_token)?.user?.membership_id;
            dispatch(
              authStore.actions.set({
                ...data,
                membership_id,
              })
            );
          }
        })
        .catch(console.error)
        .then(() => toggle(true));
    } else {
      toggle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) {
    return <SplashScreen />;
  }

  return <Navigate to={sessionStorage.getItem('originalpath') || '/'} />;
}
