export const global = {
  e_media: 'E-Media',
  back: 'Back',
  info: 'Information',
  comments: 'Comments',
  completed: 'Completed',
  date: 'Date',
  claim: 'Life is for sharing.',
  to_home_page: 'To home page',
  back_to_overview: 'Back to Overview',
};

export const login = {
  welcome: 'Welcome !',
  greet: (name = '') => `Welcome back ${name} to Amana Trading`,
};
export const appHeader = {
  new_media: 'New E-media campaign',
  overview: 'Overview',
  overview_all_campaigns: 'Overview of all campaigns/actions',
  dropDownOptions: {
    profile: 'Profil',
    my_space: 'My space',
    admin: 'Admin',
    logOut: 'Vom SSO abmelden',
  },
  serviceCategory: {
    selfService: 'Self-Service',
    faqs: 'FAQs',
    help: 'Hilfe',
  },
};

export const customAccordion = {
  hide: 'Verbergen',
  show: 'Anzeigen',
};

export const pagination = {
  first_page: 'First Page',
  previous_page: 'Previous Page',
  next_page: 'Next Page',
  last_page: 'Last Page',
  out_of: 'of',
  page: 'Page',
  go_to_page: 'Go to Page',
  go: 'Go',
};

export const flashMessages = {
  cart: {
    patch_success: 'Artikel wurde(n) erfolgreich in den Warenkorb gelegt',
    patch_error: 'Artikel konnte(n) nicht in den Warenkorb gelegt werden',
    delete_success: 'Artikel wurde(n) erfolgreich aus dem Warenkorb gelöscht',
    delete_error: 'Artikel konnte(n) nicht aus dem Warenkorb gelöscht werden',
  },
  assets_download: {
    success: 'Artikel wurde(n) erfolgreich heruntergeladen',
    error: 'Artikel konnte(n) nicht heruntergeladen werden',
  },
  assets_share: {
    success: 'Die E-Mail wurde erfolgreich gesendet',
    error: 'Die E-Mail konnte nicht gesendet werden',
  },
  excel_download_error: 'Report could not be downloaded',
  server_failure: 'Ops, Serverfehler!',
  server_500: 'Anfrage fehlgeschlagen. Bitte kontaktieren Sie den Systemadministrator.',
  server_404: 'Keine Route stimmt überein mit',
  server_422: 'Something went wrong, please try again!',
};

export const homePage = {
  title: 'Overview of all actions',
  dropdown_label: 'Show:',
  find_asset_id: 'Find Asset ID:',
  excel_download: 'Excel download',
  activated_output_media: 'Activated',
  deactivated_output_media: 'Deactivated',
  title_output_media: 'Output media',
  all: 'All',
  selected: 'selected',
  dropdownOptions: {
    all: 'All actions',
    active: 'All active actions',
    upcoming_campaigns: 'All upcoming campaigns',
    running_campaigns: 'All running campaigns',
    past_campaigns: 'All Past Actions',
    disabled_campaigns: 'All deactivated campaigns',
    output_media_without_asset_id: 'All Actions with output media without an Asset-ID',
    selected: '%{selections} of %{options} selected',
    campaigns_with_asset_id_no_finedata: 'All actions with asset IDs without fine data',
  },
  assetStatus: {
    ready: 'ready for download',
    in_progress: 'in progress',
    rejected: 'rejected',
  },

  tabel: {
    id: 'ID',
    name: 'Action',
    placement: 'Placement',
    download: 'Herunterladen',
    start_date: 'Online from',
    end_date: 'Last day',
    search: 'Search',

    to_the_desk: 'to the Desk',
    action_details: 'Action details',
    download_all_files: 'Download all files',
    download_files_preview: 'Download all preview files',
    replaced_by: 'Is replaced by',
    no_id_yet: 'no ID yet',
    unlimited: 'unlimited',
    no_output_medium: 'No output medium',
    no_actions: 'There are no actions',
  },
  new_media: 'New E-media campaign',
};

export const newMedia = {
  page_title: 'New E-media campaign',
  enter_data: 'Enter all required data.',
  form: {
    id: 'ID',
    name: 'Name',
    online_from: 'Online on',
    online_until: 'Last display day',
    online_until_validity: 'Please select a date or check "Unlimited term"',
    unlimited_term: 'Unlimited term',
    description: 'Description',
    checkbox_label: 'Does this action replace an existing action?',
    checkbox_description: 'Yes, the following action will be replaced:',
    action_already_blocked: 'his campaign has already been replaced by %{campaignId}. Please choose another!',
    action_not_existing: 'This action does not exist.',
    action_selected_is_valid: 'The selected action is valid',
    action_replace_remove: 'No action will be substituted',
    add_action_ad: 'EMA ID must be filled!',
  },
  discard: 'Discard',
  create: 'Create',
  campaign_created: 'The campaign was created successfully',
  no_actions: 'There are no actions',
};

export const copyMedia = {
  page_title: 'Copy E-Media-Aktion',
  form: {
    action_name: 'Action name',
    start_date: 'Online from',
    end_date: 'Last day',
    replace_action: 'Replace copied Action',
    replace_action_hint: 'If you select "Yes", the end date of the copied action will be adjusted automatically.',
  },
  discard: 'Discard',
  create: 'Create',
  copy: 'Copy',
  yes: 'Yes',
  no: 'No',
  success: 'Campaign successfully created!',
};

export const mediaCampaign = {
  inactive_user: 'Inactive user',
  page_title: 'E-media campaign',
  no_campaign_found: 'E-media campaign with the specified ID ("%{campaignId}") not found.',
  no_asset_found: 'E-media campaign "%{campaignId}" does not contain asset "%{assetId}".',
  output_media: 'Output media',
  fine_data: 'Fine data',
  fine_data_of_action: 'Fine data of action',
  uploaded: 'uploaded',
  rejected: 'rejected',
  upload_file: 'Upload file',
  view_fine_data: 'View fine data',
  output_medium: 'Output medium',
  task_overview: 'Task Overview',
  tasks_on_desk: 'Your tasks on this desk',
  disabled: 'Disabled',
  modalDeleteOutputMedia: {
    title: 'Are you sure?',
    subtitle: 'Do you really want to delete the output medium? All entries are lost as a result.',
    no: 'No, cancel',
    yes: 'Yes, delete',
  },
  modalWerbeNetAsset: {
    title: 'Are you sure?',
    subtitle:
      'With this function you create the last uploaded file of this output medium as an asset in WerbeNet. The file can thus be made available to trading partners. Do you want to continue?',
    no: 'No, cancel',
    yes: 'Yes, create AdvertisingNet Asset',
  },
  werbeNetAssetCreation: {
    success: 'WerbeNet Asset was successfully created',
    error: "WerbeNet Asset wasn't created!",
  },
  werbeNetAsset: 'Create "WerbeNet" Asset',
  tasksMessages: {
    create_output_media: 'Create the output media for this EMA',
    completed_all_tasks: 'You have no open tasks on this desk.',
    generate_asset_id: 'Generate an asset ID for the output medium %{mediaTypeName}.',
    upload_data: 'Upload the fine data for asset %{assetId}.',
  },
  addMedia: {
    title: 'Select output media',
    subtitle: 'Check the output media that you want to add to the action.',
    select_all: 'select all',
    deselect_all: 'deselect all',
    selection_from: 'Accept selection from:',
    no_selected: 'no EMA selected',
    discard_selection: 'Discard selection',
    create_selection: 'Create selection',
  },
  assetTable: {
    status: 'Status',
    version: 'Version',
    preview: 'Preview',
    file_name: 'File Name',
    uploaded_on: 'Uploaded on',
    uploaded_by: 'Uploaded by',
    comment: 'Comment',
    reason_rejection: 'Reason for rejection',
    correction_requested_on: 'Correction requested on',
    leave_comment: 'Leave a comment',
    exit: 'Exit',
    download: 'Download',
    delete: 'Delete',
    reject: 'Reject',
    modalDelete: {
      title: 'Are you sure?',
      subtitle: 'Are you sure you want to delete the file?',
      no: 'No, cancel',
      yes: 'Yes, delete',
    },
    modalReject: {
      title: 'Reject asset',
      id: 'Version',
      subtitle: 'Reason for rejection',
      no: 'Cancel',
      yes: 'Reject',
    },
  },
  modalAssignAsset: {
    title: 'Select Asset ID',
    subtitle: 'Select the asset that you want to add to the output medium.',
    first_created_for_action: 'First created for action',
    search: 'Search',
    no_assets_found: 'No assets found',
    no: 'Cancel',
    yes: 'Select Asset ID',
  },
  asset_id: 'Asset-ID',
  not_assigned: 'not yet assigned',
  generate: 'Generate',
  choose: 'Choose',
  error_download: 'Asset cannot be downloaded',
};

export const campaignComments = {
  page_title: 'Comments',
  newComment: {
    write_comment_title: 'Write comment',
    write_comment: 'Write a comment',
    write_answer_title: 'Post a reply',
    write_answer: 'Write an answer',
    abort: 'Abort',
    leave_comment: 'Leave a Comment',
  },
  edit_comment: 'Edit comment',
  delete_comment: 'Delete comment',
  update_comment: 'Update comment',
  processed: 'processed',
  exit: 'Exit',
  answer: 'Answer',
  answers_loading: 'Answers are loading',
  hide_answers: 'Hide answers',
  show_answers: 'Show answers',
  modalDelete: {
    title: 'Are you sure?',
    subtitle: 'Do you really want to delete the comment?',
    no: 'No, cancel',
    yes: 'Yes, delete',
  },
};

export const dashboard = {
  page_title: 'My space',
  tabs: {
    tasks: 'Tasks',
    comments: 'Comments',
    infos: 'Infos',
    e_mails: 'E-Mails',
  },
  tabTasks: {
    dropdown_label: 'Show:',
    filterOptions: {
      all: 'all',
      completed: 'done',
      created: 'pending',
    },
    tabel: {
      no_data: 'No data',
      status: 'Status',
      action: 'Action',
      task: 'Task',
      received_at: 'Received at',
      pending_since: 'Pending since',
      done_on: 'Done on',
      to_the_desk: 'to the Desk',
    },
  },
  tabComments: {
    dropdown_label: 'Show:',
    filterOptions: {
      all: 'all',
      new: 'new',
      old: 'old',
    },
    tabel: {
      no_data: 'No data',
      status: 'Status',
      action: 'Action',
      written_by: 'Written by',
      comment: 'Comment',
      posted: 'Posted',
      to_the_comment: 'to the comment',
    },
  },
  tabInfos: {
    dropdown_label: 'Anzeigen:',
    filterOptions: {
      all: 'alle',
      new: 'neu',
      old: 'alt',
    },
    tabel: {
      no_data: 'No data',
      status: 'Status',
      action: 'Action',
      info: 'Info',
      received_at: 'Received at',
      to_the_desk: 'to the Desk',
    },
  },
  tabEMails: {
    tab_title: 'Email settings',
    any_time: 'I would like to be informed about new tasks, comments and information by email at any time.',
    daily: 'I only want to be notified by email once a day.',
    twice_a_week: 'I only want to be notified by email twice a week.',
    disabled: "I don't want to receive emails.",
  },
};

export const assetDetails = {
  page_title: 'Asset-Details',
  information: 'Information',
  asset_id: 'Asset-ID',
  output_medium: 'Output medium',
  output_medium_type: 'Type of output medium',
  files: 'Files',
};

export const campaignDetails = {
  page_title: 'Action Details',
  edit: 'edit',
  cancel: 'cancel',
  save: 'save',
  loading_project_managers: 'Project managers are loading',
  form: {
    id: 'ID',
    name: 'Name',
    online_from: 'Online on',
    online_until: 'Last display day',
    unlimited_term: 'Unlimited term',
    description: 'Description',
    number_output_media: 'Number of Output Media',
    output_media: 'Output Media',
    created_on: 'Created on',
    created_by: 'Created by',
    last_changed_at: 'Last changed at',
    project_manager: 'Project Manager',
    job_number: 'Job Number',
    not_specified: 'not specified',
    replaced_by: 'Is being replaced by',
    replacing_action: 'Is replacing the following action',
    placement_category: 'Placement category',
  },
  confirmationModal: {
    title: 'Are you sure?',
    subtitle: 'Do you really want to save the changes?',
    deactivate_action: 'Do you really want to deactivate the action?',
    reactivate_action: 'Do you really want to reactivate the action?',
    delete_action: 'Do you really want to permanently delete the action? All data will be irrevocably lost as a result.',
    copy_action: 'Do you really want to copy the action?',
    no: 'No, cancel',
    yes: 'Yes, save',
    deactivate_yes: 'Yes, deactivate',
    reactivate_yes: 'Yes, reactivate',
    delete_yes: 'Yes, delete',
    copy_yes: 'Yes, copy',
  },

  blockedByNote: 'This action is superseded by action %{blockedById}.',
  deactivate_action: 'Deactivate Action',
  reactivate_action: 'Reactivate Action',
  delete_action: 'Delete Action',
  copy_action: 'Copy Action',
  action_is_disabled: 'This action has been disabled.',
};

export const noData = {
  title: 'We are Sorry...',
  message: 'Due to your user permissions, the view cannot be displayed to you. Please contact support.',
};
