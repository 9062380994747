import React, { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { FormatDate, zeroFill } from 'Helpers';
import { CustomCheckbox, Spinner } from 'Components';
import { useT, client } from 'Config';
import { formatISO } from 'date-fns';
import { ScaleIconActionDownload, ScaleIconAlertWarning } from '@telekom/scale-components-react';
import DownloadDoubleIcon from 'Assets/icons/DownloadDoubleIcon';
import AlertInformation from 'Assets/icons/AlertInformation';

interface CampaignsTableRowProps {
  campaign: Campaign;
  index: number;
  colsIds: number[];
  selectedIDsDownload?: Set<number>;
  setIDsDownload: Function;
  stickyColumnsSize: {
    width: number;
    left: number;
  }[];
}
export default function CampaignsTableRow({
  campaign,
  index,
  colsIds,
  stickyColumnsSize,
  selectedIDsDownload,
  setIDsDownload,
}: CampaignsTableRowProps) {
  const t = useT();
  const [showSpinnerDownload, setShowSpinnerDownload] = useState(false);
  const [showSpinnerDownloadPreview, setShowSpinnerDownloadPreview] = useState(false);
  const showDownloadIcon =
    campaign.output_media_types && campaign.output_media_types.some((mediaType) => mediaType.asset !== undefined && mediaType.asset.current_version);
  const showDownloadIconPreview =
    campaign.output_media_types &&
    campaign.output_media_types.some((mediaType) => mediaType.asset?.versions !== undefined && mediaType.asset.versions.some((v) => v.preview));

  const AssetBadge = ({ campaign, col }: { campaign: Campaign; col: string | number }) => {
    const mediaType = campaign.output_media_types.find((mediaType) => `${mediaType.id}` === `${col}`);
    return mediaType ? (
      mediaType.asset ? (
        <a
          href={`/asset/${campaign.id}/${mediaType.asset.id}`}
          className={`${
            mediaType.asset.status === 'rejected' ? 'bg-red-70' : mediaType.asset.status === 'ready' ? 'bg-green-60' : 'bg-gray-20'
          }  text-center px-2 p-1 rounded-lg block w-full`}
          {...(campaign?.status === 'disabled' ? { tabIndex: -1 } : {})}
        >
          {zeroFill(mediaType.asset.id, 6, 'A-')}
        </a>
      ) : (
        <div className="bg-gray-20 text-center px-2 p-1 rounded-lg">[{t.homePage.tabel.no_id_yet}]</div>
      )
    ) : null;
  };

  const downloadAssetFile = (campaign: Campaign, isPreview: boolean) => {
    client
      .downloadMultiCampaignAssets([campaign.id], isPreview)
      .then((response) => {
        setShowSpinnerDownload(false);
        const url = response.data.download_link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formatISO(new Date(), { representation: 'date' })}_${zeroFill(campaign.id, 6, 'E-')}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isPreview) {
          setShowSpinnerDownloadPreview(false);
        } else {
          setShowSpinnerDownload(false);
        }
      });
  };

  return (
    <TableRow
      key={`row-campaign-${index}`}
      className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-0'}`}
      inactiveCampaign={campaign?.status === 'disabled'}
    >
      <TdSticky
        style={{
          left: stickyColumnsSize[0].left,
          maxWidth: stickyColumnsSize[0].width,
          minWidth: stickyColumnsSize[0].width,
          background: 'inherit',
        }}
      >
        <div className="flex justify-start items-center gap-x-2">
          <a
            title={t.homePage.tabel.to_the_desk}
            className={`uppercase underline text-blue-40 ${campaign?.status === 'disabled' && 'opacity-40'}`}
            href={`/campaign/${campaign.id}`}
            {...(campaign?.status === 'disabled' ? { tabIndex: -1 } : {})}
          >
            {zeroFill(campaign.id, 6, 'E-')}
          </a>
          <a
            title={t.homePage.tabel.action_details}
            className="campaign-info flex justify-center hover:text-primary-500"
            href={`/info/${campaign.id}`}
          >
            <AlertInformation size={16} {...(!campaign.description_viewed && { strokeColor: 'var(--primary)' })} />
          </a>
          {showDownloadIconPreview &&
            (showSpinnerDownloadPreview ? (
              <Spinner size={16} strokeColor="var(--primary)" />
            ) : (
              <button
                className="flex justify-center hover:text-primary-500 disabled:cursor-default disabled:hover:text-gray-100 disabled:opacity-40"
                title={t.homePage.tabel.download_files_preview}
                aria-label={t.homePage.tabel.download_files_preview}
                onClick={() => {
                  setShowSpinnerDownloadPreview(true);
                  downloadAssetFile(campaign, true);
                }}
                disabled={campaign?.status === 'disabled'}
              >
                <DownloadDoubleIcon size={14} fill="none" />
              </button>
            ))}

          {showDownloadIcon &&
            (showSpinnerDownload ? (
              <Spinner size={16} strokeColor="var(--primary)" />
            ) : (
              <button
                className="flex justify-center hover:text-primary-500 disabled:cursor-default disabled:hover:text-gray-100 disabled:opacity-40"
                title={t.homePage.tabel.download_all_files}
                aria-label={t.homePage.tabel.download_all_files}
                onClick={() => {
                  setShowSpinnerDownload(true);
                  downloadAssetFile(campaign, false);
                }}
                disabled={campaign?.status === 'disabled'}
              >
                <ScaleIconActionDownload size={16} />
              </button>
            ))}

          {campaign.successor_id && (
            <div
              title={`${t.homePage.tabel.replaced_by} ${zeroFill(campaign.successor_id, 6, 'E-')}`}
              aria-label={`${t.homePage.tabel.replaced_by} ${zeroFill(campaign.successor_id, 6, 'E-')}`}
              className={`flex justify-center ${campaign?.status === 'disabled' && 'opacity-40'}`}
            >
              <ScaleIconAlertWarning size={16} className="text-primary-500" />
            </div>
          )}
        </div>
      </TdSticky>
      <TdSticky
        style={{
          left: stickyColumnsSize[1].left,
          maxWidth: stickyColumnsSize[1].width,
          minWidth: stickyColumnsSize[1].width,
          background: 'inherit',
        }}
      >
        <div className="flex items-center justify-center">
          <CustomCheckbox
            key={campaign.id}
            disabled={!showDownloadIcon}
            checked={selectedIDsDownload?.has(campaign.id)}
            classesLabel="items-start items-center"
            style={{ height: 16, width: 16 }}
            onChange={(e) => {
              if (e.target.checked) {
                selectedIDsDownload?.add(campaign.id);
                setIDsDownload(new Set(selectedIDsDownload));
              } else {
                selectedIDsDownload?.delete(campaign.id);
                setIDsDownload(new Set(selectedIDsDownload));
              }
            }}
          />
        </div>
      </TdSticky>
      <TdSticky
        style={{
          left: stickyColumnsSize[2].left,
          maxWidth: stickyColumnsSize[2].width,
          minWidth: stickyColumnsSize[2].width,
          background: 'inherit',
        }}
      >
        <div>{campaign.name}</div>
      </TdSticky>
      <TdSticky
        style={{
          left: stickyColumnsSize[3].left,
          maxWidth: stickyColumnsSize[3].width,
          minWidth: stickyColumnsSize[3].width,
          background: 'inherit',
        }}
      >
        <div>{campaign.placement_t}</div>
      </TdSticky>
      <TdSticky
        style={{
          left: stickyColumnsSize[4].left,
          maxWidth: stickyColumnsSize[4].width,
          minWidth: stickyColumnsSize[4].width,
          background: 'inherit',
        }}
      >
        <div>{FormatDate(campaign.start_date ?? '', true)}</div>
      </TdSticky>
      <TdSticky
        className="border-r-4 border-gray-20"
        style={{
          left: stickyColumnsSize[5].left,
          maxWidth: stickyColumnsSize[5].width,
          minWidth: stickyColumnsSize[5].width,
          background: 'inherit',
        }}
      >
        <div>{campaign.end_date ? FormatDate(campaign.end_date ?? '', true) : <span className="text-red-70">{t.homePage.tabel.unlimited}</span>}</div>
      </TdSticky>
      {!colsIds.length ? (
        <td className="p-2"></td>
      ) : (
        colsIds.map((n, index) => (
          <td key={`${campaign.id}-${n}-${index}`} className="p-2">
            <AssetBadge col={n} campaign={campaign} />
          </td>
        ))
      )}
    </TableRow>
  );
}

const TdSticky = tw.td`sticky z-[2] p-2`;

const TableRow = styled.tr(({ inactiveCampaign }: { inactiveCampaign?: boolean }) => [
  inactiveCampaign &&
    `
  td:not(:first-child){
    > * {
      opacity: .4;
    } 
  }
`,
]);
