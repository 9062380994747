import styled from 'styled-components';

interface CustomToggleProps {
  checked: boolean;
  toggle: (value: boolean) => void;
  disabled?: boolean;
  leftLabel?: string;
  rightLabel?: string;
  title?: string;
}

export function CustomToggle({ checked, toggle, disabled, leftLabel, rightLabel, title }: CustomToggleProps) {
  return (
    <ToggleContainer className="btn-container">
      <p className="by">{title}</p>
      <label className="switch btn-color-mode-switch">
        <input type="checkbox" name="color_mode" id="color_mode" value="1" checked={checked} onChange={(e) => toggle(e.target.checked)} />
        <label htmlFor="color_mode" data-on={rightLabel} data-off={leftLabel} className="btn-color-mode-switch-inner"></label>
      </label>
    </ToggleContainer>
  );
}
const ToggleContainer = styled.div`
  text-align: center;
  position: relative;
  height: 39px;
  label {
    font-size: 13px;
    color: #424242;
    font-weight: 500;
  }
  .btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
  }
  .btn-color-mode-switch > label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 165px;
    height: 39px;
    background: #e0e0e0;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
  }
  .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    top: 9px;
    right: 20px;
  }
  .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 70px;
    height: 33px;
    background: #d82a48;
    color: #000;
    border-radius: 26px;
    position: absolute;
    left: 2px;
    top: 3px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 6px -2px #111;
    padding: 5px 0px;
  }

  .btn-color-mode-switch > .alert {
    display: none;
    background: #ff9800;
    border: none;
    color: #fff;
  }

  .btn-color-mode-switch input[type='checkbox'] {
    cursor: pointer;
    width: 50px;
    height: 25px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0px;
  }

  .btn-color-mode-switch input[type='checkbox']:checked + label.btn-color-mode-switch-inner {
    background: #e0e0e0;
    color: #fff;
    color: #000;
  }

  .btn-color-mode-switch input[type='checkbox']:checked + label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: 90px;
    background-color: #73c354;
    cursor: pointer;
  }

  .btn-color-mode-switch input[type='checkbox']:checked + label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 15px;
    cursor: pointer;
  }
  .btn-color-mode-switch input[type='checkbox']:checked ~ .alert {
    display: block;
  }
  p.by {
    position: absolute;
    top: -25px;
    left: 20px;
    color: #000;
  }
`;
