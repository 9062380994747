import { ScaleIconActionAdd, ScaleIconUserFileAnalytics } from '@telekom/scale-components-react';
import tw from 'twin.macro';
import { Spinner } from 'Components';
import { client, useAppDispatch, useAppSelector, useT } from 'Config';
import { authStore } from 'Features/Authentication/lib';
import { campaigns } from 'Features/Campaigns';
import NewMedia from 'Features/NewMedia';
import { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { MenuWithCheckbox } from 'Components/DropDown/MenuWithCheckbox';
import { tReplace } from 'Components/Trans';
import { mediaTypes } from 'Features/MediaTypes';
import { CustomToggle } from 'Components/CustomToggle';
import CustomSelectOutputMedia from './CustomSelectOutputMedia';

export default function OverviewHeader() {
  let typingTimer: NodeJS.Timeout; //timer identifier
  let doneTypingInterval = 1000; //time in ms (1 seconds)
  const t = useT();
  const dispatch = useAppDispatch();
  const toastManager = useToasts();
  const filters = useAppSelector(campaigns.getFilters);
  const sort = useAppSelector(campaigns.getSort);
  const displayOptions = useAppSelector(campaigns.getDisplayOptions) || [];
  const pagination = useAppSelector(campaigns.getPagination);
  const [assetIdSearch, setAssetIdSearch] = useState<string | undefined>(filters.asset_id || undefined);
  const [downloadingExcel, setDownloadingExcel] = useState<boolean>(false);
  const [isOutputMediaActive, setIsOutputMediaActive] = useState(true);

  const dropdownOptions = [
    {
      id: 'active',
      name: t.homePage.dropdownOptions.active,
    },
    {
      id: 'upcoming',
      name: t.homePage.dropdownOptions.upcoming_campaigns,
    },
    {
      id: 'ongoing',
      name: t.homePage.dropdownOptions.running_campaigns,
    },
    {
      id: 'past',
      name: t.homePage.dropdownOptions.past_campaigns,
    },
    {
      id: 'disabled',
      name: t.homePage.dropdownOptions.disabled_campaigns,
    },
    {
      id: 'without_assets',
      name: t.homePage.dropdownOptions.output_media_without_asset_id,
    },
    {
      id: 'without_asset_attachments',
      name: t.homePage.dropdownOptions.campaigns_with_asset_id_no_finedata,
    },
  ];
  const [selectedOptions, setOptions] = useState<
    {
      id: string;
      name: string;
    }[]
  >(
    dropdownOptions.some((op) => displayOptions?.some((dop) => dop === op.id))
      ? dropdownOptions.filter((op) => displayOptions?.some((dop) => dop === op.id))
      : [dropdownOptions[0]]
  );
  const [showNewMedia, setShowNewMedia] = useState<boolean>(false);
  const canCreateEMA = useAppSelector(authStore.canCreateEMA);

  useEffect(() => {
    if (
      selectedOptions
        .flatMap((op) => op.id)
        .sort()
        .toString() === [...displayOptions].sort().toString()
    )
      return;
    dispatch(campaigns.actions.setDisplayOptions(selectedOptions.flatMap((op) => op.id)));
    if (!selectedOptions.length) {
      dispatch(campaigns.actions.setFilters({ ...filters, state: [] }));
      return;
    }

    dispatch(campaigns.actions.setFilters({ ...filters, state: selectedOptions.flatMap((option) => option.id) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    if ((!assetIdSearch && !filters.asset_id) || assetIdSearch === filters.asset_id) return;

    let newFilter: CampaignsFilters = {
      ...filters,
      asset_id: undefined,
    };
    if (assetIdSearch) {
      newFilter = { ...newFilter, asset_id: assetIdSearch };
    }
    dispatch(campaigns.actions.setFilters(newFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, assetIdSearch]);

  useEffect(() => {
    dispatch(mediaTypes.fetch(isOutputMediaActive));
    dispatch(
      campaigns.actions.setFilters({
        ...filters,
        deactivated_output_media: !isOutputMediaActive,
        state: isOutputMediaActive ? selectedOptions.flatMap((op) => op.id) : [],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOutputMediaActive]);

  const download = () => {
    setDownloadingExcel(true);
    client
      .exportReportOverview(filters, sort, pagination.total_count)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `aktionen-${Math.floor(Date.now() / 10000)}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        toastManager.addToast(
          <div role="alert">
            <div>{t.flashMessages.excel_download_error}</div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      })
      .finally(() => setDownloadingExcel(false));
  };

  const selectCampaignsLabel = !isOutputMediaActive
    ? '-'
    : selectedOptions.length === 0
    ? t.homePage.dropdownOptions.all
    : selectedOptions.length === 1
    ? selectedOptions[0].name
    : tReplace(t.homePage.dropdownOptions.selected, { selections: selectedOptions.length, options: dropdownOptions.length });

  return (
    <>
      {showNewMedia && <NewMedia isOpen={showNewMedia} onRequestClose={() => setShowNewMedia(false)}></NewMedia>}
      <h2 className="text-3xl text-gray-90 font-extrabold whitespace-pre-wrap">{t.homePage.title}</h2>

      <div className="flex items-start flex-col gap-4 text-left whitespace-nowrap w-full">
        <div className="flex gap-x-2 gap-y-8 items-start flex-wrap flex-col sm:flex-row sm:items-center">
          {canCreateEMA && (
            <button
              className="flex flex-nowrap gap-2 justify-center items-center w-min text-[16px] text-white bg-primary-500 h-10 rounded p-3 mr-4  disabled:cursor-not-allowed disabled:opacity-70"
              aria-label={t.homePage.new_media}
              onClick={() => {
                setShowNewMedia(true);
              }}
              disabled={!isOutputMediaActive}
            >
              <ScaleIconActionAdd style={{ stroke: 'white' }} size={16} />
              <div className="truncate">{t.homePage.new_media}</div>
            </button>
          )}
          <div className="text-base whitespace-nowrap">{t.homePage.dropdown_label}</div>

          <MenuWithCheckbox
            id={'select-campaigns'}
            buttonClasses="w-[240px] whitespace-pre-wrap
            px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700
            hover:bg-gray-200 hover:border-primary-500
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary mr-4
            disabled:cursor-not-allowed disabled:opacity-70 disabled:hover:border-gray-300 disabled:hover:bg-white"
            classesMenuList="w-[240px]"
            buttonLabel={selectCampaignsLabel}
            buttonTitle={selectedOptions.map((op) => op.name).join(', ')}
            items={dropdownOptions.map((option) => ({
              label: `${option.name}`,
              checked: selectedOptions.some((op) => op.id === option.id),
              onChange: (checked) => {
                if (checked) {
                  setOptions([...selectedOptions, option]);
                } else {
                  setOptions(selectedOptions.filter((op) => op.id !== option.id) || []);
                }
              },
              classes: `w-[238px] whitespace-pre-wrap ${selectedOptions.some((op) => op.id === option.id) && 'text-primary-500 font-extrabold'}`,
            }))}
            disabled={!isOutputMediaActive}
            showChevron={isOutputMediaActive}
          ></MenuWithCheckbox>

          <div className="flex gap-2 items-start flex-nowrap flex-col sm:flex-row sm:items-center">
            <div className="text-base whitespace-nowrap">{t.homePage.find_asset_id}</div>
            <SearchInput
              type="search"
              className="mr-4"
              defaultValue={assetIdSearch}
              onChange={(e) => {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(function () {
                  setAssetIdSearch(e.target.value);
                }, doneTypingInterval);
              }}
              placeholder={t.homePage.tabel.search}
            />

            <button
              className="flex flex-nowrap gap-2 justify-center mr-4 items-center w-min
              rounded-md border border-gray-300 shadow-sm text-sm font-medium  px-4 py-2 
              text-gray-700 bg-white border-solidp-2 disabled:cursor-not-allowed disabled:opacity-70"
              aria-label={t.homePage.excel_download}
              onClick={() => {
                download();
              }}
              disabled={downloadingExcel}
            >
              {downloadingExcel ? <Spinner size={16} /> : <ScaleIconUserFileAnalytics style={{ stroke: 'white' }} size={16} />}
              <div className="truncate">{t.homePage.excel_download}</div>
            </button>
          </div>
          <CustomToggle
            toggle={() => setIsOutputMediaActive(!isOutputMediaActive)}
            checked={isOutputMediaActive}
            rightLabel={t.homePage.activated_output_media}
            leftLabel={t.homePage.deactivated_output_media}
            title={t.homePage.title_output_media}
          />
        </div>

        <CustomSelectOutputMedia />
        <div className="flex flex-col gap-x-6 gap-y-2 sm:flex-row sm:items-center">
          <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
            <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
            <div className="flex-1 text-sm text-gray-60">{t.homePage.assetStatus.ready}</div>
          </div>
          <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
            <div className={`flex-none inline-block h-4 w-4 rounded-full bg-gray-20`}></div>
            <div className="flex-1 text-sm text-gray-60">{t.homePage.assetStatus.in_progress}</div>
          </div>
          <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
            <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>
            <div className="flex-1 text-sm text-gray-60">{t.homePage.assetStatus.rejected}</div>
          </div>
        </div>
      </div>
    </>
  );
}

const SearchInput = tw.input`w-[100px] p-2 rounded-md block border-gray-20 border-solid border text-sm focus:outline-none`;
