import { StylesConfig } from 'react-select';

export const customStyles: StylesConfig = {
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      zIndex: 101,
      marginTop: '8px',
      paddingTop: '8px',
    };
  },
  control: (provided: any, state: any) => {
    return {
      ...provided,
      minWidth: '200px',
      borderRadius: '8px',
      borderColor: state?.isFocused ? 'var(--primary)' : 'var(--bg-darker)',
      '&:hover': {
        cursor: 'pointer;',
      },
      boxShadow: 'none',
      marginLeft: '0',
      marginRight: '0',
    };
  },
  container: (provided: any, state: any) => ({
    ...provided,
    padding: '15px',
    borderRadius: '8px',
    border: '1px solid var(--bg-darker)',
  }),
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: 'visible',
    };
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      '&:hover': {
        cursor: 'pointer;',
        backgroundColor: 'var(--primary-hover)',
        color: 'white',
      },
      '&:active': {
        cursor: 'pointer;',
        backgroundColor: 'var(--primary-active)',
      },
      backgroundColor: `${state.isSelected ? 'var(--magenta_light)!important' : 'none'}`,
      borderRadius: '8px',
      marginBottom: '5px',
    };
  },
  input: (provided: any, state: any) => ({
    ...provided,
    input: {
      boxShadow: 'unset! important',
    },
  }),
};
