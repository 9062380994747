import { Modal } from 'Components';
import { ReactNode } from 'react';

interface ConfirmationModalProps {
  isOpen: boolean;
  onRequestClose: Function;
  onConfirm: Function;
  title?: string;
  buttonConfirm: string;
  disableButtonConfirm?: boolean;
  buttonCancel?: string;
  children: ReactNode;
}

export default function ConfirmationModal({
  isOpen,
  onRequestClose,
  onConfirm,
  title,
  buttonConfirm,
  disableButtonConfirm,
  buttonCancel,
  children,
}: ConfirmationModalProps) {
  const headerModal = (
    <div className="flex flex-col gap-2 items-start flex-1">
      <h3 className="text-xl font-extrabold break-all flex-1">{title}</h3>
    </div>
  );

  const footerModal = (
    <div className="flex flex-wrap justify-end gap-2 pt-5 border-t border-solid border-gray-20">
      {Boolean(buttonCancel) && (
        <button
          title={buttonCancel}
          aria-label={buttonCancel}
          className="whitespace-nowrap py-2 px-4 min-w-[140px] text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
          onClick={() => onRequestClose()}
        >
          {buttonCancel}
        </button>
      )}

      <button
        title={buttonConfirm}
        aria-label={buttonConfirm}
        disabled={disableButtonConfirm ? disableButtonConfirm : false}
        className="whitespace-nowrap py-2 px-4 min-w-[140px] text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl disabled:opacity-70 disabled:cursor-default"
        onClick={() => onConfirm()}
      >
        {buttonConfirm}
      </button>
    </div>
  );

  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
      bodyClasses="relative py-5 px-1 overflow-auto"
      {...(Boolean(title) && { header: headerModal })}
      footer={footerModal}
    >
      {children}
    </Modal>
  );
}
