import { Logo } from '../Logo';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useT } from 'Config';

export function BrandBar({ hide }: { hide: boolean }) {
  const t = useT();
  return (
    <FixedBar aria-hidden={hide} className={`${hide ? 'hide' : ''}`}>
      <div className="page">
        <Link className="flex gap-5 justify-center items-center" to="/" aria-label={t.global.to_home_page} title={t.global.to_home_page}>
          <Logo className="fill-white" aria-hidden={hide} height="2.25rem" />
          <div className="text-2xl leading-9 font-bold">E-Media</div>
        </Link>
      </div>
    </FixedBar>
  );
}

const FixedBar = styled.header`
  top: 0px;
  left: 0px;
  width: 100%;
  position: absolute;
  font-size: 1.5rem;
  height: 72px;
  display: flex;
  background-color: var(--primary);
  color: #fff;
  > div {
    width: var(--page-width);
    margin: 0 auto;
    font-size: 38px;
    display: flex;
    align-items: center;
    transform: translateY(0);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);

    div {
      transform: translateY(0);
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
    }
  }

  &.hide div {
    transform: translateY(1.5rem);
    opacity: 0;
  }
  > div {
    @media (max-width: 768px) {
      transform: translateY(1.5rem);
      opacity: 0;
    }
  }
`;
