import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import * as auth from 'Features/Authentication/lib/model';
import * as staticPages from 'Features/StaticPages/lib/model';
import * as campaignsSlice from 'Features/Campaigns/lib/model';
import * as mediaTypesSlice from 'Features/MediaTypes/lib/model';
import * as mediaCampaignSlice from 'Features/MediaCampaign/lib/model';
import * as campaignCommentsSlice from 'Features/CampaignComments/lib/model';
import { RootState } from './store';

const combinedReducers = combineReducers({
  [auth.name]: auth.reducer,
  [staticPages.name]: staticPages.reducer,
  [campaignsSlice.name]: campaignsSlice.reducer,
  [mediaTypesSlice.name]: mediaTypesSlice.reducer,
  [mediaCampaignSlice.name]: mediaCampaignSlice.reducer,
  [campaignCommentsSlice.name]: campaignCommentsSlice.reducer,
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    state = {};
  }

  return combinedReducers(state, action);
};
