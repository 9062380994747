import { ScaleIconNavigationLeft } from '@telekom/scale-components-react';
import { useT } from 'Config';
import { zeroFill } from 'Helpers';
import React from 'react';
import AssetTable from '../components/AssetTable';
import OutputMediaFineData from '../components/OutputMediaFineData';

interface Props {
  campaign: Campaign;
}

export default function FineData({ campaign }: Props) {
  const t = useT();
  const campaignOutputMedia = campaign.output_media_types;

  return (
    <div className="flex flex-col justify-start items-start gap-2.5 py-8 pt-10 w-full">
      <a
        className="flex flex-nowrap gap-2 items-center text-2xl text-primary-500"
        title={t.global.back}
        aria-label={t.global.back}
        href={`/campaign/${campaign.id}`}
      >
        <ScaleIconNavigationLeft size={16} />
        <span className="lowercase text-sm">{t.global.back}</span>
      </a>

      <div className="flex items-start flex-col justify-between pt-6 gap-4 text-left whitespace-nowrap w-full lg:flex-row lg:items-center">
        <h2 className="text-3xl text-gray-90 font-extrabold whitespace-pre-wrap">{`${t.mediaCampaign.fine_data_of_action} ${zeroFill(
          campaign.id,
          6,
          'EMA_'
        )}`}</h2>
        <div className="flex flex-col gap-x-6 gap-y-2 sm:flex-row sm:items-center">
          <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
            <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
            <div className="flex-1 text-sm text-gray-60">{t.mediaCampaign.uploaded}</div>
          </div>
          <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
            <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>
            <div className="flex-1 text-sm text-gray-60">{t.mediaCampaign.rejected}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full pt-8">
        {campaignOutputMedia.map((campaignMediaType, index) => (
          <React.Fragment key={`${campaignMediaType.id}_${index}`}>
            <OutputMediaFineData
              key={`${campaignMediaType.id}_${index}`}
              campaign={campaign}
              campaignMediaType={campaignMediaType}
            ></OutputMediaFineData>
            <AssetTable
              key={`${campaignMediaType.id}_table_${index}`}
              assetId={campaignMediaType.asset?.id}
              options={{ preview: true, download: true, delete: true }}
            ></AssetTable>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
