import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

export const FormatMoney = (n: any) =>
  n
    ? Number(n).toLocaleString('de-DE', {
        currency: 'EUR',
        style: 'currency',
      })
    : '--';

export const FormatDate = (n: string | Date, short = false, hastime: boolean = true, notime: '2-digit' | 'numeric' | undefined = '2-digit') => {
  if (!n) return null;
  const date = typeof n === 'string' ? new Date(n) : n;
  if (!date?.toLocaleDateString) {
    return null;
  }

  return short
    ? date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    : date.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...(hastime && { hour12: false, hour: notime, minute: notime }),
      });
};

export const LengthOfTime = (start: string | Date, end: string | Date) => {
  if (!start || !end) return null;
  const startDate = typeof start === 'string' ? new Date(start) : start;
  const endDate = typeof end === 'string' ? new Date(end) : end;
  if (!startDate?.toLocaleDateString || !endDate?.toLocaleDateString) {
    return null;
  }
  return formatDistance(startDate, endDate, { addSuffix: true, locale: de });
};

export function zeroFill(nb, minLength = 6, prefix = '') {
  // Convert your number to string.
  return String(`${nb}`)
    .padStart(minLength, '0')
    .padStart(minLength + prefix.length, prefix);
}

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}
