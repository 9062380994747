import React from 'react';
import { Helmet } from 'react-helmet';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Page } from 'Components';
import { useT } from 'Config';
import styled from 'styled-components';

export default function NoDataPage() {
  const t = useT().noData;

  return (
    <>
      <Helmet>
        <title>{t.title}</title>
      </Helmet>
      <Page>
        <div className="flex items-center flex-col justify-center h-full gap-20">
          <p className="text-primary-500 text-2xl font-bold animate-bounce">Ups!</p>
          <Error className="text-[16rem] font-black">403</Error>
          <div className="flex flex-col justify-center items-center">
            <h2 className="font-bold text-4xl italic">{t.title}</h2>
            <p className="italic text-xl">{t.message}</p>
          </div>
        </div>
      </Page>
    </>
  );
}

const Error = styled.h1`
  text-shadow: 3px 3px 0 hsl(329deg 100% 44% / 27%), 1px 1px 0 #c19ba9;
  letter-spacing: -23px;
`;
