import React, { useContext, useMemo, useState } from 'react';
import * as de from './de';
import * as en from './en';
const translations: Record<string, typeof de> = { de, en };

interface LocalState {
  state: 'en' | 'de';
  onChange: (lang: LocalState['state']) => void;
}

export const localContext = React.createContext<LocalState>({ state: 'de', onChange: console.log });
export type Translations = typeof translations.en;

const { Provider } = localContext;

export const LocalProvider: React.FC<{ children: any }> = ({ children }) => {
  const [state, onChange] = useState<LocalState['state']>('de');

  return (
    <Provider
      value={{
        state,
        onChange,
      }}
    >
      {children}
    </Provider>
  );
};

export const useLocale = () => {
  const { state, onChange } = useContext(localContext);

  return useMemo<[typeof state, typeof onChange]>(() => [state, onChange], [state, onChange]);
};

export const useT = () => {
  const [state] = useLocale();

  return translations[state];
};
