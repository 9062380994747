import { client } from 'Config';
import { useT } from 'Config';
import { RiArrowGoForwardFill } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { CustomDropDown, Pagination, Spinner } from 'Components';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FormatDate, zeroFill } from 'Helpers';
import { ScaleIconAlertInformation } from '@telekom/scale-components-react';

export default function Infos() {
  const tHomePage = useT().homePage;
  const t = useT().dashboard.tabInfos;
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 0,
    total_count: 0,
    total_pages: 0,
  });
  const [campaignEvent, setCampaignEvent] = useState<CampaignEvent[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const filterOptions = [
    {
      id: 'all',
      name: t.filterOptions.all,
    },
    {
      id: 'unseen',
      name: t.filterOptions.new,
    },
    {
      id: 'seen',
      name: t.filterOptions.old,
    },
  ];
  const [selectedOption, setOption] = useState<{
    id: string;
    name: string;
  }>(filterOptions[0]);

  useEffect(() => {
    setLoading(true);
    const paramNotice = selectedOption.id !== 'all' ? selectedOption.id : undefined;
    client
      .getCampaignEvents({ notice: paramNotice })
      .then((response) => {
        setPagination(response.data.pagination_meta);
        setCampaignEvent(response.data.events);
      })
      .finally(() => setLoading(false));
  }, [selectedOption.id]);

  const setPage = (page) => {
    setLoading(true);
    const paramNotice = selectedOption.id !== 'all' ? selectedOption.id : undefined;
    client
      .getCampaignEvents({ notice: paramNotice, page: page })
      .then((response) => {
        setPagination(response.data.pagination_meta);
        setCampaignEvent(response.data.events);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="flex flex-col h-full w-full overflow-auto mx-[-10px] px-[10px]">
        {loading ? (
          <div className="flex h-40 mx-auto">
            <Spinner size={60} />
          </div>
        ) : (
          <>
            <div className="flex items-start flex-col justify-between pt-8 gap-x-4 gap-y-8 text-left whitespace-nowrap w-full lg:flex-row lg:items-center">
              <div className="flex gap-2 items-start flex-nowrap flex-col sm:flex-row sm:items-center">
                <div className="text-base whitespace-pre-wrap">{t.dropdown_label}</div>
                <CustomDropDown
                  classes="min-w-[175px] px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-700
                  hover:bg-gray-200 hover:border-primary-500
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
                  label={selectedOption.name || ''}
                  items={filterOptions.map((option) => ({
                    label: `${option.name}`,
                    onSelect: () => {
                      setOption(option);
                    },
                    props: { className: 'min-w-[224px]' },
                  }))}
                />
              </div>

              <div className="flex flex-col gap-x-6 gap-y-2 sm:flex-row sm:items-center">
                <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
                  <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
                  <div className="flex-1 text-sm text-gray-60">{t.filterOptions.old}</div>
                </div>
                <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
                  <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>
                  <div className="flex-1 text-sm text-gray-60">{t.filterOptions.new}</div>
                </div>
              </div>
            </div>

            <div className="mb-8 mt-6 flex-1 mx-[-10px] px-[10px]">
              <div className="rounded-t-lg overflow-auto">
                <table className="min-w-full divide-y divide-gray-200 break-all">
                  <thead className="bg-gray-0 font-extrabold text-sm text-gray-100">
                    <tr>
                      <th scope="col" className="px-2 py-3 text-left whitespace-nowrap w-[56px] min-w-[56px]">
                        {t.tabel.status}
                      </th>
                      <th scope="col" className="px-2 py-3 text-left whitespace-nowrap w-[75px] min-w-[75px]">
                        {t.tabel.action}
                      </th>
                      <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[300px] ">
                        {t.tabel.info}
                      </th>
                      <th scope="col" className="px-2 py-3 text-left whitespace-nowrap w-[170px] min-w-[95px]">
                        {t.tabel.received_at}
                      </th>
                      <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[100px]"></th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200 font-normal text-sm">
                    {!campaignEvent?.length ? (
                      <tr>
                        <td colSpan={7} className="text-center align-middle text-base p-8">
                          {t.tabel.no_data}
                        </td>
                      </tr>
                    ) : (
                      <>
                        {campaignEvent?.map((event, index) => (
                          <tr key={`info-${index}`}>
                            <TableCell className="text-center">
                              {new Date(event.last_seen_at) > new Date(event.updated_at) ? (
                                <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
                              ) : (
                                <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>
                              )}
                            </TableCell>
                            <TableCell>
                              <div className="flex justify-center items-center gap-x-2">
                                <a
                                  className="text-sm text-blue-60 underline whitespace-nowrap underline-offset-1"
                                  title={tHomePage.tabel.to_the_desk}
                                  aria-label={tHomePage.tabel.to_the_desk}
                                  href={`/campaign/${event.campaign_id}`}
                                >
                                  {zeroFill(event.campaign_id, 6, 'E-')}
                                </a>
                                <a
                                  title={tHomePage.tabel.action_details}
                                  className="hover:text-primary-500 flex justify-center"
                                  href={`/info/${event.campaign_id}`}
                                >
                                  <ScaleIconAlertInformation size={16} />
                                </a>
                              </div>
                            </TableCell>
                            <TableCell>{event.text}</TableCell>
                            <TableCell>{FormatDate(event.created_at)}</TableCell>
                            <TableCell className="float-right">
                              <a
                                className="inline-flex flex-nowrap gap-2 justify-center items-center text-sm text-primary-500"
                                title={t.tabel.to_the_desk}
                                aria-label={t.tabel.to_the_desk}
                                href={`/campaign/${event.campaign_id}`}
                              >
                                <RiArrowGoForwardFill size={16} />
                                <div className="whitespace-nowrap">{t.tabel.to_the_desk}</div>
                              </a>
                            </TableCell>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={pagination.current_page}
                onSelect={setPage}
                totalCount={pagination.total_count}
                totalPages={pagination.total_pages}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

const TableCell = styled.td`
  ${tw`px-2 py-4 align-top`}
`;
