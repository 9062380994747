/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/label-has-for */
import React, { KeyboardEventHandler, useEffect } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import tw from 'twin.macro';

import styled from 'styled-components';

interface DropDownProps {
  id: string;
  disabled?: boolean;
  Icon?: React.ReactNode;
  buttonLabel?: string;
  buttonTitle?: string;
  itemsLabel?: string;
  buttonClasses?: string;
  showChevron?: boolean;
  classesMenuList?: string;
  items: Array<{
    label: string;
    onChange: (checked: boolean) => void;
    checked: boolean;
    classes?: string;
  }>;
}

export function MenuWithCheckbox(props: DropDownProps) {
  const { id, disabled, buttonClasses, classesMenuList, Icon, buttonLabel, buttonTitle, itemsLabel, items, showChevron = true } = props;
  const disabledDropDown = items.length === 0 || disabled;
  const [checked, toggle] = React.useState(false);
  const hide = () => toggle(false);
  const show = () => toggle(true);
  const refMenu = React.useRef<HTMLElement>(null);
  const refButton = React.useRef<HTMLButtonElement>(null);
  const ref = React.useRef<HTMLDivElement & { idx: number }>(null);

  useEffect(() => {
    if (checked) {
      const handleClickOutside = (event) => {
        if (refMenu.current && ref.current && !ref.current.contains(event.target) && !refMenu.current.contains(event.target)) {
          hide();
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return () => {};
  }, [ref, checked]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.idx = 0;
    ref.current.querySelector<HTMLInputElement>('[tabindex]')?.focus();
  }, [checked]);

  const tab: KeyboardEventHandler<HTMLElement> = (e) => {
    if (!ref.current) return;
    const checkBoxes = ref.current.querySelectorAll<HTMLAnchorElement>('div > [tabIndex]');
    ref.current.idx = ref.current.idx || 0;
    let isNavigationEvent = true;
    switch (e.key) {
      case 'Tab':
        e.shiftKey ? ref.current.idx-- : ref.current.idx++;
        break;
      case 'ArrowDown':
        ref.current.idx++;
        break;
      case 'ArrowUp':
        ref.current.idx--;
        break;
      case 'Enter':
        checkBoxes[ref.current.idx]?.click();
        isNavigationEvent = false;
        return hide();
      case 'Escape':
        isNavigationEvent = false;
        refButton.current?.focus();
        return hide();
      default:
        isNavigationEvent = false;
        return;
    }
    if (!isNavigationEvent) return;

    e.stopPropagation();
    e.preventDefault();
    // roll
    if (ref.current.idx > checkBoxes.length) {
      ref.current.idx = 0;
    }
    if (ref.current.idx < 0) {
      ref.current.idx = checkBoxes.length - 1;
    }
    if (!checkBoxes[ref.current.idx]) {
      ref.current.idx = 0;
    }

    checkBoxes[ref.current.idx]?.focus();
  };

  return (
    <section className="relative" id={`menu-checkbox-${id}`} ref={refMenu}>
      <button
        id={`menu-checkbox-btn-${id}`}
        disabled={disabledDropDown}
        className={`inline-flex justify-center items-center gap-x-4 disabled:opacity-80 ${buttonClasses}`}
        onClick={() => {
          checked ? hide() : show();
        }}
        ref={refButton}
        title={buttonTitle}
      >
        {buttonLabel && <span>{buttonLabel}</span>}
        {Icon && <span aria-hidden>{Icon}</span>}
        {showChevron && <>{checked ? <BsChevronUp className="drop-down-chevron" /> : <BsChevronDown className="drop-down-chevron" />}</>}
      </button>
      <DropdownCheckboxes
        onKeyDown={tab}
        id={`dropdown-checkbox-${id}`}
        className={`absolute left-0 flex flex-col whitespace-nowrap bg-white border-gray-40 outline-0 py-4 text-[85%] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50  ${classesMenuList}`}
        hidden={!checked}
        ref={ref}
      >
        {itemsLabel && <div className="dropdown-header border-b px-4 pb-2 text-gray-600">{itemsLabel}</div>}
        {(props.items || []).map((item, index) => (
          <div key={`checkbox-item-${index}`} className={`flex gap-x-2 items-center py-2 px-4 ${item.classes}`}>
            <input
              id={`checkbox-item-${id}-${index}`}
              type="checkbox"
              value=""
              checked={item.checked}
              className="w-4 h-4 text-primary-500 rounded border-gray-300 cursor-pointer focus:ring-primary-400 focus:ring-2"
              tabIndex={1 + index}
              onChange={(e) => {
                item.onChange(e.target.checked);
              }}
            />
            <label htmlFor={`checkbox-item-${id}-${index}`} className="whitespace-pre-wrap cursor-pointer flex-full">
              {item.label}
            </label>
          </div>
        ))}
      </DropdownCheckboxes>
    </section>
  );
}

const DropdownCheckboxes = styled.div(({ hidden }: { hidden?: boolean }) => [hidden && tw`hidden`]);
