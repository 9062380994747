import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CommentState extends CommentInfo {
  children?: CommentState[];
  loadingChildren: boolean;
  pagination: Pagination;
}

export interface CampaignCommentsState {
  comments: CommentState[];
  pagination: Pagination;
}

export const initialState: CampaignCommentsState = {
  comments: [],
  pagination: {
    current_page: 0,
    total_count: 0,
    total_pages: 0,
  },
};

export const { actions, reducer, name } = createSlice({
  name: 'campaignComments',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<CommentsList>) => {
      state.pagination = action.payload.pagination_meta;
      state.comments = action.payload.comments.map<CommentState>((comment) => {
        return {
          ...comment,
          ...{
            loadingChildren: false,
            pagination: {
              current_page: 0,
              total_count: 0,
              total_pages: 0,
            },
          },
        };
      });
      return state;
    },
    loadingChildren: (state, action: PayloadAction<{ parentCommentId: number }>) => {
      state.comments = setChildLoad(state.comments, action.payload.parentCommentId);
    },
    setChildren: (state, action: PayloadAction<{ comments: CommentsList; parentCommentId?: number }>) => {
      state.comments = buildComments(state.comments, action.payload.parentCommentId || 0, action.payload.comments);
    },
    reset: (state) => {
      state = initialState;
      return state;
    },
  },
});

function setChildLoad(comments: CommentState[], parentCommentId: number): CommentState[] {
  return comments.reduce((a: CommentState[], c: CommentState) => {
    if (c.id === parentCommentId) {
      return a.concat([
        {
          ...c,
          ...{
            loadingChildren: true,
          },
        },
      ]);
    } else if (!!c.children) {
      return a.concat([
        {
          ...c,
          ...{ children: setChildLoad(c.children, parentCommentId) },
        },
      ]);
    }
    return a.concat([c]);
  }, []);
}

function buildComments(comments: CommentState[], parentCommentId: number, childrenComments: CommentsList): CommentState[] {
  return comments.reduce((a: CommentState[], c: CommentState) => {
    if (c.id === parentCommentId) {
      return a.concat([
        {
          ...c,
          ...{
            loadingChildren: false,
            pagination: childrenComments.pagination_meta,
            children: childrenComments.comments.map<CommentState>((comment) => {
              return {
                ...comment,
                ...{
                  loadingChildren: false,
                  pagination: c.pagination,
                },
              };
            }),
          },
        },
      ]);
    } else if (!!c.children) {
      return a.concat([
        {
          ...c,
          ...{ children: buildComments(c.children, parentCommentId, childrenComments) },
        },
      ]);
    }
    return a.concat([c]);
  }, []);
}

export const slice = (state: RootState): CampaignCommentsState => state[name] || initialState;

export const getComments = (state: RootState) => {
  return slice(state).comments;
};

export const getPagination = (state: RootState) => {
  return slice(state).pagination;
};
