import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import '@reach/skip-nav/styles.css';
import { AppFooter, AppHeader, ConnectivityMonitor, SplashScreen } from 'Components';
import { api, useAppDispatch, useAppSelector } from 'Config';
import AppRouter from 'Features';
import { mediaTypes } from 'Features/MediaTypes';
import { staticPages } from 'Features/StaticPages/lib';
import { authStore } from 'Features/Authentication/lib';
import { useEffect, useState } from 'react';
import { DefaultToast, ToastProps, ToastProvider } from 'react-toast-notifications';
import styled from 'styled-components';
import { useUserHasRights } from 'Components/hooks/useUserHasRights';

function App() {
  const [loading, ready] = useState(true);
  const dispatch = useAppDispatch();
  const pages = useAppSelector(staticPages.slice);
  const hasRights = useUserHasRights();

  useEffect(() => {
    if (hasRights) {
      Promise.all([dispatch(authStore.fetchUserEMedia()), dispatch(staticPages.fetch()), dispatch(mediaTypes.fetch())]).finally(() => ready(false));
    } else {
      Promise.all([dispatch(authStore.fetchUserEMedia())]).finally(() => ready(false));
    }
  }, [dispatch, hasRights]);

  if (loading) return <SplashScreen />;

  return (
    <ToastProvider components={{ Toast: CustomToast }} autoDismiss autoDismissTimeout={15000}>
      <div className="flex flex-col h-full min-h-screen">
        <SkipNavLink />
        <AppHeader />
        <main className="relative flex rounded-b-xl">
          <SkipNavContent />
          <ConnectivityMonitor api={api} />
          <AppRouter />
        </main>
        <AppFooter
          pages={pages.map(({ name, slug }) => ({
            name: name,
            href: '/statics/' + slug,
          }))}
        />
      </div>
    </ToastProvider>
  );
}

export default App;

const CustomToast = ({ children, ...props }: ToastProps) => <DefaultToastCustom {...props}>{children}</DefaultToastCustom>;
const DefaultToastCustom = styled(DefaultToast)`
  border: 1px solid;
`;
