import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'Config';
import { staticPages } from './lib';
import { Spinner, Page } from 'Components';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import tw from 'twin.macro';

export default function StaticPages() {
  const dispatch = useAppDispatch();
  const pages = useAppSelector(staticPages.slice);
  const { slug } = useParams<{ slug: string }>();
  const page = pages.find((r) => `${r.slug}`.toLowerCase() === `${slug}`.toLowerCase());

  useEffect(() => {
    if (slug) dispatch(staticPages.fetchPageDetails(slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  if (!page) {
    return <Spinner />;
  }
  return (
    <Page>
      <Helmet>
        <title>{page.name}</title>
      </Helmet>
      <Container>
        <article dangerouslySetInnerHTML={{ __html: page.html_content }}></article>
      </Container>
    </Page>
  );
}

const Container = styled.div`
  border: 0;
  border-collapse: collapse;
  font-size: 18px;
  margin: 2rem auto;
  width: 100%;

  h1 {
    margin-top: 0.67rem;
    font-weight: bold;
  }

  h2 {
    ${tw`text-green-600`}
    margin: 1rem 0;
    font-weight: bold;
  }

  a {
    ${tw`text-primary-500`}
  }
`;
