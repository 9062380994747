import { CustomCheckbox, DateTimePicker, Modal } from 'Components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { formatISO } from 'date-fns';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import { client } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { campaigns } from 'Features/Campaigns';
import { useNavigate } from 'react-router';
import { tReplace } from 'Components/Trans';
import { zeroFill } from 'Helpers';

interface NewMediaProps {
  isOpen: boolean;
  onRequestClose: Function;
}

export default function NewMedia({ isOpen, onRequestClose }: NewMediaProps) {
  let typingTimerName: NodeJS.Timeout; //timer identifier
  let doneTypingInterval = 1000; //time in ms (1 seconds)
  const tGlobal = useT().global;
  const t = useT().newMedia;
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(campaigns.getFilters);
  const sort = useAppSelector(campaigns.getSort);
  const maxLengthName = 50;
  const maxLengthDescription = 500;
  const [countName, setCountName] = React.useState(0);
  const [countDescription, setCountDescription] = React.useState(0);
  const [selectedBlockedAction, setSelectedBlockedAction] = useState<Campaign>();
  const [data, setData] = useState<{
    name?: string;
    start_date?: Date;
    end_date?: Date;
    unlimited_term: boolean;
    description?: string;
    predecessor_id?: number;
  }>({
    unlimited_term: false,
  });
  const [replaceActionCheckbox, setReplaceActionCheckbox] = useState(false);
  const [actionToReplaceId, setActionToReplaceId] = useState<number>();
  const [checkboxError, setCheckboxError] = useState<string>();
  const navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();

    const formDataRequest: CampaignBasicInfo = {
      name: data.name || '',
      start_date: data.start_date && formatISO(data.start_date, { representation: 'date' }),
      end_date: !data.unlimited_term && data.end_date ? formatISO(data.end_date, { representation: 'date' }) : undefined,
      description: data.description || '',
      ...(replaceActionCheckbox && { predecessor_id: actionToReplaceId }),
    };
    client.createCampaign(formDataRequest).then((data) => {
      const { id } = data.data;

      dispatch(campaigns.fetch(filters, sort));

      addToast(
        <div role="alert">
          <div>{t.campaign_created}</div>
        </div>,
        {
          appearance: 'success',
          autoDismiss: true,
        }
      );
      navigate(`/campaign/${id}`);
      onRequestClose();
    });
  };

  const headerModal = (
    <div className="flex flex-col gap-2 items-start flex-1">
      <h3 className="text-xl font-extrabold break-all flex-1">{t.page_title}</h3>
      <h4 className="text-base break-all flex-1">{t.enter_data}</h4>
    </div>
  );

  const footerModal = (
    <div className="flex flex-wrap justify-end gap-2 pt-5 border-t border-solid border-gray-20">
      <button
        title={t.discard}
        aria-label={t.discard}
        className="py-2 px-4 w-40 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
        onClick={() => onRequestClose()}
      >
        {t.discard}
      </button>

      <button
        id="button-submit"
        disabled={(replaceActionCheckbox && !selectedBlockedAction) || !data.name || !data.start_date}
        type="submit"
        form="new-media-form"
        title={t.create}
        aria-label={t.create}
        className="py-2 px-4 w-40 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl disabled:opacity-70 disabled:cursor-default"
      >
        {t.create}
      </button>
    </div>
  );

  useEffect(() => {
    setSelectedBlockedAction(undefined);
    if (isNaN(actionToReplaceId ?? 0)) {
      setCheckboxError(t.form.action_not_existing);
    }
    if (actionToReplaceId) {
      client
        .getCampaign(actionToReplaceId)
        .then((data) => {
          const action = data.data;
          if (action.successor_id) {
            setCheckboxError(tReplace(t.form.action_already_blocked, { campaignId: zeroFill(action.successor_id, 6, 'E-') }));
          } else {
            setSelectedBlockedAction(data.data);
          }
        })
        .catch(() => setCheckboxError(t.form.action_not_existing));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionToReplaceId]);

  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
      bodyClasses="py-5 px-1 overflow-auto"
      header={headerModal}
      footer={footerModal}
    >
      <div className="flex gap-[13px] flex-col">
        <form id={'new-media-form'} className="flex flex-col gap-6" onSubmit={onSubmit}>
          <div className="flex flex-col gap-2 w-full max-w-[415px]">
            <label htmlFor="name" className="row-label text-base font-bold">
              {t.form.name}
            </label>
            <Input
              className="text-sm min-w-[220px]"
              maxLength={maxLengthName}
              required={true}
              active={!!data.name}
              onChange={(e) => {
                setCountName(e.target.value.length);
                setData({ ...data, name: e.currentTarget.value });
              }}
              placeholder={t.form.name}
              title={t.form.name}
              name="name"
              defaultValue={data.name}
              autoComplete="off"
            />
            <div className="text-xs text-right min-w-[220px]">
              <span>{countName}</span>/<span>{maxLengthName}</span>
            </div>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label className="row-label text-base font-bold">{t.form.online_from}</label>
            <DateTimePicker
              key={'start_date'}
              type={'date'}
              required={true}
              name={'start_date'}
              value={data.start_date}
              minDate={new Date()}
              placeholder={tGlobal.date}
              className="text-sm w-[220px] rounded-lg border-gray-90 border-[1px] shadow p-2 focus:text-black"
              classNameContainer="text-sm !w-[220px] rounded-lg "
              onChangeTime={(date) => {
                setData({ ...data, start_date: date });
              }}
            ></DateTimePicker>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label className="row-label text-base font-bold">{t.form.online_until}</label>
            <div className="flex flex-row flex-wrap gap-x-4">
              <DateTimePicker
                key={'end_date'}
                type={'date'}
                required={!data.unlimited_term}
                name={'end_date'}
                value={data.end_date}
                minDate={data.start_date || new Date()}
                placeholder={tGlobal.date}
                className="text-sm w-[220px] min-w-[220px] rounded-lg border-gray-90 border-[1px] shadow p-2 focus:text-black"
                classNameContainer="text-sm !w-[220px] min-w-[220px] rounded-lg "
                onChangeTime={(date) => {
                  setData({ ...data, end_date: date });
                }}
                onInvalid={(e: any) => {
                  e.target.setCustomValidity(t.form.online_until_validity);
                }}
              ></DateTimePicker>

              <CustomCheckbox
                classesLabel="items-center"
                style={{ height: 16, width: 16, marginLeft: 0 }}
                id="unlimited_term"
                value="unlimited_term"
                checked={data.unlimited_term}
                onChange={(e) => setData({ ...data, unlimited_term: e.target.checked })}
              >
                <span className="text-sm whitespace-nowrap">{t.form.unlimited_term}</span>
              </CustomCheckbox>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="row-label text-base font-bold">{t.form.description}</label>
            <TextArea
              id="description"
              className="text-sm w-[415px]"
              maxLength={maxLengthDescription}
              active={!!data.description}
              name="description"
              placeholder={t.form.description}
              rows={5}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setCountDescription(e.target.value.length);
                setData({ ...data, description: e.target.value });
              }}
              defaultValue={data.description}
            ></TextArea>
            <div className="text-xs text-right min-w-[220px]">
              <span>{countDescription}</span>/<span>{maxLengthDescription}</span>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label className="row-label text-base font-bold">{t.form.checkbox_label}</label>
            <CustomCheckbox
              classesLabel="items-center"
              style={{ height: 16, width: 16, marginLeft: 0 }}
              id="replace_Action"
              value="replace_Action"
              checked={replaceActionCheckbox}
              onChange={(e) => {
                setReplaceActionCheckbox(e.target.checked);
              }}
            >
              <span className="text-sm whitespace-nowrap mr-2">{t.form.checkbox_description}</span>
              <Input
                className="text-sm min-w-[50px] disabled:cursor-not-allowed disabled:border-gray-300"
                required={true}
                type="text"
                disabled={!replaceActionCheckbox}
                onChange={(e) => {
                  clearTimeout(typingTimerName);
                  const { currentTarget } = e;
                  typingTimerName = setTimeout(function () {
                    const value = currentTarget.value;
                    const regex = /[0-9]+/;
                    const match = value.match(regex);
                    setActionToReplaceId(parseInt(match?.[0] ?? ''));
                    setCheckboxError(undefined);
                  }, doneTypingInterval);
                }}
                placeholder="ID"
                title={'id'}
                name="id"
                onInvalid={(e: any) => {
                  e.target.setCustomValidity(t.form.add_action_ad);
                }}
              />
            </CustomCheckbox>
            {checkboxError && <p className="text-red-500 text-sm italic">{checkboxError}</p>}
          </div>
        </form>
      </div>
    </Modal>
  );
}

const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black`,
  active && tw`text-black`, // Then add conditional styles
]);

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black `,
  active && tw`text-black`, // Then add conditional styles
]);
