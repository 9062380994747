function appendSlash(str?: string | true | number): string {
  if (typeof str !== 'string') return '/';

  return str[str.length - 1] === '/' ? str : str + '/';
}

export const SSO_URL = appendSlash(process.env.REACT_APP_SSO_URL || 'http://localhost:3002/api/v2');
export const APP_UID = process.env.REACT_APP_APP_UID;
export const API_URL = appendSlash(process.env.REACT_APP_API_URL || 'http://localhost:5000/api/v1');
export const BACKEND_BASE_URL = API_URL.split('/').slice(0, 3).join('/');
export const API_TIMEOUT = Number(process.env.REACT_APP_API_TIMEOUT || 20000);
export const __DEV__ = process.env.NODE_ENV !== 'production';
export const START_MONITORING = __DEV__ && process.env.REACT_APP_MONITORING;
export const START_TRACKING = __DEV__ && process.env.REACT_APP_TRACKING;
