import { client } from 'Config';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import { Spinner } from 'Components';
import { useEffect, useState } from 'react';
import { authStore } from 'Features/Authentication/lib';

export default function Mails() {
  const t = useT().dashboard.tabEMails;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const userEmedia = useAppSelector(authStore.selectEmediaUser);

  useEffect(() => {
    setLoading(true);
    dispatch(authStore.fetchUserEMedia()).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    client
      .updatesUserEmailSchedule(event.target.value)
      .then(() => {
        dispatch(authStore.fetchUserEMedia()).finally(() => setLoading(false));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex flex-col h-full w-full overflow-auto mx-[-10px] px-[10px]">
      {loading ? (
        <div className="flex h-40 mx-auto">
          <Spinner size={60} />
        </div>
      ) : (
        <>
          <div className="flex items-start flex-col justify-between pt-8 gap-x-4 gap-y-8 text-left whitespace-nowrap w-full lg:flex-row lg:items-center">
            <h3 className="text-base font-bold">{t.tab_title}</h3>
          </div>
          <fieldset className="flex items-start flex-col mx-[-10px] px-[10px] py-6 gap-y-4">
            <div>
              <input
                className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-primary-500 checked:border-4 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                checked={userEmedia?.email_schedule === 'any_time'}
                name="settingsRadio"
                id="any_time_notifications"
                value="any_time"
                onChange={radioHandler}
              />
              <label className="text-sm inline-block text-gray-90 hover:cursor-pointer" htmlFor="any_time_notifications">
                {t.any_time}
              </label>
            </div>

            <div>
              <input
                className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-primary-500 checked:border-4 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                checked={userEmedia?.email_schedule === 'daily'}
                name="settingsRadio"
                id="daily_notifications"
                value="daily"
                onChange={radioHandler}
              />
              <label className="text-sm inline-block text-gray-90 hover:cursor-pointer" htmlFor="daily_notifications">
                {t.daily}
              </label>
            </div>

            <div>
              <input
                className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-primary-500 checked:border-4 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                checked={userEmedia?.email_schedule === 'twice_a_week'}
                name="settingsRadio"
                id="twice_a_week_notifications"
                value="twice_a_week"
                onChange={radioHandler}
              />
              <label className="text-sm inline-block text-gray-90 hover:cursor-pointer" htmlFor="twice_a_week_notifications">
                {t.twice_a_week}
              </label>
            </div>

            <div>
              <input
                className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-primary-500 checked:border-4 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                checked={userEmedia?.email_schedule === 'disabled'}
                name="settingsRadio"
                id="disabled_notifications"
                value="disabled"
                onChange={radioHandler}
              />
              <label className="text-sm inline-block text-gray-90 hover:cursor-pointer" htmlFor="disabled_notifications">
                {t.disabled}
              </label>
            </div>
          </fieldset>
        </>
      )}
    </div>
  );
}
