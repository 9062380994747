import { api } from './api';
import { DirectUpload } from '@rails/activestorage';
import { API_URL } from 'Env';

/**
 * Retries information about current user
 */
export const getMe = () => api.get<UserEmedia>(`/users/me`);

/**
 * Retries all users
 */
export const getAllUsers = (params?: { email?: string; role?: string; fullName?: string }) =>
  api.get<UserEmedia[]>(`/users`, {
    params: {
      email: params?.email,
      role: params?.role,
      full_name: params?.fullName,
    },
  });

//Static Pages
/**
 * List static pages
 */
export const GETstatic_pages = () => api.get<StaticPage[]>(`/static_pages`);

/**
 * Find static page by slug
 */
export const GETPage = (slug: string) => api.get<StaticPage>(`/static_pages/${slug}`);

/**
 * Updates current user email schedule
 */
export const updatesUserEmailSchedule = (emailSchedule: string) => api.put(`/users/my_email_schedule`, { email_schedule: emailSchedule });

//ASSETS
/**
 * rejection an asset version
 */
export const rejectAssetVersion = (assetId: number, version: number, rejectionComment: string) =>
  api.post(`/assets/${assetId}/versions/${version}/rejections`, { rejection_comment: rejectionComment });

/**
 * publication an asset version
 */
export const publicationAnAssetVersion = (assetId: number, version: number) => api.post(`/assets/${assetId}/versions/${version}/publications`, {});

/**
 * upload asset
 */
export const uploadAsset = (assetId: number, data: any, headers = {}, token = null) => {
  const enhanceRequest = (xhr: XMLHttpRequest) => {
    if (token) {
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
  };

  const upload = new DirectUpload(data.get('file'), `${API_URL}/direct_uploads`, { directUploadWillCreateBlobWithXHR: enhanceRequest });
  return new Promise((resolve, reject) => {
    upload.create((error: any, blob: any) => {
      if (error) {
        reject(error);
      } else {
        resolve(api.post(`/assets/${assetId}/versions`, { file: blob.signed_id }, headers));
      }
    });
  });
};

/**
 * asset versions
 */
export const getAssetVersions = (assetId: number) => api.get<AssetHistory>(`/assets/${assetId}/versions`);

/**
 * set asset version description
 */
export const setAssetVersionDescription = (assetId: number, version: number, description: string, preview: boolean) =>
  api.put(`/assets/${assetId}/versions/${version}`, { description: description, preview: preview });
/**
 * delete an asset version
 */
export const deleteAssetVersion = (assetId: number, version: number) => api.delete(`/assets/${assetId}/versions/${version}`);

/**
 * list all assets
 */
export const getAssetsList = (params?: {
  /**
   * Page number. Default: 1
   */
  page?: number;
  /**
   * Per page items. Default: 25
   */
  per_page?: number;
  /**
   * Search for assets matching the given id. It supports the A- prefix and partial ids
   */
  id?: string;
  /**
   * Search for assets belonging to campaigns matching the given name
   */
  campaign_name?: string;
  /**
   * Search for assets which were used with the specified media type
   */
  media_type_id?: number;
}) => api.get<AssetsList>(`/assets`, { params });

/**
 * generate a new asset
 */
export const generateNewAsset = () => api.post<{ id: number }>(`/assets`);

// CAMPAIGNS
/**
 * list campaigns
 */
export const getCampaignsList = (filters?: CampaignsFilters, sortValue?: string, page?: number) =>
  api.get<CampaignsList>(`/campaigns`, { params: { ...filters, sort: sortValue, page: page || 1 } });

/**
 * mark campaign description as viewed
 */
export const markCampaignDescription = (campaignId: number) => api.post(`/campaigns/${campaignId}/view`, {});

/**
 * create new campaign
 */
export const createCampaign = (data: CampaignBasicInfo) => api.post(`/campaigns`, data);

/**
 * copy campaign
 */
export const copyCampaign = (data: Partial<Campaign>) => api.post(`/campaigns/${data.id}/campaign_copies`, data);

/**
 * get campaign information
 */
export const getCampaign = (id: number) => api.get<Campaign>(`/campaigns/${id}`);

/**
 * delete campaign
 */
export const deleteCampaign = (id: number) => api.delete(`/campaigns/${id}`);

/**
 * update campaign information
 */
export const updateCampaignInformation = (campaignId: number, campaignBasicInfo: CampaignBasicInfo) =>
  api.put(`/campaigns/${campaignId}`, {
    ...campaignBasicInfo,
    predecessor_id: campaignBasicInfo.predecessor_id || '',
    end_date: campaignBasicInfo.end_date || '',
  });

/**
 * update campaign status
 */
export const updateCampaignStatus = (campaignId: number, newStatus?: 'created' | 'blocked' | 'disabled') =>
  api.post(`/campaigns/${campaignId}/status`, { status: newStatus });

/**
 * add a new output media types
 */
export const addNewOutputMedia = (id: number, mediaTypesIds: number[]) =>
  api.post(`/campaigns/${id}/output_media`, { media_types_ids: mediaTypesIds });

/**
 * delete campaign output media
 */
export const deleteCampaignOutputMedia = (campaignId: number, mediaTypeId: number) =>
  api.delete(`/campaigns/${campaignId}/output_media/${mediaTypeId}`);

/**
 * assign asset to campaign media type
 */
export const assignAssetCampaign = (campaignId: number, mediaTypeId: number, assetId: number) =>
  api.patch(`/campaigns/${campaignId}/output_media/${mediaTypeId}`, { asset_id: assetId });

/**
 * assign a product manager to the campaign
 */
export const assignProductManager = (campaignId: number, projectManagerId?: number) =>
  api.put(`/campaigns/${campaignId}/assign_pm`, { project_manager_id: projectManagerId });

/**
 * assign job information to the campaign
 */
export const assignJobInformation = (campaignId: number, jobNumber?: string) =>
  api.put(`/campaigns/${campaignId}/job_info`, { job_number: jobNumber });

/**
 *   downloads all campaign assets as a single zip file
 */
export const downloadMultiCampaignAssets = (campaignIds: number[], isPreview = false) =>
  api.get(`campaigns/download_data_link`, {
    params: { ids: campaignIds, preview: isPreview },
  });

/**
 *   generate a excel report from the overview
 */
export const exportReportOverview = (filters?: CampaignsFilters, sortValue?: string, perPage?: number) =>
  api.get<any>(`/campaigns.xlsx`, {
    params: { ...filters, sort: sortValue, page: 1, per_page: perPage },
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });

// CAMPAIGN COMMENTS
/**
 * lists campaign comments
 */
export const getCampaignComments = (
  campaignId: number,
  params?: {
    sort?: string;
    page?: number;
    per_page?: number;
    parent_comment_id?: number;
  }
) => api.get<CommentsList>(`/campaigns/${campaignId}/comments`, { params });

/**
 * create a new comment
 */
export const postNewComment = (campaignId: number, text: string, parentCommentId?: number) =>
  api.post(`/campaigns/${campaignId}/comments`, {
    text: text,
    parent_comment_id: parentCommentId,
  });

/**
 * delete a comment
 */
export const deleteComment = (campaignId: number, commentId: number) => api.delete(`/campaigns/${campaignId}/comments/${commentId}`);

/**
 * edit a comment
 */
export const editComment = (campaignId: number, commentId: number, text: string) =>
  api.put(`/campaigns/${campaignId}/comments/${commentId}`, {
    text: text,
  });

/**
 * list current user tasks on the given campaign
 */
export const getCampaignTasks = (
  campaignId: number,
  params?: {
    page?: number;
    per_page?: number;
    sort?: string;
    status?: string;
  }
) => api.get<TasksResponse>(`/campaigns/${campaignId}/tasks`, { params });

/**
 * list campaign events
 */
export const getCampaignEvents = (params?: { page?: number; perPage?: number; notice?: string }) =>
  api.get<{ events: CampaignEvent[]; pagination_meta: Pagination }>(`/events`, {
    params: {
      page: params?.page || 1,
      per_page: params?.perPage || 25,
      notice: params?.notice,
    },
  });

/**
 * list comments events
 */
export const getCommentsEvents = (params?: { page?: number; perPage?: number; notice?: string }) =>
  api.get<{ events: CommentEvent[]; pagination_meta: Pagination }>(`/events`, {
    params: {
      page: params?.page || 1,
      per_page: params?.perPage || 25,
      notice: params?.notice,
      for: 'comments',
    },
  });

/**
 * list media types
 */
export const getMediaTypes = (params?: { active?: boolean }) => {
  return api.get<MediaType[]>(`/media_types`, { params });
};

/**
 * create media types
 */
export const createMediaTypes = (title: string, group: string) => api.post(`/media_types`, { title: title, group: group });

/**
 * list tasks
 */
export const getTasks = (params?: { page?: number; per_page?: number; sort?: string; status?: string }) =>
  api.get<TasksResponse>(`/tasks`, { params });

/**
 * a visit to one of the campaigns pages
 */
export const postVisit = (campaignId: number, section: 'desk' | 'comments') =>
  api.post(`/visits`, {
    campaign_id: campaignId,
    section: section,
  });
