import { Skeleton } from 'Components';
import { api, useAppDispatch, useAppSelector } from 'Config';
import { SSO_URL } from 'Env';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import tw from 'twin.macro';
import { authStore } from '../lib';

const Button = tw.button`bg-primary-500 text-xl px-4 py-2 text-white rounded shadow hover:bg-primary-700`;
const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`w-full text-gray-400 focus:text-black border-gray-300 mt-2 border-solid border-2 shadow rounded p-4`, // Add base styles first
  active && tw`text-black`, // Then add conditional styles
]);

export default function ProfilePage() {
  const user = useAppSelector(authStore.selectUser);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Record<string, any>>({ ...user });
  const [saving, toggleSaving] = useState<{ formId: number; loading: boolean; errors?: any }>({ formId: 0, loading: false });
  const oldData = useRef<any>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    api
      .get(`/api/v2/profiles?filter[user]=${user?.id}&filter[membership]=${user?.membership_id}`, {
        baseURL: SSO_URL,
      })
      .then(({ data }) => {
        oldData.current = data.data[0].attributes;
        setLoading(false);
        setData({
          ...data.data[0].attributes,
          id: data.data[0].id,
        });
      })
      .catch(console.warn);
  }, [user?.id, user?.membership_id]);

  const onInputChange = useCallback((e) => {
    const val = e.target.value;
    const name = e.target.name;
    setData((odata) => {
      // toggle status indicate 'data changed'
      odata[name] !== val && toggleSaving({ formId: 1, loading: false });

      return {
        ...odata,
        [name]: val,
      };
    });
  }, []);

  const onInputChange2 = useCallback((e) => {
    const val = e.target.value;
    const name = e.target.name;
    setData((odata) => {
      // toggle status indicate 'data changed'
      odata[name] !== val && toggleSaving({ formId: 2, loading: false });

      return {
        ...odata,
        data: {
          ...(odata.data || {}),
          [name]: val,
        },
      };
    });
  }, []);

  const save = useCallback(
    (attributes) => {
      if (saving.loading) {
        return;
      }
      const profileId = data.id;
      oldData.current = attributes;
      toggleSaving((old) => ({ formId: old.formId, loading: true, errors: undefined }));
      return dispatch(authStore.editProfile(profileId, attributes))
        .then(() => {
          alert('Erfolgreich gespeichert!');
          toggleSaving({ formId: 0, loading: false, errors: undefined });
        })
        .catch((newErrors) => {
          alert('ist nicht gültig!');
          toggleSaving((old) => ({ formId: old.formId, loading: false, errors: newErrors.response.data }));
        });
    },
    [data?.id, dispatch, saving]
  );

  const submit = useCallback(
    (e: any) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const entries = Object.fromEntries(formData.entries());

      save(entries);
    },
    [save]
  );

  const submit2 = useCallback(
    (e: any) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = Object.fromEntries(formData.entries());

      save({ data });
    },
    [save]
  );
  if (loading) {
    return (
      <div>
        <Skeleton />
      </div>
    );
  }

  return (
    <Container className="page main-page">
      <Helmet>
        <title>Profil</title>
      </Helmet>
      <article className={'profile--page flex mt-6'}>
        {loading ? (
          <div className="flex-1 font-bold ">
            <h2 className="text-lg border-b border-solid border-gray-200 py-4 mb-4">Persönliche Daten</h2>
            <Skeleton />
          </div>
        ) : (
          <div className="flex-1 font-bold ">
            <h2 className="text-lg border-b border-solid border-gray-200 py-4 mb-4">Persönliche Daten</h2>
            <div>
              <div className="mb-8">
                <span className={`block text-gray-500`}>{'Vollständiger Name'}</span>
                <span>{data?.first_name + ' ' + data?.last_name}</span>
              </div>

              <div className="mb-8">
                <span className={`block text-gray-500`}>{'E-Mail Adresse'}</span>
                <span>{data?.email}</span>
              </div>

              <div className="mb-8">
                <span className={`block text-gray-500`}>{'Anmeldedatum'}</span>
                <span>{data?.created_at || '--'}</span>
              </div>
            </div>

            <form id="myform" className="mb-8" style={{ maxWidth: 700 }} onSubmit={submit}>
              <h2 className="text-lg border-b border-solid border-gray-200 py-4 mt-8 mb-4">VO/VP Nummer</h2>
              <label className="mt-8 block">
                <span className="text-gray-400 min-w-[100px] pr-6">VO Nummer</span>
                <Input active={saving.formId === 1} onChange={onInputChange} title="VO Nummer" name="vo_number" value={data.vo_number} />
              </label>
              <label className="mt-8 block">
                <span className="text-gray-400 min-w-[100px] font-normal pr-6">VO Nummer</span>
                <Input
                  active={saving.formId === 1}
                  onChange={onInputChange}
                  title="VP Nummer"
                  placeholder="VP Nummer"
                  name="vp_number"
                  value={data.vp_number}
                />
              </label>

              {saving.formId === 1 ? (
                <>
                  {saving.errors && <pre className="bg-red-200 text-red-800 rounded my-4">{JSON.stringify(saving.errors, null, 2)}</pre>}
                  <div className="w-full mt-6 flex justify-end">
                    <Button type="submit" disabled={!!saving.loading}>
                      Speichern
                    </Button>
                  </div>
                </>
              ) : null}
            </form>

            <form id="myform" className="mb-8" style={{ maxWidth: 700 }} onSubmit={submit2}>
              <h2 className="text-lg border-b border-solid border-gray-200 py-4 mt-8 mb-4">Weitere Daten</h2>
              {[
                ['telephone', 'Telefonnummer'],
                ['company', 'Firma'],
                ['area', 'Bereích'],
                ['partner', 'Handelspartner'],
              ].map(([name, label]) => (
                <label key={name} className="mt-8 block">
                  <span className="text-gray-400 min-w-[100px] pr-6">{label}</span>
                  <input
                    className=" w-full border-gray-300 mt-2 border-solid border-2 shadow rounded p-4"
                    onChange={onInputChange2}
                    title={label}
                    name={name}
                    value={(data?.data || {})[name]}
                  />
                </label>
              ))}

              {saving.formId === 2 ? (
                <>
                  {saving.errors && <pre className="bg-red-200 text-red-800 rounded my-4">{JSON.stringify(saving.errors, null, 2)}</pre>}
                  <div className="w-full mt-6 flex justify-end">
                    <Button type="submit" disabled={!!saving.loading}>
                      Speichern
                    </Button>
                  </div>
                </>
              ) : null}
            </form>
          </div>
        )}
        <aside className="ml-4 md:ml-6 lg:ml-8 max-w-[300px] mt-4">
          <h2 className="font-bold mb-8">Datenschutz-Bestimmungen</h2>
          <p className="mb-4">
            {`Die Deutsche Telekom AG legt großen Wert auf den Schutz Ihrer persönlichen Daten. Wir informieren Sie stets darüber, welche personenbezogenen Daten wir erheben, verwenden, verarbeiten und wie Sie diese beeinflussen können.`}
          </p>
          <p className="mb-4">
            {'Wenn Sie mehr über die Datenschutzbestimmungen erfahren möchten, rufen Sie unsere Datenschutzrichtlinien über folgenden Link auf:'}
          </p>

          <a
            className="text-blue-400 hover:text-blue-500"
            href="https://portal.smart.telekom-dienste.de/static_page/datenschutzerklaerung"
          >{`Datenschutz-Bestimmungen`}</a>
        </aside>
      </article>
    </Container>
  );
}

const Container = styled.div`
  h1 {
    border-bottom: 1px solid #8c8c8c;
    font-size: 18px;
    font-weight: bold;

    span {
      border-bottom: 2px solid var(--primary);
      display: inline-block;
      padding: 0.5em 0;
    }
  }
`;
