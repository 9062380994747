import { useState } from 'react';
import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_CREATOR, ROLE_EDITOR, ROLE_RECEIVER, ROLE_SUPPORT } from 'Components/constants';
import { useAppSelector } from 'Config';
import { authStore } from 'Features/Authentication/lib';

export function useUserHasRights() {
  const user = useAppSelector(authStore.selectEmediaUser);

  const hasRights =
    user?.role.toLowerCase() === ROLE_SUPER_ADMIN ||
    user?.role.toLowerCase() === ROLE_ADMIN ||
    user?.role.toLowerCase() === ROLE_EDITOR ||
    user?.role.toLowerCase() === ROLE_RECEIVER ||
    user?.role.toLowerCase() === ROLE_SUPPORT ||
    user?.role.toLowerCase() === ROLE_CREATOR;

  const [userHasRights] = useState(hasRights);

  return userHasRights;
}
