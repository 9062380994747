import { Modal } from 'Components';
import { API_URL } from 'Env';
import defaultImgSrc from 'Assets/nopreview.png';
import { GrDocumentZip } from 'react-icons/gr';

interface AssetPreviewProps {
  assetVersion: AssetVersion;
  onClose: () => void;
}

export default function AssetPreview({ assetVersion, onClose }: AssetPreviewProps) {
  const fileExtension = assetVersion.file.url.split('.').pop();
  return (
    <Modal
      visible={true}
      onRequestClose={onClose}
      bodyClasses="py-6 px-1 overflow-hidden"
      header={
        <div className="flex flex-col gap-2 items-start flex-1">
          <h3 className="text-xl font-extrabold break-all flex-1">{assetVersion.file.name}</h3>
        </div>
      }
      isPreviewImage
    >
      <div className="flex justify-center max-h-[calc(98vh-8.25rem-65px)]">
        {fileExtension === 'mp4' ? (
          <video controls src={`${API_URL.slice(0, -8)}${assetVersion.file.url}`}>
            <track default kind="captions" />
          </video>
        ) : fileExtension === 'pdf' ? (
          <a className=" border-[1px] border-gray-20" href={`${API_URL.slice(0, -8)}${assetVersion.file.url}`} target="_blank" rel="noreferrer">
            <img
              src={`${API_URL.slice(0, -8)}${assetVersion.file.preview_url}`}
              className="max-w-none"
              onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                if (e.target.src !== defaultImgSrc) {
                  e.target.onerror = null;
                  e.target.src = defaultImgSrc;
                }
              }}
              alt={`${assetVersion.file.name}`}
            />
          </a>
        ) : fileExtension === 'zip' ? (
          <>
            {assetVersion.file.preview_url ? (
              <img
                src={`${API_URL.slice(0, -8)}${assetVersion.file.preview_url}`}
                className="max-w-none"
                onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
                  if (e.target.src !== defaultImgSrc) {
                    e.target.onerror = null;
                    e.target.src = defaultImgSrc;
                  }
                }}
                alt={`${assetVersion.file.name}`}
              />
            ) : (
              <GrDocumentZip size={200} />
            )}
          </>
        ) : (
          <img
            src={`${API_URL.slice(0, -8)}${assetVersion.file.url}`}
            className="object-contain w-full h-auto max-w-screen-2xl"
            onError={(e: React.BaseSyntheticEvent<Event, EventTarget & HTMLImageElement, HTMLImageElement>) => {
              if (e.target.src !== defaultImgSrc) {
                e.target.onerror = null;
                e.target.src = defaultImgSrc;
              }
            }}
            alt={`${assetVersion.file.name}`}
          />
        )}
      </div>
    </Modal>
  );
}
