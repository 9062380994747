import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MediaTypesState {
  data: MediaType[];
  byGroup: Record<string, Array<MediaType>>;
}

export const initialState: MediaTypesState = {
  data: [],
  byGroup: {},
};

export const { actions, reducer, name } = createSlice({
  name: 'mediaTypes',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<MediaType[]>) => {
      state.byGroup = {};
      const orderedMediaTypes = action.payload.sort((a, b) => {
        if (a.group === b.group) {
          if (a.order === b.order) return a.title.localeCompare(b.title);
          if (a.order === 0 || a.order === undefined) return 1;
          if (b.order === 0 || b.order === undefined) return -1;
          return a.order - b.order;
        }

        if (a.group_order === b.group_order) return a.group.localeCompare(b.group);
        if (a.group_order === 0 || a.group_order === undefined) return 1;
        if (b.group_order === 0 || b.group_order === undefined) return -1;
        return a.group_order - b.group_order;
      });
      state.data = orderedMediaTypes;
      orderedMediaTypes.forEach((mediaType) => {
        if (!state.byGroup[mediaType.group]) {
          state.byGroup[mediaType.group] = [];
        }
        state.byGroup[mediaType.group].push(mediaType);
      });
      return state;
    },
  },
});

export const slice = (state: RootState): MediaTypesState => state[name] || initialState;

export const selectMediaTypes = (state: RootState) => {
  return slice(state).data;
};
export const selectMediaTypesMap = (state: RootState) => {
  return slice(state).data.reduce((acc, mediaType) => {
    acc[mediaType.id] = mediaType;
    return acc;
  }, {} as Record<string, MediaType>);
};
export const selectMediaTypesByGroup = (state: RootState) => {
  return slice(state).byGroup;
};
