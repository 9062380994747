import { useT } from 'Config';
import { useEffect, useState } from 'react';
import { BsChevronDoubleLeft, BsChevronLeft, BsChevronDoubleRight, BsChevronRight } from 'react-icons/bs';
import styled from 'styled-components';
import tw from 'twin.macro';

type CustomProp = { totalPages: number } | { pageSize: number };
interface PagintaionProps {
  totalPages: number;
  totalCount: number;
  currentPage: number;
  onSelect: (n: number) => void;
  loading?: boolean;
}

type Props = PagintaionProps & CustomProp;

export const PaginationStandard: React.FC<PagintaionProps> = (props: Props) => {
  const t = useT();
  const { loading, totalCount, currentPage, onSelect } = props;
  const totalPages = 'pageSize' in props ? Math.ceil(totalCount / props.pageSize) : props.totalPages;

  const [goPage, setGoPage] = useState<number | undefined>();

  useEffect(() => {
    setGoPage(undefined);
  }, [currentPage]);

  if (!totalCount || loading) {
    return null;
  }

  if (totalPages === 1) {
    return null;
  }
  const gotoButtonsDisabled = {
    first: currentPage <= 2 || !currentPage,
    prev: currentPage - 1 < 1 || !currentPage,
    next: currentPage + 1 > totalPages,
    last: currentPage >= totalPages - 1 || !totalPages,
  };
  return (
    <nav className="flex flex-row flex-wrap items-center w-full gap-8" aria-label="Pagination">
      <div className="flex flex-row flex-nowrap items-center">
        <ButtonPagination
          className="rounded-l-xl"
          title={t.pagination.first_page}
          aria-label={t.pagination.first_page}
          disabled={gotoButtonsDisabled.first}
          onClick={() => onSelect(1)}
        >
          <BsChevronDoubleLeft size={16} />
        </ButtonPagination>

        <ButtonPagination
          className=""
          title={t.pagination.previous_page}
          aria-label={t.pagination.previous_page}
          disabled={gotoButtonsDisabled.prev}
          onClick={() => onSelect(currentPage - 1)}
        >
          <BsChevronLeft size={16} />
        </ButtonPagination>

        <div className="max-h-[34px] flex p-2 flex-row flex-nowrap justify-center items-center text-xs border border-gray-200 h-full">
          {`${currentPage} ${t.pagination.out_of} ${totalPages}`}
        </div>

        <ButtonPagination
          className=""
          title={t.pagination.next_page}
          aria-label={t.pagination.next_page}
          disabled={gotoButtonsDisabled.next}
          onClick={() => onSelect(currentPage + 1)}
        >
          <BsChevronRight size={16} />
        </ButtonPagination>

        <ButtonPagination
          className="rounded-r-xl"
          title={t.pagination.last_page}
          aria-label={t.pagination.last_page}
          disabled={gotoButtonsDisabled.last}
          onClick={() => onSelect(totalPages)}
        >
          <BsChevronDoubleRight />
        </ButtonPagination>
      </div>
      <form
        className="inline-flex h-10 justify-center items-center bg-white text-gray-60"
        onSubmit={(e) => {
          e.preventDefault();
          if (goPage) onSelect(goPage);
        }}
      >
        <div className="pr-2">{t.pagination.go_to_page}</div>
        <input
          className="h-[80%] w-20 rounded-lg rounded-r-none pr-1 border-gray-60"
          type="number"
          placeholder={t.pagination.page}
          min="1"
          max={totalPages}
          required={true}
          value={goPage || ''}
          onChange={(e) => {
            setGoPage(e.target.value ? parseInt(e.target.value) : undefined);
          }}
        />
        <button
          type="submit"
          className="flex p-2 h-[80%] flex-row flex-nowrap justify-center items-center rounded-xl rounded-l-none border border-gray-200 max-h-[34px]
          hover:text-primary-500
          hover:bg-gray-200 hover:border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
          title={t.pagination.go_to_page}
        >
          {/* {t.pagination.go} */}
          <BsChevronRight />
        </button>
      </form>
    </nav>
  );
};

const ButtonPagination = styled.button`
  ${tw`flex p-2 justify-center items-center border border-gray-200 bg-white text-black hover:border-gray-300 hover:text-primary-500 focus:z-10 disabled:opacity-70 disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-default`}
`;
