const size = {
  xs: '475px',
  sm: '640px',
  med: '768px',
  lg: '1040px',
  xlg: '1296px',
  xxlg: '1552px',
};

const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  med: `(min-width: ${size.med})`,
  lg: `(min-width: ${size.lg})`,
  xlg: `(min-width: ${size.xlg})`,
  xxlg: `(min-width: ${size.xxlg})`,
};

export const breakpoint = {
  size,
  device,
};
