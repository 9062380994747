import { Action, configureStore, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api, ssoApi } from './api';
import { rootReducer } from './rootReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const middleWares = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
  thunk: {
    extraArgument: { api, ssoApi },
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(middleWares),
});

export const persistor = persistStore(store);
setupListeners(store.dispatch); // setup react-toolkit

export type AppGetState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<AppGetState>;

export type AppAsyncThunk<ReturnType = void, A = unknown> = ThunkAction<
  Promise<ReturnType>,
  RootState,
  typeof middleWares.thunk.extraArgument,
  PayloadAction<A>
>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, typeof middleWares.thunk.extraArgument, Action>;
