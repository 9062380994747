import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { actions } from './model';

export const fetch =
  (filters?: CampaignsFilters, sort?: string, page?: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.getCampaignsList(filters, sort, page).then(({ data }) => {
      dispatch(actions.set(data));
    });
