import { CSSProperties } from 'styled-components';

type ComponentProps = {
  fill?: string;
  size?: number;
  color?: string;
  strokeColor?: string;
};

const AlertInformation: React.FC<ComponentProps> = ({ fill = 'currentColor', size, strokeColor }) => {
  const style = {
    stroke: strokeColor,
    strokeWidth: '1px',
    fill: strokeColor,
  } as CSSProperties;
  return (
    <svg className="scale-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
      <title>information</title>
      <g fill={fill} style={strokeColor ? style : undefined}>
        <g>
          <path
            d="M12 1c6.1 0 11 4.9 11 11s-4.9 11-11 11S1 18.1 1 12 5.9 1 12 1zm0 1.5c-5.25 0-9.5 4.25-9.5 9.5s4.25 9.5 9.5 9.5 9.5-4.25 9.5-9.5-4.25-9.5-9.5-9.5zm1 8V17h-2v-6.5h2zm-1-4A1.25 1.25 0 1112 9a1.25 1.25 0 010-2.5z"
            fillRule="evenodd"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default AlertInformation;
