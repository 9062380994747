import {
  ScaleIconActionChangelog,
  ScaleIconActionNotification,
  ScaleIconCommunicationEmail,
  ScaleIconCommunicationMessage,
} from '@telekom/scale-components-react';
import { CustomTabs, Page } from 'Components';
import { useT } from 'Config';

import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Comments from './tabs/Comments';
import Infos from './tabs/Infos';
import Mails from './tabs/Mails';
import Tasks from './tabs/Tasks';

export default function UserDashboard() {
  const t = useT();
  const [tabIndex, setTabIndex] = useState(0);

  const tabsItems = [
    {
      icon: <ScaleIconActionChangelog size={16} />,
      label: t.dashboard.tabs.tasks,
      tabContent: <Tasks />,
    },
    {
      icon: <ScaleIconCommunicationMessage size={16} />,
      label: t.dashboard.tabs.comments,
      tabContent: <Comments />,
    },
    {
      icon: <ScaleIconActionNotification size={16} />,
      label: t.dashboard.tabs.infos,
      tabContent: <Infos />,
    },
    {
      icon: <ScaleIconCommunicationEmail size={16} />,
      label: t.dashboard.tabs.e_mails,
      tabContent: <Mails />,
    },
  ];

  return (
    <Page key={'user-dashboard'}>
      <Helmet>
        <title>{`${t.global.e_media} - ${t.dashboard.page_title}`}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full overflow-auto mx-[-10px] px-[10px]">
        <div className="flex items-start flex-col justify-between pt-10 pb-8 gap-4 text-left whitespace-nowrap w-full lg:flex-row lg:items-center">
          <h2 className="text-3xl text-gray-90 font-extrabold whitespace-pre-wrap">{t.dashboard.page_title}</h2>
        </div>

        <CustomTabs tabIndex={tabIndex} handleTabsChange={setTabIndex} items={tabsItems} />
      </div>
    </Page>
  );
}
