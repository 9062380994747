import { useAppDispatch, useAppSelector, useT } from 'Config';
import { FormatDate } from 'Helpers';
import React, { useEffect, useState } from 'react';
import NewComment from '../components/NewComment';
import { CommentState } from '../lib/model';
import { Modal, Spinner } from 'Components';
import { campaignComments } from '../lib';
import { Pagination } from 'Components';
import { authStore } from 'Features/Authentication/lib';
import styled from 'styled-components';
import tw from 'twin.macro';
import {
  ScaleIconActionClose,
  ScaleIconActionEdit,
  ScaleIconActionRemove,
  ScaleIconActionSuccess,
  ScaleIconNavigationCollapseDown,
  ScaleIconNavigationRight,
} from '@telekom/scale-components-react';

interface CommentProps {
  campaign: Campaign;
  comment: CommentState;
}

export default function Comment({ comment, campaign }: CommentProps) {
  const t = useT();
  const tCampaignComments = t.campaignComments;
  const dispatch = useAppDispatch();
  const userEmedia = useAppSelector(authStore.selectEmediaUser);
  const [editCommentMode, setEditCommentMode] = useState<boolean>(false);
  const [newComment, setNewComment] = useState<string>(comment.text);
  const [showNewComment, setShowNewComment] = useState<boolean>(false);
  const [showChildren, setShowChildren] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const refChildren = React.useRef<HTMLButtonElement>(null);
  const refTextArea = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!comment.parent_comment_id) return;
    if (showChildren && !comment.loadingChildren) {
      comment.children?.forEach((comment) => {
        dispatch(campaignComments.fetchChildren(campaign.id, comment.id));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChildren, comment.loadingChildren]);

  useEffect(() => {
    if (editCommentMode) {
      refTextArea.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCommentMode]);

  const setPage = (page) => {
    dispatch(campaignComments.fetchChildren(campaign.id, comment.id, page)).then(() => {
      refChildren.current?.focus();
    });
  };

  const addNewComment = (commentText: string) => {
    commentText &&
      dispatch(campaignComments.addNewComments(campaign.id, commentText, comment.id)).then(() => {
        setShowNewComment(false);
        setShowChildren(true);
      });
  };

  const deleteComment = () => {
    dispatch(campaignComments.deleteComments(campaign.id, comment.id, comment.parent_comment_id)).finally(() => setShowConfirmation(false));
  };

  const editComment = () => {
    dispatch(campaignComments.editComment(campaign.id, comment.id, newComment, comment.parent_comment_id));
  };

  return (
    <>
      <Modal
        visible={showConfirmation}
        onRequestClose={() => {
          setShowConfirmation(false);
        }}
        bodyClasses="py-6 px-1 overflow-auto"
        header={
          <div className="flex flex-col gap-2 items-start flex-1">
            <h3 className="text-xl font-extrabold break-all flex-1">{tCampaignComments.modalDelete.title}</h3>
          </div>
        }
      >
        <div className="flex gap-[13px] flex-col">
          <h3 className="text-base min-w-[350px]">{tCampaignComments.modalDelete.subtitle}</h3>
          <div className="flex justify-end gap-2 pt-5">
            <button
              title={tCampaignComments.modalDelete.no}
              aria-label={tCampaignComments.modalDelete.no}
              className="py-2 px-4 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
              onClick={() => setShowConfirmation(false)}
            >
              {tCampaignComments.modalDelete.no}
            </button>

            <button
              title={tCampaignComments.modalDelete.yes}
              aria-label={tCampaignComments.modalDelete.yes}
              className="py-2 px-4 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl"
              onClick={() => deleteComment()}
            >
              {tCampaignComments.modalDelete.yes}
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex flex-row gap-2 items-center">
        <h4 className="text-base font-bold text-gray-90">{`${comment.user.first_name} ${comment.user.last_name}`}</h4>
        <div className="text-xs text-gray-60">{FormatDate(comment.updated_at, false)}</div>
        {comment.created_at !== comment.updated_at && <div className="text-xs text-gray-60">{`(${tCampaignComments.processed})`}</div>}
        {!comment.loadingChildren && !comment.children?.length && comment.user.id === userEmedia?.id && (
          <>
            <button
              className="align-middle hover:text-primary-500"
              title={tCampaignComments.edit_comment}
              aria-label={tCampaignComments.edit_comment}
              onClick={() => {
                setEditCommentMode(true);
              }}
            >
              <ScaleIconActionEdit size={14} />
            </button>
            <button
              className="align-middle hover:text-primary-500"
              title={tCampaignComments.delete_comment}
              aria-label={tCampaignComments.delete_comment}
              onClick={() => {
                setShowConfirmation(true);
              }}
            >
              <ScaleIconActionRemove size={14} />
            </button>
          </>
        )}
      </div>
      {editCommentMode ? (
        <div>
          <TextArea
            className="w-full min-w-[280px]"
            active={true}
            name="comment"
            placeholder={tCampaignComments.newComment.write_comment}
            rows={5}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setNewComment(e.target.value);
            }}
            value={newComment}
            ref={refTextArea}
          ></TextArea>
          <div className="flex gap-2 justify-end w-full min-w-[280px]">
            <button
              className="align-middle text-red-70 hover:text-primary-500"
              title={tCampaignComments.exit}
              aria-label={tCampaignComments.exit}
              onClick={() => {
                setNewComment(comment.text);
                setEditCommentMode(false);
              }}
            >
              <ScaleIconActionClose size={18} />
            </button>
            <button
              className="align-middle text-green-100 hover:text-primary-500"
              title={tCampaignComments.update_comment}
              aria-label={tCampaignComments.update_comment}
              onClick={() => {
                editComment();
                setEditCommentMode(false);
              }}
            >
              <ScaleIconActionSuccess size={16} />
            </button>
          </div>
        </div>
      ) : (
        <p className="text-sm text-gray-60">{comment.text}</p>
      )}
      {showNewComment && (
        <NewComment
          title={tCampaignComments.newComment.write_answer_title}
          placeholder={tCampaignComments.newComment.write_answer}
          onAddComment={addNewComment}
          onCancel={() => setShowNewComment(false)}
        ></NewComment>
      )}
      <div className="flex flex-row flex-nowrap justify-start gap-2">
        {!comment.parent_comment_id && (
          <>
            <div className="flex flex-col">
              <button
                title={tCampaignComments.answer}
                aria-label={tCampaignComments.answer}
                className="py-1.5 px-3 w-min text-xs font-bold text-gray-90 border-[1px] border-gray-90 rounded-lg"
                onClick={() => {
                  setShowNewComment(!showNewComment);
                }}
              >
                {tCampaignComments.answer}
              </button>
            </div>

            <div className="flex flex-col gap-y-6 w-full">
              {comment.loadingChildren ? (
                <div className="flex flex-nowrap gap-1 justify-center items-center py-1.5 w-min whitespace-nowrap text-base font-bold text-primary-400">
                  <Spinner size={16} />
                  <div>{tCampaignComments.answers_loading}</div>
                </div>
              ) : !comment.children?.length ? (
                <div className="flex flex-nowrap gap-1 justify-center items-center py-1.5 w-min whitespace-nowrap text-base font-bold text-primary-400 opacity-0">
                  <div>{tCampaignComments.answers_loading}</div>
                </div>
              ) : (
                <button
                  ref={refChildren}
                  className="transition flex flex-nowrap gap-1 justify-center items-center py-1.5 w-min whitespace-nowrap text-base font-bold text-primary-500 rounded-lg disabled:opacity-70 disabled:cursor-default"
                  title={showChildren ? tCampaignComments.show_answers : tCampaignComments.hide_answers}
                  aria-label={showChildren ? tCampaignComments.show_answers : tCampaignComments.hide_answers}
                  onClick={() => setShowChildren(!showChildren)}
                  disabled={comment.loadingChildren}
                >
                  {showChildren ? (
                    <>
                      <ScaleIconNavigationCollapseDown size={16} />
                      <div>{tCampaignComments.hide_answers}</div>
                    </>
                  ) : (
                    <>
                      <ScaleIconNavigationRight size={16} />
                      <div>{tCampaignComments.show_answers}</div>
                    </>
                  )}
                </button>
              )}
              {showChildren && (
                <>
                  {comment.children?.map((child, index) => (
                    <Comment key={`comment-${child.id}-${index}`} campaign={campaign} comment={child}></Comment>
                  ))}
                  <Pagination
                    currentPage={comment.pagination.current_page}
                    onSelect={setPage}
                    totalCount={comment.pagination.total_count}
                    totalPages={comment.pagination.total_pages}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black `,
  active && tw`text-black`, // Then add conditional styles
]);
