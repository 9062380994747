import { client, useAppDispatch, useAppSelector, useT } from 'Config';
import { API_URL } from 'Env';
import { CustomCheckbox } from 'Components';
import { FormatDate } from 'Helpers';
import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { mediaCampaign } from '../lib';
import { authStore } from 'Features/Authentication/lib';
import {
  ScaleIconActionCircleClose,
  ScaleIconActionClose,
  ScaleIconActionDownload,
  ScaleIconActionEdit,
  ScaleIconActionPlay,
  ScaleIconActionRemove,
  ScaleIconActionSuccess,
} from '@telekom/scale-components-react';

interface AssetTableRowProps {
  assetId?: number;
  options?: {
    preview?: boolean;
    download?: boolean;
    delete?: boolean;
    reject?: boolean;
  };
  showCheckMark?: boolean;
  index: number;
  assetVersion: AssetVersion;
  setDeleteVersion: (value: number) => void;
  setRejectVersion: (value: number) => void;
  setLoading: (value: boolean) => void;
  setAssetVersionPreview: (value: AssetVersion) => void;
  setRejectedComment: (value: string) => void;
}

export default function AssetTableRow({
  index,
  assetVersion,
  showCheckMark,
  assetId,
  options = { preview: true, download: true, delete: true, reject: true },
  setDeleteVersion,
  setRejectVersion,
  setLoading,
  setAssetVersionPreview,
}: AssetTableRowProps) {
  const t = useT().mediaCampaign;
  const dispatch = useAppDispatch();
  const canEditNoteFineData = useAppSelector(authStore.canEditNoteFineData);
  const canDeleteFineData = useAppSelector(authStore.canDeleteFineData);
  const canRejectFineData = useAppSelector(authStore.canRejectFineData);
  const [editComment, setEditComment] = useState<Set<number>>(new Set());
  const assetHistory = useAppSelector(mediaCampaign.getAssetHistory(assetId || -1));

  const openEditComment = (index) => {
    setEditComment(new Set(editComment).add(index));
  };

  const closeEditComment = (index) => {
    const newEditComment = new Set(editComment);
    newEditComment.delete(index);
    setEditComment(newEditComment);
  };

  const setVersionDescription = (newDescription: string, versionIndex: number, preview: boolean) => {
    if (assetId && assetHistory) {
      setLoading(true);
      client
        .setAssetVersionDescription(assetId, assetHistory.versions[versionIndex].version, newDescription, preview)
        .then(() => {
          closeEditComment(versionIndex);
          assetId && dispatch(mediaCampaign.fetchAssetHistory(assetId));
        })
        .finally(() => setLoading(false));
    }
  };
  const isActiveUser = !!(assetVersion.updated_by?.first_name || assetVersion.updated_by?.last_name);

  return (
    <tr key={`row-${index}`}>
      <TableCell className="text-center">
        {assetVersion.status === 'created' && <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>}
        {assetVersion.status === 'rejected' && <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>}
      </TableCell>
      <TableCell>{assetVersion.version}</TableCell>
      <TableCell>
        {showCheckMark ? (
          assetVersion.preview ? (
            <ScaleIconActionSuccess size={22} />
          ) : null
        ) : (
          <CustomCheckbox
            checked={assetVersion.preview ?? false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVersionDescription(assetVersion.description, index, e.target.checked)}
            style={{ height: 16, width: 16 }}
          >
            <></>
          </CustomCheckbox>
        )}
      </TableCell>
      <TableCell>
        {assetVersion.file.name}
        {' - '}
        {(assetVersion.file.size / 1048576).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {'  MB'}
      </TableCell>
      <TableCell>{FormatDate(assetVersion.updated_at)}</TableCell>
      <TableCell>{isActiveUser ? `${assetVersion.updated_by?.first_name} ${assetVersion.updated_by?.last_name}` : t.inactive_user}</TableCell>
      <TableCell>
        {editComment.has(index) ? (
          <form
            id={`${assetHistory?.id}-${assetVersion.version}-${index}`}
            className="flex flex-col"
            onSubmit={(event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              setVersionDescription(formData.get('description')?.toString() || '', index, assetVersion.preview);
            }}
          >
            <TextArea
              className="w-[150px]"
              active={true}
              name="description"
              placeholder={`${t.assetTable.comment}...`}
              rows={5}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {}}
              defaultValue={assetVersion.description || ''}
              required={true}
            ></TextArea>
            <div className="flex gap-2 justify-end w-[150px]">
              <button
                className="align-middle text-red-70 hover:text-primary-500"
                title={t.assetTable.exit}
                aria-label={t.assetTable.exit}
                onClick={() => {
                  closeEditComment(index);
                }}
              >
                <ScaleIconActionClose size={18} />
              </button>
              <button
                id="button-submit"
                type="submit"
                form={`${assetHistory?.id}-${assetVersion.version}-${index}`}
                className="align-middle text-green-100 hover:text-primary-500"
                title={t.assetTable.leave_comment}
                aria-label={t.assetTable.leave_comment}
              >
                <ScaleIconActionSuccess size={16} />
              </button>
            </div>
          </form>
        ) : (
          <>
            <span className="pr-2">{assetVersion.description}</span>
            {canEditNoteFineData ? (
              <button
                className="align-middle hover:text-primary-500"
                title={t.assetTable.leave_comment}
                aria-label={t.assetTable.leave_comment}
                onClick={() => {
                  openEditComment(index);
                }}
              >
                <ScaleIconActionEdit size={16} />
              </button>
            ) : (
              <span className="pr-2">{!assetVersion.description && '-'}</span>
            )}
          </>
        )}
      </TableCell>
      <TableCell>{FormatDate(assetVersion.rejected_at)}</TableCell>
      <TableCell>{assetVersion.rejection_comment}</TableCell>
      <TableCell>
        <div className="flex justify-end gap-x-3.5">
          {options.preview && (
            <button
              className="flex items-center h-[22px] hover:text-primary-500"
              title={t.assetTable.preview}
              aria-label={t.assetTable.preview}
              onClick={() => {
                setAssetVersionPreview(assetVersion);
              }}
            >
              <ScaleIconActionPlay size={16} />
            </button>
          )}
          {options.download && (
            <a
              href={`${new URL(API_URL).origin}${assetVersion.file.url}?disposition=attachment`}
              download={assetVersion.file.name}
              className="flex items-center  hover:text-primary-500"
              title={t.assetTable.download}
              aria-label={t.assetTable.download}
            >
              <ScaleIconActionDownload size={16} />
            </a>
          )}
          {options.delete && canDeleteFineData && (
            <button
              className="flex items-center hover:text-primary-500"
              title={t.assetTable.delete}
              aria-label={t.assetTable.delete}
              onClick={() => {
                setDeleteVersion(assetVersion.version);
              }}
            >
              <ScaleIconActionRemove size={16} />
            </button>
          )}

          {options.reject && canRejectFineData && (
            <button
              className="flex items-center hover:text-primary-500 disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:text-gray-60"
              title={t.assetTable.reject}
              aria-label={t.assetTable.reject}
              onClick={() => setRejectVersion(assetVersion.version)}
              disabled={assetVersion.status === 'rejected'}
            >
              <ScaleIconActionCircleClose size={16} />
            </button>
          )}
        </div>
      </TableCell>
    </tr>
  );
}

const TableCell = styled.td`
  ${tw`px-2 py-4 align-top`}
`;

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black `,
  active && tw`text-black`, // Then add conditional styles
]);
