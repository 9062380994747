import { Navigate, Route, Routes } from 'react-router';
import ProfilePage from './Authentication/views/ProfilePage';
import Home from './HomePage';
import './index.css';
import MediaCampaign from './MediaCampaign';
import StaticPage from './StaticPages';
import CampaignComments from './CampaignComments';
import UserDashboard from './UserDashboard';
import AssetDetails from './AssetDetails';
import CampaignDetails from './CampaignDetails';
import NoDataPage from './NoDataPage';
import { useUserHasRights } from 'Components/hooks/useUserHasRights';

export default function AppRouter() {
  const hasRights = useUserHasRights();

  if (hasRights) {
    return (
      <Routes>
        <Route path="/profile" Component={ProfilePage} />
        <Route path="/statics/:slug" Component={StaticPage} />
        <Route path="/dashboard" Component={UserDashboard} />
        <Route path="/info/:campaignId" Component={CampaignDetails} />
        <Route path="/comments/:campaignId" Component={CampaignComments} />
        <Route path="/campaign/:campaignId/finedata" element={<MediaCampaign viewFineData={true} />} />
        <Route path="/campaign/:campaignId" Component={MediaCampaign} />
        <Route path="/asset/:campaignId/:assetId" Component={AssetDetails} />

        <Route path="/" Component={Home} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/profile" Component={ProfilePage} />
      <Route path="*" Component={NoDataPage} />
    </Routes>
  );
}
