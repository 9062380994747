import { Fragment, useState } from 'react';
import AddMedia from './AddMedia';
import { useAppSelector, useT } from 'Config';
import OutputMedia from './OutputMedia';
import { authStore } from 'Features/Authentication/lib';
import { ScaleIconActionAdd } from '@telekom/scale-components-react';

interface Props {
  campaign: Campaign;
}

export default function OutputMediaList({ campaign }: Props) {
  const t = useT().mediaCampaign;
  const canAddOutputMedia = useAppSelector(authStore.canAddOutputMedia);
  const [showAddMedia, setShowAddMedia] = useState<boolean>(false);
  const campaignOutputMedia = campaign.output_media_types;

  return (
    <Fragment>
      <AddMedia
        campaign={campaign}
        isOpen={showAddMedia}
        onRequestClose={() => {
          setShowAddMedia(false);
        }}
      ></AddMedia>

      <div className="flex flex-1 flex-col gap-4 bg-white mr-10 py-8">
        <div className="flex justify-between text-xl font-extrabold">
          <h5>{t.output_media}</h5>
          {!!campaignOutputMedia.length && <h5 className="flex justify-center items-center pr-4 w-36 ">{t.fine_data}</h5>}
        </div>
        {canAddOutputMedia && (
          <div>
            <button
              className="flex flex-nowrap gap-2 justify-center items-center text-[16px] text-white bg-primary-500 h-10 rounded-lg p-3"
              title={t.output_medium}
              aria-label={t.output_medium}
              onClick={() => {
                setShowAddMedia(true);
              }}
            >
              <ScaleIconActionAdd style={{ stroke: 'white' }} size={16} />
              <div className="truncate">{t.output_medium}</div>
            </button>
          </div>
        )}
        <div className="flex flex-col gap-6">
          {campaignOutputMedia.map((mediaType, index) => (
            <OutputMedia key={`${mediaType.id}_${index}`} campaign={campaign} campaignMediaType={mediaType}></OutputMedia>
          ))}
        </div>
      </div>
    </Fragment>
  );
}
