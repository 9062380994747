import { ReactNode } from 'react';

interface CheckboxProps {
  disabled?: boolean;
  children?: ReactNode;
  [x: string]: any;
}

export function CustomCheckbox({ disabled = false, children, ...props }: CheckboxProps) {
  return (
    <label className={`inline-flex gap-2 items-center ${!disabled ? 'cursor-pointer' : 'cursor-default'}`}>
      <input
        className={`rounded bg-white
        focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer
        checked:bg-primary-500 checked:border-primary-700
        hover:bg-gray-10 checked:hover:bg-primary-600 
        focus:bg-gray-10 checked:focus:bg-primary-600
        disabled:opacity-60 disabled:cursor-not-allowed w-fit`}
        type="checkbox"
        disabled={disabled}
        {...props}
      />
      {children}
    </label>
  );
}
