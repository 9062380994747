import { Modal } from 'Components';
import { useT } from 'Config';
import styled from 'styled-components';
import tw from 'twin.macro';
interface AssetRejectionProps {
  rejectVersion: number;
  setRejectVersion: (value: number) => void;
  rejectAssetVersion: (value: number) => void;
  setRejectedComment: (value: string) => void;
}

export default function AssetRejection({ rejectVersion, setRejectVersion, rejectAssetVersion, setRejectedComment }: AssetRejectionProps) {
  const t = useT().mediaCampaign;
  return (
    <Modal
      visible={rejectVersion > 0}
      onRequestClose={() => {
        setRejectVersion(-1);
      }}
      bodyClasses="py-6 px-1 overflow-auto"
      header={
        <div className="flex flex-col gap-2 items-start flex-1">
          <h3 className="text-xl font-extrabold break-all flex-1">{t.assetTable.modalReject.title}</h3>
        </div>
      }
    >
      <div className="flex gap-[13px] flex-col">
        <div className="flex flex-row gap-[2em]">
          <h3 className="text-base min-w-[150px]">{t.assetTable.modalReject.id}</h3>
          <p>{rejectVersion}</p>
        </div>

        <div className="flex flex-row gap-[2em]">
          <h3 className="text-base min-w-[150px]">{t.assetTable.modalReject.subtitle}</h3>
          <TextArea
            className="min-w-[350px]"
            active={true}
            name="rejectReason"
            placeholder="optional"
            rows={5}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setRejectedComment(e.target.value)}
            required={false}
          ></TextArea>
        </div>

        <div className="flex justify-end gap-2 pt-5">
          <button
            title={t.assetTable.modalReject.no}
            aria-label={t.assetTable.modalReject.no}
            className="py-2 px-4 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
            onClick={() => setRejectVersion(-1)}
          >
            {t.assetTable.modalReject.no}
          </button>

          <button
            title={t.assetTable.modalReject.yes}
            aria-label={t.assetTable.modalReject.yes}
            className="py-2 px-4 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl"
            onClick={() => {
              rejectAssetVersion(rejectVersion);
              setRejectVersion(-1);
            }}
          >
            {t.assetTable.modalReject.yes}
          </button>
        </div>
      </div>
    </Modal>
  );
}

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black `,
  active && tw`text-black`, // Then add conditional styles
]);
