import { AppAsyncThunk, client } from 'Config';
import { SSO_URL } from 'Env';
import { actions } from './model';

export const attemptLogout =
  (includeSSo: boolean): AppAsyncThunk =>
  (dispatch, _getState, { api }) => {
    dispatch({
      type: 'RESET',
      payload: undefined,
    });
    return includeSSo
      ? api.get('/api/v1/users/logout', {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          baseURL: SSO_URL,
        })
      : api.get('/users/logout');
  };

interface ProfileError {
  title: string;
  detail: string;
  code: string;
  source: { pointer: string };
  status: string;
}

export const editProfile =
  (id: string, attributes: any): AppAsyncThunk<any, Record<string, string>> =>
  (_dispatch, _getState, { api }) =>
    api
      .put(
        `/api/v2/profiles/${id}`,
        {
          data: {
            type: 'profiles',
            id,
            attributes,
          },
        },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          baseURL: SSO_URL,
        }
      )
      .catch((e) => {
        if (e?.response?.status === 422) {
          const { errors } = e?.response?.data || { errors: [] };
          const newErrors: Record<string, string> = {};
          errors.forEach((err: ProfileError) => {
            const name = err.source.pointer.split('/').pop()?.split('.').pop() || '__general';
            const val = err.title;
            newErrors[name] = newErrors[name] ? newErrors[name] + ', ' + val : val;
          });
          throw newErrors;
        }
      });

export const fetchUserEMedia = (): AppAsyncThunk => (dispatch, _getState) =>
  client.getMe().then(({ data }) => {
    dispatch(actions.setUserEMedia(data));
  });
