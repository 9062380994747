import { LogoClaim } from 'Components/Logo';
import { breakpoint } from 'Helpers';
import styled from 'styled-components';

export function AppFooter({ pages }: { pages: { name: string; href: string }[] }) {
  return (
    <>
      <NavCorner className="relative">
        <svg viewBox="0 0 10 10" className="nav-corner nav-corner--left">
          <path d="M0,0c0,6.7,3.3,10,10,10l0,0H0L0,0"></path>
        </svg>
        <svg viewBox="0 0 10 10" className="nav-corner nav-corner--right">
          <path d="M10 0 V10 H0 V10 Q10 10 10 0"></path>
        </svg>
      </NavCorner>
      <Footer data-testid="footer">
        <div className="footer-container">
          <div className="brand-images">
            <div className="brand-logo">
              <LogoClaim style={{ height: 30 }} />
            </div>
          </div>
          <div className="copyright-navigation">
            <p className="copyright"> {`© ${new Date().getFullYear()} Deutsche Telekom AG`} </p>

            <nav className="navigation">
              {pages &&
                pages.length > 0 &&
                pages.map((page, index) => (
                  <A key={`page-${index}`} data-testid="pages" href={page.href} rel="noopener noreferrer" className="text-light">
                    {page.name}
                  </A>
                ))}
            </nav>
          </div>
        </div>
      </Footer>
    </>
  );
}

const NavCorner = styled.div`
  .nav-corner {
    bottom: 100%;
    fill: var(--gray-900, #262626);
    height: 10px;
    position: absolute;
    width: 10px;
  }

  .nav-corner--left {
    left: 0;
  }
  .nav-corner--right {
    right: 0;
  }
`;

const Footer = styled.footer`
  background-color: var(--gray-900, #262626);
  bottom: 0;
  color: #fff;
  font-size: 1rem;
  margin: 0;
  padding: 1.5rem;
  padding-right: 2rem;
  position: relative;
  width: 100%;
  z-index: 1;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .brand-images {
    display: flex;
    flex-grow: 1;
    padding: 6px;
    justify-content: flex-start;
    align-items: center;

    .brand-logo {
      padding: 6px;
      img {
        width: 43px;
        he
      }
    }

    .claim {
      flex-grow: 1;
      padding-left: 20px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 800;
    }
  }

  .copyright-navigation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
  }

  .copyright {
    margin: 0;
    flex-grow: 1;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 14px;
    padding: 9px 12px 8px 12px;
    line-height: 1.125;
    font-weight: 600;
  }

  .navigation {
    align-items: center;
    justify-content: end;

    nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      row-gap: 1rem;
    }
  }

  @media (max-width: ${breakpoint.size.lg}) {
    .brand-images {
      flex: 1 1 100%;
    }
  }
`;

const A = styled.a`
  color: #fafafa;
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.125;
  padding: 9px 12px 8px;
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;

  &:hover {
    color: #fff !important;
    text-decoration: underline;
  }

  @media (max-width: ${breakpoint.size.xs}) {
    width: 100%;
  }
`;
