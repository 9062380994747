import { useT } from 'Config';
import { breakpoint } from 'Helpers';
import React, { ReactNode, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Logo } from '../Logo';

/**
 * Defaultlayout expect exactly 2 children
 * - search
 * - nav menu
 */

export function AppNavbar({ hide, data, children }: { children: ReactNode; data: any[]; showSearch?: boolean; hide: boolean }) {
  const t = useT().appHeader;
  const tGlobal = useT().global;
  const [ihide, i] = useState(hide);
  const [idx, focus] = useState(-1);
  const refMenu = React.useRef<HTMLElement>(null);

  useEffect(() => {
    setTimeout(() => i(hide), 300);
  }, [hide]);

  return (
    <Container className={`${ihide ? 'hide' : ''} ${idx !== -1 ? 'open' : ''}`}>
      <div className="border-b border-gray-200">
        <div className="page text-gray-90">
          <Link
            to="/"
            className={`logo ${!ihide ? 'pointer-events-none mr-0 invisible' : 'mr-4'}`}
            tabIndex={ihide ? 0 : -1}
            aria-hidden={ihide}
            aria-label={tGlobal.to_home_page}
            title={tGlobal.to_home_page}
          >
            <Logo aria-hidden={ihide} height={28} />
          </Link>
          <NavLink className="text-xl !font-extrabold !no-underline" title={t.overview_all_campaigns} aria-label={t.overview_all_campaigns} to="/">
            {t.overview}
          </NavLink>
          <nav className="nav flex-1">
            <ul className="flex ml-[-1em]">
              {data.map((category, _idx) => (
                <Li
                  key={category.id}
                  className={idx === _idx ? 'active' : ''}
                  onFocus={() => focus(_idx)}
                  onBlur={(e) => {
                    if (
                      refMenu.current &&
                      !(refMenu.current.contains(e.relatedTarget as Node) || (e.relatedTarget as HTMLElement).contains(refMenu.current))
                    ) {
                      focus(-1);
                    }
                  }}
                  onMouseOver={() => focus(_idx)}
                  onMouseLeave={(e) => {
                    if (
                      refMenu.current &&
                      !(refMenu.current.contains(e.relatedTarget as Node) || (e.relatedTarget as HTMLElement).contains(refMenu.current))
                    ) {
                      focus(-1);
                    }
                  }}
                >
                  <button className="ring-black font-bold p-2 px-4">{category.title}</button>
                </Li>
              ))}
            </ul>
          </nav>
          {children}
        </div>
      </div>
      <section
        ref={refMenu}
        className="page"
        onClick={() => focus(-1)}
        onMouseLeave={(e) => {
          if (refMenu.current && !refMenu.current.contains(e.relatedTarget as Node)) {
            focus(-1);
          }
        }}
      >
        {idx === -1 || !(idx < data.length) ? null : (
          <div className="menu p-1">
            {data[idx]?.children && (
              <Masonry
                breakpointCols={{
                  default: 5,
                  1500: 4,
                  1100: 3,
                  900: 2,
                  600: 1,
                }}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {data[idx].children?.map((children) => (
                  <ListItem key={children.id} data={children} level={1} />
                ))}
              </Masonry>
            )}
            {children}
          </div>
        )}
      </section>
    </Container>
  );
}

function ListItem({ data, level }: { data: any; level: number }) {
  return (
    <div>
      <NavLink
        to={`/${data?.id}`}
        end={level > 2}
        className={`leading-5 level-${level} ${data?.children || level === 1 ? 'font-semibold' : 'font-normal'}`}
      >
        {data?.title}
      </NavLink>
      {data?.children && (
        <div>
          {data.children.map((n: any) => (
            <ListItem key={n.id} data={n} level={level + 1} />
          ))}
        </div>
      )}
    </div>
  );
}

const Li = styled.li`
  position: relative;
  :after {
    background-color: transparent;
    content: ' ';
    display: block;
    height: 10px;
    position: absolute;
    left: 0;
    right: 0;
  }
`;

// const MobileNavContainer = styled.nav``;
const Container = styled.div`
  position: relative;
  margin-top: -4px;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  .my-masonry-grid {
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
  }
  .my-masonry-grid_column:not(:last-child) {
    margin-right: 2em;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 0;
  }

  .my-masonry-grid_column a {
    padding-bottom: 8px;
    overflow: hidden;
    width: 100%;
    display: inline-block;
  }

  .menu {
    max-width: var(--page-width);
    max-height: 0px;
    overflow: hidden;
    padding-top: 1em;
  }

  &:hover .menu,
  &.open .menu {
    max-height: 70vh;
    overflow-y: auto;
  }
  > div > div {
    margin: 0 auto;
    display: flex;
    align-items: center;
    min-height: 52px;
  }
  .logo svg {
    color: var(--primary);
    min-width: 1px;
    width: 1px;
    font-size: 1.5rem;
    transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  }
  li ul {
    margin-left: 1em;
  }
  .__old_col3 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .col3 {
    display: flex;
    flex-flow: column wrap;
    max-height: 50vh;
  }
  li a:hover {
    color: var(--primary);
  }
  .col3 > li {
    margin-top: 1em;
    flex: 1;
  }
  .col3 li > a {
    font-size: 16px;
    padding: 12px 12px 4px 12px;
    line-height: 2;
  }
  .level-1 {
    margin: 0;
    font-size: 16px;
  }
  .level-2 {
    font-size: 16px;
    padding-left: 12px;
  }
  .level-3 {
    font-size: 15px;
    padding-left: 24px;
  }
  .level-4 {
    font-size: 14px;
    padding-left: 30px;
  }
  .active {
    color: var(--primary);
  }
  a:hover,
  button:hover {
    cursor: pointer;
    color: var(--primary);
  }
  &.hide {
    border-radius: 0;
    margin-top: 4px;
    .logo > svg {
      min-width: 30px;
    }
  }

  @media (max-width: ${breakpoint.size.med}) {
    .page {
      padding-right: 0;
    }
  }
`;
