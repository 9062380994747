import '@reach/menu-button/styles.css';
import './index.css';
import React from 'react';
import { Menu, MenuList, MenuButton, MenuItem, MenuLink } from '@reach/menu-button';
import { NavLink } from 'react-router-dom';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { Spinner } from 'Components';

interface MenuProps {
  disabled?: boolean;
  loadingItems?: boolean;
  Icon?: React.ReactNode;
  label?: string;
  itemsLabel?: string;
  classes?: string;
  showChevron?: boolean;
  classesMenuList?: string;
  items: Array<
    | {
        label: string;
        href: string;
        newTab?: boolean;
        props?: any;
      }
    | {
        label: string;
        onSelect: () => void;
        props?: any;
      }
  >;
}

export function CustomDropDown(props: MenuProps) {
  const { disabled, loadingItems, classes, classesMenuList, Icon, label, itemsLabel, items, showChevron = true } = props;
  const disabledDropDown = items.length === 0 || disabled;
  return (
    <Menu>
      {({ isExpanded }) => (
        <React.Fragment>
          <MenuButton disabled={disabledDropDown} className={`inline-flex justify-center items-center gap-x-4 disabled:opacity-80 ${classes}`}>
            {loadingItems && <Spinner size={20} />}
            {label && <span>{label}</span>}
            {Icon && <span aria-hidden>{Icon}</span>}
            {showChevron && <>{isExpanded ? <BsChevronUp className="drop-down-chevron" /> : <BsChevronDown className="drop-down-chevron" />}</>}
          </MenuButton>
          <MenuList className={`rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-110 ${classesMenuList}`}>
            {itemsLabel && <div className="dropdown-header border-b px-4 pb-2 text-gray-600">{itemsLabel}</div>}
            {(props.items || []).map((item, index) =>
              'href' in item ? (
                item.newTab ? (
                  <MenuLink key={`custom-drop-down-${index}`} as="a" href={item.href} {...item.props} target={'_blank'}>
                    {item.label}
                  </MenuLink>
                ) : (
                  <MenuLink key={`custom-drop-down-${index}`} as={NavLink} to={item.href} {...item.props}>
                    {item.label}
                  </MenuLink>
                )
              ) : (
                <MenuItem key={`custom-drop-down-${index}`} onSelect={item.onSelect} {...item.props}>
                  {item.label}
                </MenuItem>
              )
            )}
          </MenuList>
        </React.Fragment>
      )}
    </Menu>
  );
}
