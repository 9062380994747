import '@reach/dialog/styles.css';
import './index.css';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { ReactNode } from 'react';
import { ScaleIconActionClose } from '@telekom/scale-components-react';
import styled from 'styled-components';

interface ModalProps {
  visible: boolean;
  title?: string;
  onRequestClose: (extrainfo: any) => void;
  children: any;
  header?: ReactNode;
  footer?: ReactNode;
  headerClasses?: string;
  bodyClasses?: string;
  isPreviewImage?: boolean;
}

export function Modal({ visible, onRequestClose, children, title, headerClasses, bodyClasses, header, footer, isPreviewImage }: ModalProps) {
  const close = () => onRequestClose(false);

  return (
    <DialogOverlayWrapper
      className="overflow-x-hidden overflow-y-auto fixed inset-0 z-101 outline-none focus:outline-none justify-center items-center"
      isOpen={visible}
      onDismiss={close}
      isPreviewImage={isPreviewImage || false}
    >
      <DialogContent
        className="
        overflow-auto
        border
        rounded-lg
        shadow-lg
        relative
        flex flex-col
        bg-white
        outline-none
        focus:outline-none
      "
        aria-label={title || 'Modal'}
      >
        <div
          className="modal-header
          flex
          gap-4
          items-center
          justify-between
          pb-5
          border-b border-solid border-gray-20
          rounded-t
        "
        >
          {header ? header : <h3 className={`text-3xl font-semibold break-all ${headerClasses}`}>{title}</h3>}
          <button
            className="
            p-1
            m-0
            bg-transparent
            border-0
            text-gray-400
            float-right
            text-3xl
            font-semibold
            outline-none
            focus:outline-none
          "
            onClick={close}
          >
            <span
              className="
              bg-transparent
              text-3xl
              block
              outline-none
              focus:outline-none
            "
            >
              <ScaleIconActionClose />
            </span>
          </button>
        </div>

        <div className={`modal-body ${bodyClasses}`}>{children}</div>
        {footer && <div className="modal-footer pt-2">{footer}</div>}
      </DialogContent>
    </DialogOverlayWrapper>
  );
}

const DialogOverlayWrapper = styled(DialogOverlay)<{ isPreviewImage: boolean }>`
  > [data-reach-dialog-content] {
    ${({ isPreviewImage }) => isPreviewImage && 'max-height: unset !important;'}
  }
`;
