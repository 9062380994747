import { CustomCheckbox, Modal } from 'Components';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import { mediaTypes } from 'Features/MediaTypes';
import { useEffect, useState } from 'react';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import styled from 'styled-components';
import tw from 'twin.macro';
import { mediaCampaign } from '../lib';

interface AddMediaProps {
  campaign: Campaign;
  isOpen: boolean;
  onRequestClose: Function;
}

export default function AddMedia({ campaign, isOpen, onRequestClose }: AddMediaProps) {
  const t = useT().mediaCampaign;
  const dispatch = useAppDispatch();
  const allMediaTypes = useAppSelector(mediaTypes.selectMediaTypes);
  const mediaTypesByGroup = useAppSelector(mediaTypes.selectMediaTypesByGroup);
  const [selectedIDs, setIDs] = useState<number[]>([]);
  const campaignOutputMediaIds = campaign.output_media_types.map((campaignOutputMedia) => campaignOutputMedia.id);

  useEffect(() => {
    setIDs(campaign.output_media_types.map((campaignOutputMedia) => campaignOutputMedia.id));
  }, [isOpen, campaign]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      mediaCampaign.addNewOutputMediaTypes(
        campaign.id,
        selectedIDs.filter((id) => !campaignOutputMediaIds.includes(id))
      )
    );
    onRequestClose();
  };

  const toggleAll = (selectAll?: boolean) => {
    setIDs(selectAll ? allMediaTypes.map((mediaType) => mediaType.id) : campaignOutputMediaIds);
  };

  const getAllIDslByGroup = (groupName: string) => {
    return allMediaTypes.reduce((result: number[], mediaType) => {
      return mediaType.group === groupName ? result.concat(mediaType.id) : result;
    }, []);
  };

  const toggleAllByGroup = (groupName: string, checked: boolean) => {
    const allIDslByGroup = getAllIDslByGroup(groupName);
    setIDs(
      checked
        ? Array.from(new Set(selectedIDs.concat(allIDslByGroup)))
        : selectedIDs.filter((mediaTypeId) => !allIDslByGroup.includes(mediaTypeId) || campaignOutputMediaIds.includes(mediaTypeId))
    );
  };

  const headerModal = (
    <div className="flex flex-col gap-2 items-start flex-1">
      <h3 className="text-xl font-extrabold break-all flex-1">{t.addMedia.title}</h3>
      <div className="flex items-start gap-4 w-full flex-col lg:flex-row lg:items-center">
        <h4 className="text-base break-all flex-1">{t.addMedia.subtitle}</h4>
        <div className="flex items-center gap-2 gap-x-5 flex-col sm:flex-row">
          <button
            className="flex flex-nowrap gap-2 justify-center items-center text-sm text-primary-500"
            title={t.addMedia.select_all}
            aria-label={t.addMedia.select_all}
            onClick={() => {
              toggleAll(true);
            }}
          >
            <BsCheckLg size={13} />
            <div>{t.addMedia.select_all}</div>
          </button>

          <button
            className="flex flex-nowrap gap-2 justify-center items-center text-sm text-primary-500"
            title={t.addMedia.deselect_all}
            aria-label={t.addMedia.deselect_all}
            onClick={() => {
              toggleAll();
            }}
          >
            <BsXLg size={13} />
            <div>{t.addMedia.deselect_all}</div>
          </button>
        </div>
      </div>
    </div>
  );

  const footerModal = (
    <div className="flex justify-end gap-2 pt-5 border-t border-solid border-gray-20">
      <button
        title={t.addMedia.discard_selection}
        aria-label={t.addMedia.discard_selection}
        className="py-2 px-4 w-40 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
        onClick={() => onRequestClose()}
      >
        {t.addMedia.discard_selection}
      </button>

      <button
        id="button-submit"
        type="submit"
        form="add-media-form"
        title={t.addMedia.create_selection}
        aria-label={t.addMedia.create_selection}
        className="py-2 px-4 w-40 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl"
      >
        {t.addMedia.create_selection}
      </button>
    </div>
  );
  return (
    <Modal
      visible={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
      bodyClasses="py-6 px-1 overflow-auto"
      header={headerModal}
      footer={footerModal}
    >
      <div className="flex gap-[13px] flex-col">
        <AddMediaForm id={'add-media-form'} className="gap-x-10 gap-y-8" onSubmit={onSubmit}>
          {Object.entries(mediaTypesByGroup).map(([groupName, mediaTypes]) => (
            <MediaFormatCol key={groupName} className="flex flex-col gap-y-2">
              <CustomCheckbox
                key={groupName}
                checked={getAllIDslByGroup(groupName).every((id) => selectedIDs.indexOf(id) > -1)}
                classesLabel="items-start items-center"
                style={{ height: 16, width: 16 }}
                onChange={(e) => {
                  toggleAllByGroup(groupName, e.target.checked);
                }}
              >
                <div className="text-base font-extrabold whitespace-nowrap">{groupName}</div>
              </CustomCheckbox>
              <div className="flex flex-col gap-y-2 pl-3.5">
                {mediaTypes
                  .filter((activeMediaType) => activeMediaType.active)
                  .map((mediaType) => (
                    <CustomCheckbox
                      key={mediaType.id}
                      checked={selectedIDs.includes(mediaType.id)}
                      disabled={campaign.output_media_types.some((campaignMedia) => campaignMedia.id === mediaType.id)}
                      classesLabel="items-start items-center"
                      style={{ height: 16, width: 16 }}
                      onChange={(e) => {
                        setIDs((ids) => {
                          return e.target.checked ? ids.concat(mediaType.id) : ids.filter((id) => id !== mediaType.id);
                        });
                      }}
                    >
                      <div className="max-w-[280px] whitespace-nowrap">
                        <div className="text-sm">{mediaType.title}</div>
                        {/* {mediaType.comment && <div className="text-xs">{'Comment CommentComment'}</div>} */}
                      </div>
                    </CustomCheckbox>
                  ))}
              </div>
            </MediaFormatCol>
          ))}
        </AddMediaForm>
      </div>
    </Modal>
  );
}

const MediaFormatCol = styled.div`
  ${tw`flex flex-col gap-y-2`}
`;

const AddMediaForm = styled.form`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;
