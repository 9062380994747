import { AxiosError } from 'axios';
import { DateTimePicker } from 'Components';
import ConfirmationModal from 'Components/ConfirmationModal';
import { useT, client } from 'Config';
import { formatISO } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import tw from 'twin.macro';

interface CopyCampaignFormProps {
  campaign: Campaign;
  setShowFormCopyAction: Function;
}

const CopyCampaignForm = ({ campaign, setShowFormCopyAction }: CopyCampaignFormProps) => {
  const t = useT();
  const tGlobal = useT().global;
  const navigate = useNavigate();
  const [countName, setCountName] = useState(0);
  const maxLengthName = 50;
  const { addToast } = useToasts();
  const [showConfirmCopyAction, setShowConfirmCopyAction] = useState<boolean>(false);
  const [replace, setReplace] = useState('no');
  const [updateCopiedAction, setUpdateCopiedAction] = useState<{
    name: string;
    start_date?: Date;
    end_date?: Date;
    id: number;
    replaced: boolean;
  }>({
    name: `${t.copyMedia.copy} ${campaign?.name}`,
    id: campaign.id,
    replaced: false,
  });

  const copyCampaign = () => {
    const updateCampaignForm: Partial<Campaign> = {
      ...updateCopiedAction,
      start_date: updateCopiedAction.start_date && formatISO(updateCopiedAction.start_date, { representation: 'date' }),
      end_date: updateCopiedAction.end_date ? formatISO(updateCopiedAction.end_date, { representation: 'date' }) : undefined,
      replaced: replace === 'yes' ? true : false,
    };
    client
      .copyCampaign(updateCampaignForm)
      .then((data) => {
        const { new_campaign_id } = data.data;
        addToast(
          <div role="alert">
            <div>{t.copyMedia.success}</div>
          </div>,
          {
            appearance: 'success',
            autoDismiss: true,
          }
        );
        navigate(`/campaign/${new_campaign_id}`);
      })
      .catch((e: AxiosError<any>) => {
        const error = e.response?.data?.errors?.toString();
        addToast(
          <div role="alert">
            <div>{error}</div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      });
  };
  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setReplace(value);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={true}
        title={t.copyMedia.page_title}
        buttonConfirm={t.copyMedia.create}
        buttonCancel={t.copyMedia.discard}
        disableButtonConfirm={!updateCopiedAction.name || !updateCopiedAction.start_date}
        onRequestClose={() => {
          setShowFormCopyAction(false);
        }}
        onConfirm={() => {
          setShowConfirmCopyAction(true);
        }}
      >
        <div className="flex gap-[13px] flex-col">
          <form id={'new-media-form'} className="flex flex-col gap-6">
            <div className="flex flex-col gap-2 w-full max-w-[415px]">
              <label htmlFor="name" className="row-label text-base font-bold">
                {t.copyMedia.form.action_name}
              </label>
              <Input
                className="text-sm min-w-[220px]"
                maxLength={maxLengthName}
                required={true}
                active={!!updateCopiedAction.name}
                onChange={(e) => {
                  setCountName(e.target.value.length);
                  setUpdateCopiedAction({ ...updateCopiedAction, name: e.currentTarget.value });
                }}
                placeholder={updateCopiedAction.name}
                title={updateCopiedAction.name}
                name="name"
                defaultValue={updateCopiedAction.name}
                autoComplete="off"
              />
              <div className="text-xs text-right min-w-[220px]">
                <span>{countName}</span>/<span>{maxLengthName}</span>{' '}
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label className="row-label text-base font-bold">{t.copyMedia.form.start_date}</label>
              <DateTimePicker
                key={'start_date'}
                type={'date'}
                required={true}
                name={'start_date'}
                minDate={new Date()}
                value={updateCopiedAction.start_date}
                placeholder={tGlobal.date}
                className="text-sm w-[220px] rounded-lg border-gray-90 border-[1px] shadow p-2 focus:text-black"
                classNameContainer="text-sm w-[220px] rounded-lg "
                onChangeTime={(date) => {
                  setUpdateCopiedAction({ ...updateCopiedAction, start_date: date });
                }}
              ></DateTimePicker>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label className="row-label text-base font-bold">{t.copyMedia.form.end_date}</label>
              <div className="flex flex-row flex-wrap gap-x-4">
                <DateTimePicker
                  key={'end_date'}
                  type={'date'}
                  name={'end_date'}
                  value={updateCopiedAction.end_date}
                  minDate={updateCopiedAction.start_date || new Date()}
                  placeholder={tGlobal.date}
                  className="text-sm w-[220px] min-w-[220px] rounded-lg border-gray-90 border-[1px] shadow p-2 focus:text-black"
                  classNameContainer="text-sm w-[220px] min-w-[220px] rounded-lg "
                  onChangeTime={(date) => {
                    setUpdateCopiedAction({ ...updateCopiedAction, end_date: date });
                  }}
                ></DateTimePicker>
              </div>
            </div>
            <div className="hidden flex-col gap-2 w-full">
              <label className="row-label text-base font-bold">{t.copyMedia.form.replace_action}</label>
              <div className="flex flex-row gap-4">
                <div>
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-primary-500 checked:border-4 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="settingsRadio"
                    id="confirm_replace_action"
                    value="yes"
                    onChange={radioHandler}
                    checked={replace === 'yes'}
                  />
                  <label className="text-sm inline-block text-gray-90 hover:cursor-pointer" htmlFor="confirm_replace_action">
                    {t.copyMedia.yes}
                  </label>
                </div>

                <div>
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:border-primary-500 checked:border-4 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="settingsRadio"
                    id="no_replace_action"
                    value="no"
                    onChange={radioHandler}
                    checked={replace === 'no'}
                  />
                  <label className="text-sm inline-block text-gray-90 hover:cursor-pointer" htmlFor="no_replace_action">
                    {t.copyMedia.no}
                  </label>
                </div>
              </div>
              {replace === 'yes' && <p className="text-sm text-red-90 italic max-w-[240px]">{t.copyMedia.form.replace_action_hint}</p>}
            </div>
          </form>
        </div>
      </ConfirmationModal>

      <ConfirmationModal
        isOpen={showConfirmCopyAction}
        title={t.campaignDetails.confirmationModal.title}
        buttonConfirm={t.campaignDetails.confirmationModal.copy_yes}
        buttonCancel={t.campaignDetails.confirmationModal.no}
        onRequestClose={() => {
          setShowConfirmCopyAction(false);
        }}
        onConfirm={() => {
          setShowConfirmCopyAction(false);
          copyCampaign();
        }}
      >
        <div className="flex flex-col gap-2 items-start flex-1">
          <h3 className="text-base min-w-[425px]">{t.campaignDetails.confirmationModal.copy_action}</h3>
        </div>
      </ConfirmationModal>
    </>
  );
};

const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black`,
  active && tw`text-black`,
]);

export default CopyCampaignForm;
