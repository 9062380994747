/* eslint-disable react-hooks/exhaustive-deps */
import { Page, Spinner, DateTimePicker, CustomDropDown } from 'Components';
import { formatISO } from 'date-fns';
import { useNavigate } from 'react-router';
import Trans, { tReplace } from 'Components/Trans';
import { client, useAppDispatch, useAppSelector, useT } from 'Config';
import { FormatDate, zeroFill } from 'Helpers';
import { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import styled from 'styled-components';
import tw from 'twin.macro';
import { mediaCampaign } from '../MediaCampaign/lib';
import { authStore } from 'Features/Authentication/lib';
import {
  ScaleIconActionClose,
  ScaleIconActionEdit,
  ScaleIconActionSuccess,
  ScaleIconNavigationLeft,
  ScaleIconActionHidePassword,
  ScaleIconActionRestart,
  ScaleIconActionRemove,
  ScaleIconActionCopyPaste,
} from '@telekom/scale-components-react';
import ConfirmationModal from '../../Components/ConfirmationModal';
import CopyCampaignForm from './CopyCampaignForm';
import { useToasts } from 'react-toast-notifications';

export default function CampaignDetails() {
  const t = useT();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let typingTimerName: NodeJS.Timeout; //timer identifier
  let doneTypingInterval = 1000; //time in ms (1 seconds)
  const toastManager = useToasts();
  const refActivationButton = useRef<HTMLButtonElement>(null);
  const refReplaceIdInput = useRef<HTMLInputElement>(null);
  const canEditEMADetails = useAppSelector(authStore.canEditEMADetails);
  const canEditJobnummer = useAppSelector(authStore.canEditJobnummer);
  const canSelectPM = useAppSelector(authStore.canSelectPM);
  const canCreateEMA = useAppSelector(authStore.canCreateEMA);
  const canEditEMAStatus = useAppSelector(authStore.canEditEMAStatus);
  const params = useParams<{ campaignId: string }>();
  const campaignId = Number(params.campaignId);
  const campaign = useAppSelector(mediaCampaign.getCampaign);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPMs, setLoadingPMs] = useState<boolean>(false);
  const [userPMs, setPMs] = useState<UserEmedia[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState<boolean>(false);
  const [campaignBasicInfo, setCampaignBasicInfo] = useState<CampaignBasicInfo>();
  const [newJobNumber, setNewJobNumber] = useState<string>();
  const [newProjectManager, setNewProjectManager] = useState<Partial<UserEmedia>>();
  const [newPredecessorId, setNewPredecessorId] = useState<number>();
  const [validatePredecessorId, setValidatePredecessorId] = useState<boolean>(false);
  const [replaceIdMessage, setReplaceIdMessage] = useState<string>();
  const [newIDIsValid, toggleValidation] = useState<boolean>(true);
  const [showConfirmEditAction, setShowConfirmEditAction] = useState<boolean>(false);
  const [showConfirmDeleteAction, setShowConfirmDeleteAction] = useState<boolean>(false);
  const [showFormCopyAction, setShowFormCopyAction] = useState<boolean>(false);
  const maxLengthName = 50;
  const maxLengthDescription = 500;
  const actionActivStatus = campaign?.status !== 'disabled';

  useEffect(() => {
    loadMediaCampaign();
  }, [params]);

  useEffect(() => {
    if (!campaign) return;

    if (!campaign?.description_viewed) {
      client.markCampaignDescription(campaign.id).catch((e) => {
        toastManager.addToast(
          <div role="alert">
            <div>{t.flashMessages.server_422}</div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      });
    }
  }, [campaign?.description_viewed]);

  useEffect(() => {
    resetFormData();
  }, [campaign]);

  useEffect(() => {
    if (newPredecessorId === campaign?.predecessor_id) {
      setReplaceIdMessage(undefined);
      toggleValidation(true);
    } else if (newPredecessorId === undefined) {
      setReplaceIdMessage(t.newMedia.form.action_replace_remove);
      toggleValidation(true);
    } else if (isNaN(newPredecessorId ?? 0)) {
      setReplaceIdMessage(t.newMedia.form.action_not_existing);
      toggleValidation(false);
    } else if (newPredecessorId) {
      setValidatePredecessorId(true);
      client
        .getCampaign(newPredecessorId)
        .then((data) => {
          const action = data.data;
          if (action.successor_id) {
            setReplaceIdMessage(tReplace(t.newMedia.form.action_already_blocked, { campaignId: zeroFill(action.successor_id, 6, 'E-') }));
            toggleValidation(false);
          } else {
            setReplaceIdMessage(t.newMedia.form.action_selected_is_valid);
            toggleValidation(true);
          }
        })
        .catch(() => {
          setReplaceIdMessage(t.newMedia.form.action_not_existing);
          toggleValidation(false);
        })
        .finally(() => {
          setValidatePredecessorId(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPredecessorId]);

  useEffect(() => {
    if (editMode) {
      if (!actionActivStatus) {
        refActivationButton.current?.focus();
      }
      setLoadingPMs(true);
      client
        .getAllUsers({ role: 'support' })
        .then((response) => {
          setPMs(response.data);
        })
        .finally(() => setLoadingPMs(false));
    }
  }, [editMode]);

  const onSubmit = (e) => {
    e.preventDefault();
    newIDIsValid ? setShowConfirmEdit(true) : refReplaceIdInput.current?.focus();
  };

  const updateCampaign = () => {
    setEditMode(false);
    if (campaign && campaignBasicInfo) {
      Promise.all([
        canEditEMADetails &&
          client.updateCampaignInformation(campaign.id, { ...campaignBasicInfo, ...(newIDIsValid && { predecessor_id: newPredecessorId }) }),
        campaign.job_number !== newJobNumber && client.assignJobInformation(campaign.id, newJobNumber),
        !!newProjectManager &&
          campaign.project_manager?.id !== newProjectManager.id &&
          client.assignProductManager(campaign.id, newProjectManager.id),
      ]).finally(() => {
        loadMediaCampaign();
      });
    }
    setShowConfirmEdit(false);
  };

  const updateCampaignStatus = (newStatus: 'created' | 'disabled') => {
    if (campaign && campaign.id && newStatus) {
      client.updateCampaignStatus(campaign.id, newStatus).then(() => {
        if (newStatus === 'disabled') {
          navigate(`/`);
        } else {
          setShowConfirmEditAction(false);
          loadMediaCampaign();
        }
      });
    }
  };

  const deleteCampaign = () => {
    if (campaign && campaign.id) {
      client.deleteCampaign(campaign.id).then(() => {
        navigate(`/`);
      });
    }
  };

  const loadMediaCampaign = () => {
    setLoading(true);
    dispatch(mediaCampaign.fetch(campaignId))
      .then(() => client.postVisit(campaignId, 'desk'))
      .finally(() => setLoading(false));
  };

  const resetFormData = () => {
    setEditMode(false);
    if (campaign) {
      setCampaignBasicInfo({
        name: campaign.name,
        start_date: campaign.start_date,
        end_date: campaign.end_date,
        description: campaign.description,
      });
      setNewPredecessorId(campaign.predecessor_id);
      setNewJobNumber(campaign.job_number);
      setNewProjectManager(campaign.project_manager);
    }
  };

  const formatBlockedByText = () => {
    const blocked = zeroFill(campaign?.successor_id, 6, 'E-');
    const href = `/campaign/${campaign?.successor_id}`;
    return t.campaignDetails.blockedByNote.replace('%{blockedById}', `<a href=${href} class="text-blue-60">${blocked}</a>`);
  };

  return (
    <Page key={'campaign-details'}>
      <Helmet>
        <title>{`${t.mediaCampaign.page_title} - ${t.campaignDetails.page_title}`}</title>
      </Helmet>
      <ConfirmationModal
        isOpen={showConfirmEdit}
        title={t.campaignDetails.confirmationModal.title}
        buttonConfirm={t.campaignDetails.confirmationModal.yes}
        buttonCancel={t.campaignDetails.confirmationModal.no}
        onRequestClose={() => {
          setShowConfirmEdit(false);
          resetFormData();
        }}
        onConfirm={() => {
          updateCampaign();
        }}
      >
        <div className="flex flex-col gap-2 items-start flex-1">
          <h3 className="text-base min-w-[350px]">{t.campaignDetails.confirmationModal.subtitle}</h3>
        </div>
      </ConfirmationModal>

      <div className="flex flex-col h-full w-full overflow-auto mx-[-10px] px-[10px]">
        <div className="flex flex-col justify-start items-start py-6 pt-10 w-full">
          <a
            className="flex flex-nowrap gap-2 items-center text-2xl text-primary-500 pr-2"
            title={t.global.back}
            aria-label={t.global.back}
            href={`/campaign/${params.campaignId}`}
          >
            <ScaleIconNavigationLeft size={16} />
            <span className="lowercase text-sm">{t.global.back}</span>
          </a>
        </div>

        <div className="flex items-start flex-col justify-between gap-4 text-left whitespace-nowrap w-full sm:flex-row sm:items-center">
          <h2 className="text-3xl text-gray-90 font-extrabold whitespace-pre-wrap">{t.campaignDetails.page_title}</h2>
          {!loading && (canEditEMADetails || canEditJobnummer || canSelectPM) && (
            <>
              {!editMode ? (
                <button
                  className="inline-flex flex-nowrap gap-2 justify-center items-center text-sm text-primary-500 hover:underline"
                  title={t.campaignDetails.edit}
                  aria-label={t.campaignDetails.edit}
                  onClick={() => {
                    setEditMode(true);
                    setReplaceIdMessage(undefined);
                  }}
                >
                  <ScaleIconActionEdit size={16} />
                  <div className="whitespace-nowrap">{t.campaignDetails.edit}</div>
                </button>
              ) : (
                <div className="flex gap-10">
                  <button
                    className="inline-flex flex-nowrap gap-2 justify-center items-center text-sm text-red-70 hover:underline"
                    title={t.campaignDetails.cancel}
                    aria-label={t.campaignDetails.cancel}
                    onClick={() => {
                      setEditMode(false);
                      resetFormData();
                    }}
                  >
                    <ScaleIconActionClose size={16} />
                    <div className="whitespace-nowrap">{t.campaignDetails.cancel}</div>
                  </button>

                  <button
                    id="button-submit"
                    type="submit"
                    form={`form-campaign-details`}
                    className="inline-flex flex-nowrap gap-2 justify-center items-center text-sm text-green-100 hover:underline 
                    disabled:cursor-not-allowed disabled:opacity-70 disabled:hover:no-underline"
                    title={t.campaignDetails.save}
                    aria-label={t.campaignDetails.save}
                    disabled={!actionActivStatus || validatePredecessorId}
                  >
                    <ScaleIconActionSuccess size={16} />
                    <div className="whitespace-nowrap">{t.campaignDetails.save}</div>
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {loading ? (
          <div className="flex h-40 mx-auto">
            <Spinner size={60} />
          </div>
        ) : campaign === undefined || campaignBasicInfo === undefined ? (
          <div className="flex flex-col justify-start items-start gap-2.5 py-8">
            <h2>
              <Trans id={`msg-no-campaign-found`} {...{ value: t.mediaCampaign.no_campaign_found, campaignId: params.campaignId }} />
            </h2>
          </div>
        ) : (
          <>
            <form
              id={`form-campaign-details`}
              className="flex flex-wrap justify-between gap-y-6 py-8 mt-8 border-t-[1px] border-gray-20"
              onSubmit={onSubmit}
            >
              {campaign.successor_id && (
                <div className="flex flex-row w-full">
                  <div className="text-sm text-red-60" dangerouslySetInnerHTML={{ __html: formatBlockedByText() }} />
                </div>
              )}
              {!actionActivStatus && (
                <div className="flex flex-row w-full">
                  <div className="text-sm text-red-60">{t.campaignDetails.action_is_disabled}</div>
                </div>
              )}
              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.id}</label>
                <div className="text-sm text-gray-60">{zeroFill(campaignId, 6, 'E-')}</div>
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.name}</label>

                {!editMode || !canEditEMADetails || !actionActivStatus ? (
                  <div className="text-sm text-gray-60">{campaignBasicInfo.name}</div>
                ) : (
                  <>
                    <Input
                      className="text-sm max-w-[415px] min-w-[220px]"
                      maxLength={maxLengthName}
                      required={true}
                      active={!!campaignBasicInfo.name}
                      onChange={(e) => {
                        setCampaignBasicInfo({ ...campaignBasicInfo, name: e.currentTarget.value });
                      }}
                      placeholder={t.campaignDetails.form.name}
                      title={t.campaignDetails.form.name}
                      name="name"
                      value={campaignBasicInfo.name || ''}
                      autoComplete="off"
                    />
                    <div className="text-xs text-right max-w-[415px] min-w-[220px]">
                      <span>{campaignBasicInfo.name?.length}</span>/<span>{maxLengthName}</span>
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.online_from}</label>

                {!editMode || !canEditEMADetails || !actionActivStatus ? (
                  <div className="text-sm text-gray-60">
                    {campaignBasicInfo.start_date ? (
                      FormatDate(campaignBasicInfo.start_date, false, false)
                    ) : (
                      <span className="text-red-70">{t.campaignDetails.form.not_specified}</span>
                    )}
                  </div>
                ) : (
                  <DateTimePicker
                    key={'start_date'}
                    type={'date'}
                    required={true}
                    name={'start_date'}
                    value={campaignBasicInfo.start_date ? new Date(campaignBasicInfo.start_date) : undefined}
                    minDate={undefined}
                    placeholder={t.global.date}
                    className="text-sm w-[220px] rounded-lg text-gray-60 border-gray-90 border-[1px] shadow p-2 focus:text-black"
                    classNameContainer="text-sm max-w-[415px] min-w-[220px] rounded-lg"
                    onChangeTime={(date) => {
                      setCampaignBasicInfo({ ...campaignBasicInfo, start_date: date ? formatISO(date, { representation: 'date' }) : undefined });
                    }}
                  ></DateTimePicker>
                )}
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.online_until}</label>

                {!editMode || !canEditEMADetails || !actionActivStatus ? (
                  <div className="text-sm text-gray-60">
                    {campaignBasicInfo.end_date ? (
                      FormatDate(campaignBasicInfo.end_date, false, false)
                    ) : (
                      <span className="text-red-70">{t.campaignDetails.form.unlimited_term}</span>
                    )}
                  </div>
                ) : (
                  <DateTimePicker
                    key={'end_date'}
                    type={'date'}
                    name={'end_date'}
                    value={campaignBasicInfo.end_date ? new Date(campaignBasicInfo.end_date) : undefined}
                    minDate={campaignBasicInfo.start_date ? new Date(campaignBasicInfo.start_date) : undefined}
                    placeholder={t.global.date}
                    className="text-sm w-[220px] rounded-lg text-gray-60 border-gray-90 border-[1px] shadow p-2 focus:text-black"
                    classNameContainer="text-sm max-w-[415px] min-w-[220px] rounded-lg "
                    onChangeTime={(date) => {
                      setCampaignBasicInfo({ ...campaignBasicInfo, end_date: date ? formatISO(date, { representation: 'date' }) : undefined });
                    }}
                  ></DateTimePicker>
                )}
              </div>

              <div className="flex flex-col gap-y-2 w-full">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.description}</label>

                {!editMode || !canEditEMADetails || !actionActivStatus ? (
                  <div className="text-sm text-gray-60 max-w-[800px]">{campaignBasicInfo.description}</div>
                ) : (
                  <>
                    <TextArea
                      id="description"
                      className="text-sm max-w-[415px] min-w-[220px]"
                      maxLength={maxLengthDescription}
                      active={!!campaignBasicInfo.description}
                      name="description"
                      placeholder={t.campaignDetails.form.description}
                      rows={5}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setCampaignBasicInfo({ ...campaignBasicInfo, description: e.target.value });
                      }}
                      value={campaignBasicInfo.description}
                    ></TextArea>
                    <div className="text-xs text-right max-w-[415px] min-w-[220px]">
                      <span>{campaignBasicInfo.description?.length}</span>/<span>{maxLengthDescription}</span>
                    </div>
                  </>
                )}
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.number_output_media}</label>
                <div className="text-sm text-gray-60">{campaign.output_media_types.length}</div>
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.placement_category}</label>
                <div className="text-sm text-gray-60">{campaign.placement_t}</div>
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.output_media}</label>

                <div className="text-sm text-gray-60 max-w-[800px]">
                  {campaign.output_media_types.map((outputMedia, index) => (
                    <span key={index}>
                      {index > 0 && ', '}
                      {outputMedia.title}
                    </span>
                  ))}
                </div>
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.project_manager}</label>
                {!editMode || !canSelectPM || !actionActivStatus ? (
                  <div className="text-sm text-gray-60">
                    {newProjectManager
                      ? !!newProjectManager.first_name && !!newProjectManager.last_name
                        ? `${newProjectManager.first_name || ''} ${newProjectManager.last_name || ''}`
                        : newProjectManager.email
                      : t.campaignDetails.form.not_specified}
                  </div>
                ) : (
                  <CustomDropDown
                    classes="max-w-[415px] min-w-[220px] px-4 py-2 rounded-md border border-gray-300 shadow-sm bg-white text-sm text-gray-60
          hover:bg-gray-200 hover:border-primary-500
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-500 focus:ring-primary
          disabled:cursor-default disabled:border-gray-300 disabled:bg-white"
                    classesMenuList="max-w-[415px] min-w-[220px]"
                    disabled={loadingPMs}
                    loadingItems={loadingPMs}
                    label={
                      loadingPMs
                        ? t.campaignDetails.loading_project_managers
                        : newProjectManager
                        ? !!newProjectManager.first_name && !!newProjectManager.last_name
                          ? `${newProjectManager.first_name || ''} ${newProjectManager.last_name || ''}`
                          : newProjectManager.email
                        : t.campaignDetails.form.not_specified
                    }
                    items={userPMs.map((user) => ({
                      label: !!user.first_name && !!user.last_name ? `${user.first_name || ''} ${user.last_name || ''}` : user.email,
                      onSelect: () => {
                        setNewProjectManager(user);
                      },
                    }))}
                  />
                )}
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.job_number}</label>

                {!editMode || !canEditJobnummer || !actionActivStatus ? (
                  <div className="text-sm text-gray-60">{newJobNumber ? newJobNumber : t.campaignDetails.form.not_specified}</div>
                ) : (
                  <>
                    <Input
                      className="text-sm max-w-[415px] min-w-[220px]"
                      active={!!newJobNumber}
                      onChange={(e) => {
                        setNewJobNumber(e.currentTarget.value);
                      }}
                      placeholder={t.campaignDetails.form.job_number}
                      title={t.campaignDetails.form.job_number}
                      name="job_number"
                      value={newJobNumber || ''}
                      autoComplete="off"
                    />
                  </>
                )}
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.created_on}</label>
                <div className="text-sm text-gray-60">{FormatDate(campaign.created_at)}</div>
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.created_by}</label>
                <div className="text-sm text-gray-60">
                  {!!campaign.created_by ? `${campaign.created_by.first_name} ${campaign.created_by.last_name}` : t.mediaCampaign.inactive_user}
                </div>
              </div>

              <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.last_changed_at}</label>
                <div className="text-sm text-gray-60">{FormatDate(campaign.updated_at || '')}</div>
              </div>

              {(campaign.successor_id || campaign.predecessor_id || (editMode && canEditEMADetails && actionActivStatus)) && (
                <div className="flex flex-col gap-y-2 w-[calc(50%-0.5rem)]">
                  {campaign.successor_id && (
                    <>
                      <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.replaced_by}</label>
                      <div className="text-sm text-gray-60">{zeroFill(campaign.successor_id, 6, 'E-')}</div>
                    </>
                  )}
                  {(campaign.predecessor_id || (editMode && canEditEMADetails && actionActivStatus)) && (
                    <>
                      <label className="text-base text-gray-90 font-bold">{t.campaignDetails.form.replacing_action}</label>

                      {!editMode || !canEditEMADetails || !actionActivStatus ? (
                        <div className="text-sm text-gray-60">{zeroFill(campaign.predecessor_id, 6, 'E-')}</div>
                      ) : (
                        <>
                          <Input
                            ref={refReplaceIdInput}
                            className="text-sm max-w-[415px] min-w-[220px]"
                            active={!!newPredecessorId}
                            onChange={(e) => {
                              clearTimeout(typingTimerName);
                              const { currentTarget } = e;
                              typingTimerName = setTimeout(function () {
                                const value = currentTarget.value;
                                const regex = /[0-9]+/;
                                const match = value.match(regex);
                                setReplaceIdMessage(undefined);
                                toggleValidation(false);
                                setNewPredecessorId(value.length ? parseInt(match?.[0] ?? '') : undefined);
                              }, doneTypingInterval);
                            }}
                            placeholder={t.campaignDetails.form.replacing_action}
                            title={t.campaignDetails.form.replacing_action}
                            name="predecessor_id"
                            defaultValue={newPredecessorId}
                            autoComplete="off"
                          />
                          {replaceIdMessage && (
                            <p className={`${newIDIsValid ? 'text-green-100' : 'text-red-500'} text-sm italic`}>{replaceIdMessage}</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </form>
          </>
        )}

        {campaign && showFormCopyAction && <CopyCampaignForm campaign={campaign} setShowFormCopyAction={setShowFormCopyAction} />}

        <ConfirmationModal
          isOpen={showConfirmEditAction}
          title={t.campaignDetails.confirmationModal.title}
          buttonConfirm={actionActivStatus ? t.campaignDetails.confirmationModal.deactivate_yes : t.campaignDetails.confirmationModal.reactivate_yes}
          buttonCancel={t.campaignDetails.confirmationModal.no}
          onRequestClose={() => {
            setShowConfirmEditAction(false);
          }}
          onConfirm={() => {
            updateCampaignStatus(actionActivStatus ? 'disabled' : 'created');
          }}
        >
          <div className="flex flex-col gap-2 items-start flex-1">
            <h3 className="text-base min-w-[425px]">
              {actionActivStatus ? t.campaignDetails.confirmationModal.deactivate_action : t.campaignDetails.confirmationModal.reactivate_action}
            </h3>
          </div>
        </ConfirmationModal>

        <ConfirmationModal
          isOpen={showConfirmDeleteAction}
          title={t.campaignDetails.delete_action}
          buttonConfirm={t.campaignDetails.confirmationModal.delete_yes}
          buttonCancel={t.campaignDetails.confirmationModal.no}
          onRequestClose={() => {
            setShowConfirmDeleteAction(false);
          }}
          onConfirm={() => {
            deleteCampaign();
          }}
        >
          <div className="flex flex-col gap-2 items-start flex-1">
            <h3 className="text-base min-w-[425px]">{t.campaignDetails.confirmationModal.delete_action}</h3>
          </div>
        </ConfirmationModal>

        {editMode && (
          <div className="flex gap-4">
            {canEditEMAStatus && (
              <button
                ref={refActivationButton}
                title={actionActivStatus ? t.campaignDetails.deactivate_action : t.campaignDetails.reactivate_action}
                aria-label={actionActivStatus ? t.campaignDetails.deactivate_action : t.campaignDetails.reactivate_action}
                className="flex flex-nowrap gap-2 justify-center items-center py-1.5 px-3 mb-8 w-min text-base font-bold text-gray-90 border-[1px] border-gray-90 rounded-lg"
                onClick={() => setShowConfirmEditAction(true)}
              >
                {actionActivStatus ? <ScaleIconActionHidePassword size={16} /> : <ScaleIconActionRestart size={16} />}
                <div className="truncate">{actionActivStatus ? t.campaignDetails.deactivate_action : t.campaignDetails.reactivate_action}</div>
              </button>
            )}

            <button
              title={t.campaignDetails.copy_action}
              aria-label={t.campaignDetails.copy_action}
              className="flex flex-nowrap gap-2 justify-center items-center py-1.5 px-3 mb-8 w-min text-base font-bold text-gray-90 border-[1px] border-gray-90 rounded-lg"
              onClick={() => setShowFormCopyAction(true)}
            >
              <ScaleIconActionCopyPaste size={16} />
              <div className="truncate"> {t.campaignDetails.copy_action}</div>
            </button>

            {canCreateEMA && (
              <button
                title={t.campaignDetails.delete_action}
                aria-label={t.campaignDetails.delete_action}
                className="flex flex-nowrap gap-2 justify-center items-center py-1.5 px-3 mb-8 w-min text-base font-bold text-gray-90 border-[1px] border-gray-90 rounded-lg"
                onClick={() => setShowConfirmDeleteAction(true)}
              >
                <ScaleIconActionRemove size={16} />
                <div className="truncate">{t.campaignDetails.delete_action}</div>
              </button>
            )}
          </div>
        )}
      </div>
    </Page>
  );
}

const Input = styled.input(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black`,
  active && tw`text-black`, // Then add conditional styles
]);

const TextArea = styled.textarea(({ active }: { active?: boolean }) => [
  tw`text-gray-60 border-gray-90 border-[1px] shadow rounded-lg p-2 focus:text-black `,
  active && tw`text-black`, // Then add conditional styles
]);
