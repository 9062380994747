import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CampaignsState {
  data: Campaign[];
  byId: Record<string, Campaign>;
  filter: CampaignsFilters;
  displayOptions: string[];
  sort: string;
  pagination: Pagination;
  resetFilter: boolean;
  mediaTypesLoaded: boolean;
}

export const initialState: CampaignsState = {
  data: [],
  byId: {},
  filter: {},
  displayOptions: [],
  sort: '-id',
  pagination: {
    current_page: 0,
    total_count: 0,
    total_pages: 0,
  },
  mediaTypesLoaded: false,
  resetFilter: true,
};

export const { actions, reducer, name } = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<CampaignsList>) => {
      state.pagination = action.payload.pagination_meta;

      state.data = action.payload.campaigns;
      state.byId = {};
      action.payload.campaigns.forEach((campaign) => {
        state.byId[campaign.id] = campaign;
      });
      return state;
    },
    setFilters: (state, action: PayloadAction<CampaignsFilters>) => {
      state.filter = action.payload;
      state.pagination = {
        current_page: 1,
        total_count: 0,
        total_pages: 0,
      };
    },

    setSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
      state.pagination = {
        current_page: 1,
        total_count: 0,
        total_pages: 0,
      };
    },

    setPagination: (state, action: PayloadAction<Pagination>) => {
      state.pagination = action.payload;
    },

    setDisplayOptions: (state, action: PayloadAction<string[]>) => {
      state.displayOptions = action.payload;
    },

    setResetFilter: (state, action: PayloadAction<boolean>) => {
      state.resetFilter = action.payload;
    },

    setMediaTypesLoaded: (state, action: PayloadAction<boolean>) => {
      state.mediaTypesLoaded = action.payload;
    },

    setToInitialState: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const slice = (state: RootState): CampaignsState => state[name] || initialState;

export const selectCampaigns = (state: RootState) => {
  return slice(state).data;
};
export const selectCampaignsById = (state: RootState) => {
  return slice(state).byId;
};

export const getFilters = (state: RootState) => slice(state).filter;

export const getSort = (state: RootState) => slice(state).sort;

export const getPagination = (state: RootState) => slice(state).pagination;

export const getDisplayOptions = (state: RootState) => slice(state).displayOptions;

export const resetFilter = (state: RootState) => slice(state).resetFilter;

export const mediaTypesLoaded = (state: RootState) => slice(state).mediaTypesLoaded;
