import { RootState } from 'Config/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MediaCampaignState {
  data?: Campaign;
  assetsHistory: Array<AssetHistory>;
}

export const initialState: MediaCampaignState = {
  data: undefined,
  assetsHistory: [],
};

export const { actions, reducer, name } = createSlice({
  name: 'mediaCampaign',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Campaign | undefined>) => {
      state.data = action.payload;
      state.assetsHistory = [];
      return state;
    },
    setAssetHistory: (state, action: PayloadAction<{ assetId: number; assetHistory: AssetHistory }>) => {
      const historySortByVersion = {
        ...action.payload.assetHistory,
        history: action.payload.assetHistory.versions.sort((a, b) => b.version - a.version),
      };
      if (state.assetsHistory.some((obj) => obj.id === action.payload.assetId)) {
        state.assetsHistory = state.assetsHistory.map((obj) => (obj.id === action.payload.assetId ? historySortByVersion : obj));
      } else {
        state.assetsHistory.push(historySortByVersion);
      }
      return state;
    },
  },
});

export const slice = (state: RootState): MediaCampaignState => state[name] || initialState;

export const getCampaign = (state: RootState) => {
  return slice(state).data;
};

export const getAssetHistory = (id: number) => (state: RootState) => {
  return slice(state).assetsHistory.find((history) => history.id === id);
};
