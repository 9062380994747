import { CSSProperties, MouseEventHandler } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export function BreadCrumb({
  links,
  style,
  className,
}: {
  style?: CSSProperties | undefined;
  links: { title: string; link: string; onClick?: MouseEventHandler }[];
  className?: string;
}) {
  return (
    <Container style={style} className={className}>
      <ul>
        {links.map(({ title, link = '/', onClick }, idx) => (
          <li key={link} className={`capitalize ${links[idx + 1] ? 'text-gray-400' : 'text-black font-semibold'}`}>
            {link ? (
              <>
                <Link onClick={onClick} to={link}>
                  {title}
                </Link>
                {links[idx + 1] ? <BsChevronRight /> : null}
              </>
            ) : (
              title
            )}
          </li>
        ))}
      </ul>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  ul {
    display: flex;
    justify-content: flex-start;
  }
  li {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    a {
      font-size: 15px;
      margin: 0 -12px;
      padding: 9px 12px 7px;
      display: inline-block;
      vertical-align: middle;
      outline-offset: -1px;

      &:hover {
        text-decoration: none;
      }
    }
    svg {
      font-size: 12px;
      margin: 4px 0 0 12px;
    }
  }
`;
