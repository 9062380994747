/* eslint-disable react-hooks/exhaustive-deps */
import { Page, Spinner } from 'Components';
import Trans from 'Components/Trans';
import { client, useAppDispatch, useAppSelector, useT } from 'Config';
import { zeroFill } from 'Helpers';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { mediaCampaign } from '../MediaCampaign/lib';
import { campaignComments } from './lib';
import Comments from './views/Comments';

export default function CampaignComments() {
  const t = useT();
  const dispatch = useAppDispatch();
  const params = useParams<{ campaignId: string }>();
  const campaignId = Number(params.campaignId);
  const [loading, setLoading] = useState<boolean>(true);
  const campaign = useAppSelector(mediaCampaign.getCampaign);

  useEffect(() => {
    setLoading(true);
    dispatch(campaignComments.resetComments());
    dispatch(mediaCampaign.fetch(campaignId))
      .then(() => client.postVisit(Number(params.campaignId), 'comments'))
      .finally(() => setLoading(false));
  }, [params.campaignId]);

  return (
    <Page key={'page-comments'}>
      <Helmet>
        <title>{`${t.mediaCampaign.page_title} -${t.campaignComments.page_title}`}</title>
      </Helmet>
      <div className="flex flex-row h-full w-full overflow-auto mx-[-10px] px-[10px]">
        {loading ? (
          <div className="flex h-40 mx-auto">
            <Spinner size={60} />
          </div>
        ) : campaign === undefined ? (
          <div className="flex flex-col justify-start items-start gap-2.5 py-8">
            <h2>
              <Trans
                id={`msg-no-campaign-found`}
                {...{ value: t.mediaCampaign.no_campaign_found, campaignId: zeroFill(params.campaignId, 6, 'E-') }}
              />
            </h2>
          </div>
        ) : (
          <Comments campaign={campaign}></Comments>
        )}
      </div>
    </Page>
  );
}
