import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { authStore } from 'Features/Authentication/lib';
import { actions } from './model';

export const fetch =
  (campaignID: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client
      .getCampaign(campaignID)
      .then(({ data }) => {
        dispatch(actions.set(data));
      })
      .catch((error) => {
        dispatch(actions.set(undefined));
        return Promise.reject(error);
      });

export const addNewOutputMediaTypes =
  (campaignID: number, mediaTypesIds: number[]): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.addNewOutputMedia(campaignID, mediaTypesIds).then(() => dispatch(fetch(campaignID)));

export const deleteOutputMedia =
  (campaignID: number, mediaTypeId: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.deleteCampaignOutputMedia(campaignID, mediaTypeId).then(() => dispatch(fetch(campaignID)));

export const generateAssetForMediaType =
  (campaignID: number, mediaTypeId: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.generateNewAsset().then(({ data }) => dispatch(assignAsset(campaignID, mediaTypeId, data.id)));

export const assignAsset =
  (campaignID: number, mediaTypeId: number, assetId: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.assignAssetCampaign(campaignID, mediaTypeId, assetId).then(() => dispatch(fetch(campaignID)));

export const uploadAsset =
  (assetId: number, file: File): AppAsyncThunk =>
  (dispatch, getState) => {
    const headers = {};
    const token = authStore.selectUserToken(getState());
    headers['Content-Type'] = 'multipart/form-data';
    let data = new FormData();
    data.append('file', file);
    return client.uploadAsset(assetId, data, headers, token).then(() => {
      dispatch(fetchAssetHistory(assetId));
    });
  };

export const fetchAssetHistory =
  (assetId: number): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.getAssetVersions(assetId).then(({ data }) => {
      dispatch(actions.setAssetHistory({ assetId: assetId, assetHistory: data }));
    });
