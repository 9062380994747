import { useEffect, useState } from 'react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { useAppSelector } from 'Config';
import { authStore } from 'Features/Authentication/lib';
import TrackPage from './Tracker';

export default function MatomoGateway({ children }) {
  const [matomoInstance, setMatomoInstance] = useState<any>();
  const user = useAppSelector(authStore.selectUser);

  useEffect(() => {
    if (process.env.REACT_APP_MATOMO_URL && !isNaN(Number(process.env.REACT_APP_MATOMO_SITE_ID)))
      setMatomoInstance(
        createInstance({
          urlBase: process.env.REACT_APP_MATOMO_URL,
          siteId: Number(process.env.REACT_APP_MATOMO_SITE_ID), // 2 Stage
          userId: user?.id,
          heartBeat: {
            // optional, enabled by default
            active: true, // optional, default value: true
            seconds: 30, // optional, default value: `15
          },
          linkTracking: true, // optional, default value: true
          configurations: {
            // optional, default value: {}
            // any valid matomo configuration, all below are optional
            // disableCookies: true,
            // setSecureCookie: true,
            // setRequestMethod: 'POST'
          },
        })
      );
  }, [user]);

  // Tag Snippets
  useEffect(() => {
    // lag between a route changing and the title property updating
    setTimeout(() => {
      var _paq = (window._paq = window._paq || []);
      _paq.push(['setDocumentTitle', document.title]);
      _paq.push(['trackPageView']);
    }, 1000);
  }, []);

  return (
    // @ts-ignore.
    <MatomoProvider value={matomoInstance}>
      <>
        <TrackPage />
        {children}
      </>
    </MatomoProvider>
  );
}
