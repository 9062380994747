import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { BsFillCaretDownFill, BsFillCaretRightFill, BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { mediaTypes } from 'Features/MediaTypes';
import { customStyles } from 'Components/ReactSelect';
import DropdownButton from 'react-multiselect-checkboxes/lib/DropdownButton.js';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import styled from 'styled-components';
import { campaigns } from 'Features/Campaigns';

const CustomSelectOutputMedia = () => {
  const t = useT().homePage;
  const dispatch = useAppDispatch();
  const filters = useAppSelector(campaigns.getFilters);
  const [mediaTypesOptionsIni, initializeMediaTypes] = useState(false);
  const mediaTypesByGroup = useAppSelector(mediaTypes.selectMediaTypesByGroup);

  const [outputMediaOptions, setOutputMediaOptions] = useState<GroupOption[]>([
    { label: t.all, expanded: true, options: [{ value: '*', label: t.all }] },
  ]);

  const allOptions = outputMediaOptions.reduce((acc: OutputMediaOption[], curr) => {
    return acc.concat(curr.options ?? []);
  }, []);

  const [selectedOptions, setSelectedOptions] = useState<OutputMediaOption[]>(allOptions);

  useEffect(() => {
    if (Object.keys(mediaTypesByGroup).length !== 0) {
      const outputMediaOptionsReduced = Object.entries(mediaTypesByGroup).map(([groupName, mediaTypes]) => {
        const mediaTypesOptions = mediaTypes.map((m) => ({ label: m.title, id: m.id, value: m.title }));
        return { label: groupName, options: mediaTypesOptions, expanded: false };
      });
      setOutputMediaOptions([{ label: t.all, expanded: true, options: [{ value: '*', label: t.all }] }].concat(outputMediaOptionsReduced));
      setSelectedOptions(
        [{ label: t.all, expanded: true, options: [{ value: '*', label: t.all }] }]
          .concat(outputMediaOptionsReduced)
          .reduce((acc: OutputMediaOption[], curr) => {
            return acc.concat(curr.options ?? []);
          }, [])
      );
      initializeMediaTypes(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaTypesByGroup]);

  useEffect(() => {
    if (!filters.media_types_ids) {
      dispatch(
        campaigns.actions.setFilters({
          ...filters,
          media_types_ids: selectedOptions?.filter((op) => op.id !== undefined).flatMap((option) => option.id ?? 0),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!mediaTypesOptionsIni) return;
    dispatch(
      campaigns.actions.setFilters({
        ...filters,
        media_types_ids: selectedOptions?.filter((op) => op.id !== undefined).flatMap((option) => option.id ?? 0),
      })
    );

    if (mediaTypesOptionsIni) dispatch(campaigns.actions.setMediaTypesLoaded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const toggle = (data: GroupOption) => {
    setOutputMediaOptions(
      outputMediaOptions.map((s) => {
        if (s.label === data.label) {
          return { ...s, expanded: !data.expanded };
        }
        return s;
      })
    );
  };

  const Group = (props) => {
    const { children, label } = props;
    const expanded = props.data.expanded;

    if (label === t.all) {
      return <div style={{ display: 'block' }}>{children}</div>;
    }

    return (
      <>
        <div onClick={() => toggle(props.data)} className="flex flex-row gap-1 mb-3">
          {expanded ? <BsFillCaretDownFill /> : <BsFillCaretRightFill />}
          {label}
        </div>
        <div style={{ display: expanded ? 'block' : 'none' }}>{children}</div>
      </>
    );
  };

  const CustomDropDownButton = ({ iconAfter, ...props }) => {
    const icon = props.isSelected ? <BsChevronUp size={14} /> : <BsChevronDown size={14} />;
    return (
      <DropdownButton {...props} iconAfter={icon}>
        <p className="px-4">{props.children}</p>
      </DropdownButton>
    );
  };

  const getDropdownButtonLabel = (props) => {
    const { placeholderButtonLabel, value } = props;
    if (value && value.some((o) => o.value === '*')) {
      return `${placeholderButtonLabel}: ${t.all}`;
    } else {
      return `${placeholderButtonLabel}: ${value?.length} ${t.selected}`;
    }
  };

  const onChange = (value, event) => {
    if (event.action === 'select-option' && event.option.value === '*') {
      setSelectedOptions(allOptions);
    } else if (event.action === 'deselect-option' && event.option.value === '*') {
      setSelectedOptions([]);
    } else if (event.action === 'deselect-option') {
      setSelectedOptions(value.filter((o) => o.value !== '*'));
    } else if (value.length === selectedOptions.length) {
      setSelectedOptions(selectedOptions);
    } else {
      setSelectedOptions(value);
    }
  };

  return (
    <SelectWrapper className="relative z-10">
      <ReactMultiSelectCheckboxes
        className="w-full max-w-sm font-normal text-sm leading-4
                       text-gray-60 focus:text-black"
        options={outputMediaOptions}
        components={{ Group, DropdownButton: CustomDropDownButton }}
        styles={customStyles}
        onChange={onChange}
        value={selectedOptions}
        placeholderButtonLabel={t.title_output_media}
        getDropdownButtonLabel={getDropdownButtonLabel}
      />
    </SelectWrapper>
  );
};

export default CustomSelectOutputMedia;

const SelectWrapper = styled.div`
  button {
    box-shadow: none;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 1px solid var(--grey-20);
    border-radius: 0.375rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  [type='checkbox']:checked {
    background-color: var(--primary);
  }
`;
