import { ScaleIconActionAdd } from '@telekom/scale-components-react';
import PublishIcon from 'Assets/icons/PublishIcon';
import { Modal, Spinner } from 'Components';
import { client } from 'Config';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import { authStore } from 'Features/Authentication/lib';
import { mediaTypes } from 'Features/MediaTypes';
import { zeroFill } from 'Helpers';
import React, { useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { mediaCampaign } from '../lib';
import AssignAsset from './AssignAsset';

interface OutputMediaFineDataProps {
  campaign: Campaign;
  campaignMediaType: CampaignMediaType;
}

export default function OutputMediaFineData({ campaign, campaignMediaType }: OutputMediaFineDataProps) {
  const t = useT().mediaCampaign;
  const toastManager = useToasts();
  const dispatch = useAppDispatch();
  const canGenerateAssignAssetID = useAppSelector(authStore.canGenerateAssignAssetID);
  const canUploadFineData = useAppSelector(authStore.canUploadFineData);
  const [uploadingAsset, setLoding] = useState<boolean>(false);
  const [showAssignAsset, setShowAssignAsset] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>(null);
  const mediaTypesStore = useAppSelector(mediaTypes.selectMediaTypes);
  const asset = campaignMediaType.asset;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const assetHistory = useAppSelector(mediaCampaign.getAssetHistory(campaignMediaType.asset?.id || -1));

  const generateAssetId = () => {
    dispatch(mediaCampaign.generateAssetForMediaType(campaign.id, campaignMediaType.id));
  };

  const uploadAsset = (file: File) => {
    if (campaignMediaType.asset) {
      setLoding(true);
      dispatch(mediaCampaign.uploadAsset(campaignMediaType.asset.id, file)).finally(() => setLoding(false));
    }
  };

  const handleFileUpload = (event) => {
    event.target.files[0] && uploadAsset(event.target.files[0]);
  };

  return (
    <>
      <AssignAsset
        campaignId={campaign.id}
        mediaTypeId={campaignMediaType.id}
        isOpen={showAssignAsset}
        onRequestClose={() => {
          setShowAssignAsset(false);
        }}
      ></AssignAsset>
      <div className="flex items-start flex-col justify-between gap-x-8 gap-y-2 text-left whitespace-nowrap w-full relative text-3xl pt-8 border-t-[1px] border-gray-20 sm:flex-row sm:items-center">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-3 items-center flex-wrap">
            <div className="text-xl font-bold whitespace-pre-wrap">{campaignMediaType.title}</div>
            <div className="text-white text-xs px-2.5 py-1 bg-gray-40 border-gray-40 rounded">
              {mediaTypesStore.find((mediaType) => mediaType.id === campaignMediaType.id)?.group || ''}
            </div>
            {!campaignMediaType.active && <p className="text-white text-xs px-2.5 py-1 bg-gray-40 border-gray-40 rounded">{t.disabled}</p>}
          </div>

          <div className="flex flex-row items-center gap-2 text-xs text-gray-60 ">
            <div>{`${t.asset_id}: `}</div>
            {asset ? (
              <a
                className="uppercase no-underline text-blue-60 hover:underline hover:text-blue-70 focus:underline focus:text-blue-70"
                href={`/asset/${campaign.id}/${asset.id}`}
              >
                {zeroFill(asset.id, 6, 'A-')}
              </a>
            ) : (
              <>
                <div>{`[${t.not_assigned}]`}</div>
                {canGenerateAssignAssetID && (
                  <>
                    <button
                      className="text-xs text-primary-500 underline disabled:text-primary-300 disabled:cursor-default"
                      title={t.generate}
                      aria-label={t.generate}
                      onClick={() => {
                        generateAssetId();
                      }}
                      disabled={!!asset}
                    >
                      {t.generate}
                    </button>
                    <button
                      className="text-xs text-primary-500 underline disabled:text-primary-300 disabled:cursor-default"
                      title={t.choose}
                      aria-label={t.choose}
                      onClick={() => {
                        setShowAssignAsset(true);
                      }}
                      disabled={!!asset}
                    >
                      {t.choose}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal
          visible={showConfirmation}
          onRequestClose={() => {
            setShowConfirmation(false);
          }}
          bodyClasses="py-6 px-1 overflow-auto max-w-[450px]"
          header={
            <div className="flex flex-col gap-2 items-start flex-1">
              <h3 className="text-xl font-extrabold break-all flex-1">{t.modalWerbeNetAsset.title}</h3>
            </div>
          }
        >
          <div className="flex gap-[13px] flex-col">
            <h3 className="text-base min-w-[350px]">{t.modalWerbeNetAsset.subtitle}</h3>
            <div className="flex justify-end gap-2 pt-5">
              <button
                title={t.modalWerbeNetAsset.no}
                aria-label={t.modalWerbeNetAsset.no}
                className="py-2 px-4 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
                onClick={() => setShowConfirmation(false)}
              >
                {t.modalWerbeNetAsset.no}
              </button>

              <button
                title={t.modalWerbeNetAsset.yes}
                aria-label={t.modalWerbeNetAsset.yes}
                className="py-2 px-4 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl"
                onClick={() => {
                  client
                    .publicationAnAssetVersion(campaignMediaType.asset?.id ?? -1, assetHistory?.versions[0].version ?? -1)
                    .then(() => {
                      toastManager.addToast(
                        <div role="alert">
                          <div>{t.werbeNetAssetCreation.success}</div>
                        </div>,
                        {
                          appearance: 'success',
                          autoDismiss: true,
                        }
                      );
                    })
                    .catch((error) => {
                      toastManager.addToast(
                        <div role="alert">
                          <div>{t.werbeNetAssetCreation.error}</div>
                        </div>,
                        {
                          appearance: 'error',
                          autoDismiss: true,
                        }
                      );
                    })
                    .finally(() => {
                      setShowConfirmation(false);
                    });
                }}
              >
                {t.modalWerbeNetAsset.yes}
              </button>
            </div>
          </div>
        </Modal>
        <div className="flex">
          {assetHistory?.current_version &&
          assetHistory?.versions.some((version) => version.version === assetHistory.current_version && !version.preview) ? (
            <button
              className="mr-4 flex flex-nowrap gap-2 justify-center items-center text-[16px] text-slate-800 bg-white border-solid border border-gray-500 h-10 max-w-full rounded p-3 disabled:cursor-default disabled:opacity-70"
              onClick={() => {
                setShowConfirmation(true);
              }}
              disabled={!campaignMediaType.active}
            >
              <PublishIcon />
              {t.werbeNetAsset}
            </button>
          ) : null}
          {asset && canUploadFineData ? (
            <>
              <button
                className="flex flex-nowrap gap-2 justify-center items-center text-[16px] text-white bg-primary-500 h-10 max-w-full rounded p-3 disabled:cursor-default disabled:opacity-70"
                title={t.upload_file}
                aria-label={t.upload_file}
                onClick={() => {
                  refInput.current?.click();
                }}
                disabled={uploadingAsset || !campaignMediaType.active}
              >
                {uploadingAsset ? <Spinner size={16} /> : <ScaleIconActionAdd style={{ stroke: 'white' }} size={16} />}
                <div className="truncate">{t.upload_file}</div>
              </button>
              <input className="hidden" ref={refInput} onChange={handleFileUpload} type="file" />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
