import React, { FC, InputHTMLAttributes } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import styled from 'styled-components';
import { ScaleIconContentCalendar } from '@telekom/scale-components-react';
import { Portal } from 'react-portal';

export interface DateTimePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  type: 'date' | 'time' | 'datetime';
  /**
   * function called to change value
   */
  onChangeTime: (date: Date) => void;
  /**
   * input value
   */
  value: any;
  /**
   * placeholder text to show while input is empty
   */
  placeholder?: string;
  /**
   * input name
   */
  name: string;
  /**
   * text to show above input as floating label
   */
  label?: string;

  minDate?: Date;
  /**
   * list of classes to pass to parent container
   * please don't abuse and polute global css
   */
  classNameContainer?: string;
  className?: string;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  type,
  name,
  label,
  placeholder,
  minDate,
  classNameContainer,
  className,
  value,
  onChangeTime,
  disabled,
  ...props
}) => {
  const id = props.id;

  const CustomInput = React.forwardRef(({ placeholder, value, onClick, onChange, name }: any, ref: any) => (
    <CustomInputLabel onClick={onClick}>
      <input
        id={id}
        className={className}
        autoComplete="off"
        name={name}
        onChange={onChange}
        ref={ref}
        type="text"
        placeholder={placeholder}
        value={value}
        {...props}
      ></input>
      {!value && <ScaleIconContentCalendar className="icon" size={16} />}
    </CustomInputLabel>
  ));

  const CalendarContainer = ({ children }) => {
    return <>{children ? <Portal container={null}>{children}</Portal> : <></>}</>;
  };

  return (
    <DatePickerContainer className={`w-full ${classNameContainer || ''}`} id={id + '-label'}>
      <DatePicker
        id={id}
        name={`${name}`}
        selected={value || undefined}
        shouldCloseOnSelect={true}
        placeholderText={[type.includes('date') ? placeholder : null, type.includes('time') ? '--:-- --' : null].join(' ')}
        onChange={(date: Date) => onChangeTime(date)}
        showPopperArrow={false}
        showTimeSelect={type !== 'date'}
        showTimeSelectOnly={type === 'time'}
        timeIntervals={15}
        timeCaption="Time"
        dateFormat={[type.includes('date') ? 'dd/MM/yyyy' : null, ...(type.includes('time') ? ['HH:mm'] : [])].join(' ')}
        minDate={minDate}
        locale={de}
        customInput={<CustomInput />}
        popperClassName="!z-101"
        popperContainer={CalendarContainer}
        isClearable
      />
    </DatePickerContainer>
  );
};

const CustomInputLabel = styled.div`
  cursor: pointer;
  .icon {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    display: inline-block !important;
    width: 1em;
    height: 1em;
    stroke-width: 0;
  }

  input {
    width: 100%;
    padding: 0.5em;
    padding-right: calc(1em + 0.5em + 3px); /* icon width + icon padding-right + desired separation*/
    align-items: center;
    outline: none;
  }
`;

const DatePickerContainer = styled.div`
  .react-datepicker-popper {
    z-index: 99;
  }
`;
