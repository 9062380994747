/* eslint-disable react-hooks/exhaustive-deps */
import { ScaleIconNavigationLeft } from '@telekom/scale-components-react';
import { Page, Spinner } from 'Components';
import Trans from 'Components/Trans';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import AssetTable from 'Features/MediaCampaign/components/AssetTable';
import { mediaTypes } from 'Features/MediaTypes';
import { zeroFill } from 'Helpers';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { mediaCampaign } from '../MediaCampaign/lib';

export default function AssetDetails() {
  const t = useT();
  const dispatch = useAppDispatch();
  const params = useParams<{ campaignId: string; assetId: string }>();
  const campaignId = Number(params.campaignId);
  const campaign = useAppSelector(mediaCampaign.getCampaign);
  const assetId = params.assetId;
  const [loading, setLoading] = useState<boolean>(true);
  const mediaTypesStore = useAppSelector(mediaTypes.selectMediaTypes);
  const campaignMediaType = useAppSelector(mediaCampaign.getCampaign)?.output_media_types.find(
    (mediaType) => mediaType.asset?.id.toString() === assetId
  );
  const asset = campaignMediaType?.asset;

  useEffect(() => {
    setLoading(true);
    dispatch(mediaCampaign.fetch(Number(campaignId))).finally(() => setLoading(false));
  }, [params]);

  return (
    <Page key={'asset-details'}>
      <Helmet>
        <title>{`${t.mediaCampaign.page_title} - ${t.assetDetails.page_title}`}</title>
      </Helmet>
      <div className="flex flex-col h-full w-full overflow-auto mx-[-10px] px-[10px]">
        <div className="flex flex-col justify-start items-start py-6 pt-10 w-full">
          <a
            className="flex flex-nowrap gap-2 items-center text-2xl text-primary-500 pr-2"
            title={t.global.back}
            aria-label={t.global.back}
            href={`/campaign/${params.campaignId}`}
          >
            <ScaleIconNavigationLeft size={16} />
            <span className="lowercase text-sm">{t.global.back}</span>
          </a>
        </div>

        <h2 className="text-3xl text-gray-90 font-extrabold whitespace-pre-wrap">{t.assetDetails.page_title}</h2>

        {loading ? (
          <div className="flex h-40 mx-auto">
            <Spinner size={60} />
          </div>
        ) : !campaign || !asset ? (
          <div className="flex flex-col justify-start items-start gap-2.5 py-8">
            <h2>
              {!campaign ? (
                <Trans
                  id={`msg-no-campaign-found`}
                  {...{ value: t.mediaCampaign.no_campaign_found, campaignId: zeroFill(params.campaignId, 6, 'E-') }}
                />
              ) : (
                <Trans
                  id={`msg-no-asset-found`}
                  {...{
                    value: t.mediaCampaign.no_asset_found,
                    campaignId: zeroFill(params.campaignId, 6, 'E-'),
                    assetId: zeroFill(params.assetId, 6, 'A-'),
                  }}
                />
              )}
            </h2>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-6 py-8 my-8 border-t-[1px] border-b-[1px] border-gray-20">
              <h3 className="text-xl text-gray-90 font-bold">{t.assetDetails.information}</h3>
              <div>
                <label className="text-base text-gray-90 font-bold">{t.assetDetails.asset_id}</label>
                <div className="text-sm text-gray-60">{zeroFill(assetId, 6, 'A-')}</div>
              </div>

              <div>
                <label className="text-base text-gray-90 font-bold">{t.assetDetails.output_medium}</label>
                <div className="text-sm text-gray-60">{campaignMediaType?.title}</div>
              </div>

              <div>
                <label className="text-base text-gray-90 font-bold">{t.assetDetails.output_medium_type}</label>
                <div className="text-sm text-gray-60">{mediaTypesStore.find((mediaType) => mediaType.id === campaignMediaType?.id)?.group || ''}</div>
              </div>
            </div>

            <div className="flex items-start flex-col justify-between pt-6 gap-4 text-left whitespace-nowrap w-full lg:flex-row lg:items-center">
              <h3 className="text-xl text-gray-90 font-bold">{t.assetDetails.files}</h3>
              <div className="flex flex-col gap-x-6 gap-y-2 sm:flex-row sm:items-center">
                <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
                  <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
                  <div className="flex-1 text-sm text-gray-60">{t.mediaCampaign.uploaded}</div>
                </div>
                <div className="flex gap-2 flex-wrap items-center lg:flex-nowrap">
                  <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>
                  <div className="flex-1 text-sm text-gray-60">{t.mediaCampaign.rejected}</div>
                </div>
              </div>
            </div>
            <AssetTable showCheckMark assetId={asset?.id} options={{ preview: true, download: true, reject: true }}></AssetTable>
          </>
        )}
      </div>
    </Page>
  );
}
