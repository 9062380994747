import { AxiosInstance } from 'axios';
import { useT } from 'Config';
import { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

export function ConnectivityMonitor({ api }: { api?: AxiosInstance }) {
  const t = useT().flashMessages;
  const toastManager = useToasts();
  const [isOnline, toggleOnline] = useState(!!window.navigator.onLine);
  const mounted = useRef(false);
  useEffect(() => {
    // prevent show message on inital mount
    if (!mounted.current && isOnline) {
      mounted.current = true;
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);
  useEffect(() => {
    const offline = () => toggleOnline(false);
    const online = () => toggleOnline(true);
    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    };
  }, []);

  useEffect(() => {
    let i: null | number = null;
    if (api) {
      i = api.interceptors.response.use(
        (response) => {
          let message = '';
          switch (response.config.url) {
            case '/cart': {
              message = response.config.method === 'patch' ? t.cart.patch_success : response.config.method === 'delete' ? t.cart.delete_success : '';
              break;
            }
            case '/assets/download': {
              message = t.assets_download.success;
              break;
            }
            case '/assets/share': {
              message = t.assets_share.success;
              break;
            }
            default: {
              break;
            }
          }

          if (message) {
            toastManager.addToast(
              <div role="alert">
                <div>{message}</div>
              </div>,
              {
                appearance: 'success',
                autoDismiss: true,
              }
            );
          }

          return Promise.resolve(response);
        },
        (error) => {
          if (!error.response) {
            toastManager.addToast(
              <div role="alert">
                <strong>{t.server_failure}</strong>
                <div>{t.server_500}</div>
              </div>,
              {
                appearance: 'error',
                autoDismiss: true,
              }
            );
            return Promise.reject(error);
          }
          const url = error.response.config.url;
          const method = error.response.config.method;
          let header = '';
          let message = '';

          if (error.response.status === 404 && method === 'get' && /^\/campaigns/i.test(url)) {
            message = '';
          } else if (error.response.status === 500 || error.response.status === 404) {
            header = `${t.server_failure},  ${error?.response?.status}`;
            message = error.response.status === 500 ? t.server_500 : `${t.server_404} [${url}] ${method}`;
          } else if (error.response.status === 422) {
            header = `${t.server_failure},  ${error?.response?.status}`;
            message = error.response?.data.error;
          } else if (error.response.status === 401) {
            console.warn('session expired...');
          }

          if (message) {
            toastManager.addToast(
              <div role="alert">
                {header && <strong>{header}</strong>}
                <div>{message}</div>
              </div>,
              {
                appearance: 'error',
                autoDismiss: true,
              }
            );
          }

          return Promise.reject(error);
        }
      );
    }
    return () => {
      i !== null && api?.interceptors.response.eject(i);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
