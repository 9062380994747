import { VisuallyHidden } from '@reach/visually-hidden';
import { useT } from 'Config';
import { useEffect, useState } from 'react';
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronRight } from 'react-icons/bs';

type CustomProp = { totalPages: number } | { pageSize: number };
interface PaginationProps {
  totalPages: number;
  totalCount: number;
  currentPage: number;
  onSelect: (n: number) => void;
  loading?: boolean;
}

type Props = PaginationProps & CustomProp;

export const Pagination: React.FC<PaginationProps> = (props: Props) => {
  const { loading, totalCount, currentPage, onSelect } = props;
  const totalPages = 'pageSize' in props ? Math.ceil(totalCount / props.pageSize) : props.totalPages;
  const t = useT();

  const [goPage, setGoPage] = useState<number | undefined>();

  useEffect(() => {
    setGoPage(currentPage);
  }, [currentPage]);

  if (!totalCount || loading) {
    return null;
  }
  const className = (active: boolean) => (active ? 'text-white' : 'border-gray-200 bg-white text-black');

  if (totalPages === 1) {
    return null;
  }
  const gotoButtonsDisabled = {
    first: currentPage <= 2 || !currentPage,
    prev: currentPage - 1 < 1 || !currentPage,
    next: currentPage + 1 > totalPages,
    last: currentPage >= totalPages - 1 || !totalPages,
  };
  return (
    <nav className="flex flex-row flex-wrap gap-8 items-center mt-8 mb-16" aria-label="Pagination">
      <div className="flex flex-row flex-nowrap items-center min-w-[485px]">
        <button
          className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
          title={t.pagination.first_page}
          disabled={gotoButtonsDisabled.first}
          onClick={() => onSelect(1)}
        >
          <VisuallyHidden>{t.pagination.first_page}</VisuallyHidden>
          <BsChevronDoubleLeft />
        </button>
        <button
          className={`flex w-10 h-10 mr-1 justify-center items-center rounded-full border disabled:hidden hover:border-gray-300 ${className(false)}`}
          title={t.pagination.previous_page}
          disabled={gotoButtonsDisabled.prev}
          onClick={() => onSelect(currentPage - 1)}
        >
          <VisuallyHidden>{t.pagination.previous_page}</VisuallyHidden>
          <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
            <path d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
          </svg>
        </button>
        {currentPage - 2 > 0 ? (
          <button
            className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
            title={`${t.pagination.page} ${currentPage - 2}`}
            onClick={() => onSelect(currentPage - 2)}
          >
            {currentPage - 2}
          </button>
        ) : null}
        {currentPage - 1 > 0 ? (
          <button
            className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
            title={`${t.pagination.page} ${currentPage - 1}`}
            onClick={() => onSelect(currentPage - 1)}
          >
            {currentPage - 1}
          </button>
        ) : null}
        <button
          className={`hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border pointer-events-none  ${className(true)}`}
          style={{ backgroundColor: 'var(--primary)', borderColor: 'var(--primary)' }}
          aria-current="page"
          title={`${t.pagination.page} ${currentPage}`}
        >
          {currentPage}
        </button>
        {currentPage + 1 <= totalPages ? (
          <button
            className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
            title={`${t.pagination.page} ${currentPage + 1}`}
            onClick={() => onSelect(currentPage + 1)}
          >
            {currentPage + 1}
          </button>
        ) : null}
        {currentPage + 2 <= totalPages ? (
          <button
            className="hidden md:flex w-10 h-10 mx-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black hover:border-gray-300"
            title={`${t.pagination.page} ${currentPage + 2}`}
            onClick={() => onSelect(currentPage + 2)}
          >
            {currentPage + 2}
          </button>
        ) : null}
        <button
          className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
          title={t.pagination.next_page}
          disabled={gotoButtonsDisabled.next}
          onClick={() => onSelect(currentPage + 1)}
        >
          <VisuallyHidden>{t.pagination.next_page}</VisuallyHidden>
          <svg className="block w-4 h-4 fill-current" viewBox="0 0 256 512" aria-hidden="true" role="presentation">
            <path d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" />
          </svg>
        </button>
        <button
          disabled={gotoButtonsDisabled.last}
          className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white disabled:hidden text-black hover:border-gray-300"
          title={t.pagination.last_page}
          onClick={() => onSelect(totalPages)}
        >
          <VisuallyHidden>{t.pagination.last_page}</VisuallyHidden>
          <BsChevronDoubleRight />
        </button>
        <div className="flex ml-4 h-10 justify-center items-center first-letter:bg-white text-black">
          {currentPage} {t.pagination.out_of} {totalPages}
        </div>
      </div>
      <form
        className="inline-flex gap-2 h-10 justify-center items-center bg-white text-gray-60"
        onSubmit={(e) => {
          e.preventDefault();
          if (goPage) onSelect(goPage);
        }}
      >
        <div>{t.pagination.go_to_page}</div>
        <input
          className="h-[80%] w-20 rounded-lg pr-1 border-gray-60"
          type="number"
          placeholder={t.pagination.page}
          min="1"
          max={totalPages}
          required={true}
          value={goPage}
          onChange={(e) => {
            setGoPage(e.target.value ? parseInt(e.target.value) : undefined);
          }}
        />
        <button
          type="submit"
          className="flex w-10 h-10 ml-1 justify-center items-center rounded-full border border-gray-200 bg-white text-black
          hover:text-primary-500
          hover:bg-gray-200 hover:border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
          title={t.pagination.go_to_page}
        >
          {/* {t.pagination.go} */}
          <BsChevronRight />
        </button>
      </form>
    </nav>
  );
};
