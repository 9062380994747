import React, { useState, useEffect } from 'react';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { FaSort } from 'react-icons/fa';
import tw from 'twin.macro';
import styled from 'styled-components';
import { DateTimePicker, IconButton, Pagination, Spinner } from 'Components';
import { useAppDispatch, useAppSelector, useT, client } from 'Config';
import { campaigns } from 'Features/Campaigns';
import { mediaTypes } from 'Features/MediaTypes';
import { formatISO, parseISO } from 'date-fns';
import CampaignsTableRow from './CampaignsTableRow';
import DownloadDoubleIcon from 'Assets/icons/DownloadDoubleIcon';

export default function CampaignsTable() {
  let typingTimerId: NodeJS.Timeout; //timer identifier
  let typingTimerName: NodeJS.Timeout; //timer identifier
  let typingTimerPlacement: NodeJS.Timeout; //timer identifier
  let doneTypingInterval = 1000; //time in ms (1 seconds)
  const t = useT();
  const dispatch = useAppDispatch();
  const campaignsStore = useAppSelector(campaigns.selectCampaigns);
  const campaignsFilter = useAppSelector(campaigns.getFilters);
  const campaignsSort = useAppSelector(campaigns.getSort);
  const mediaTypesLoaded = useAppSelector(campaigns.mediaTypesLoaded);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterId, setFilterId] = useState<string | undefined>(campaignsFilter.id);
  const [filterName, setFilterName] = useState<string | undefined>(campaignsFilter.name);
  const [filterPlacement, setFilterPlacement] = useState<string | undefined>(campaignsFilter.placement_t);

  const sort = useAppSelector(campaigns.getSort);
  const allMediaTypes = useAppSelector(mediaTypes.selectMediaTypes).length;
  const pagination = useAppSelector(campaigns.getPagination);
  const groupBy = useAppSelector(mediaTypes.selectMediaTypesByGroup);
  const [selectedIDsDownload, setIDsDownload] = useState<Set<number>>(new Set());
  const [showSpinnerDownload, setShowSpinnerDownload] = useState(false);

  useEffect(() => {
    if (!mediaTypesLoaded) return;
    if (campaignsFilter.state) {
      setLoading(true);
      dispatch(campaigns.fetch(campaignsFilter, sort, pagination.current_page)).finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(campaignsFilter), sort, pagination.current_page, mediaTypesLoaded]);

  useEffect(() => {
    if (filterId !== campaignsFilter.id || filterName !== campaignsFilter.name || filterPlacement !== campaignsFilter.placement_t)
      dispatch(campaigns.actions.setFilters({ ...campaignsFilter, id: filterId, name: filterName, placement_t: filterPlacement }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterId, filterName, filterPlacement]);

  const stickyColumnsSize = [
    { width: 170, left: 0 },
    { width: 120, left: 170 },
    { width: 150, left: 290 },
    { width: 120, left: 440 },
    { width: 124, left: 560 },
    { width: 124, left: 684 },
  ];
  const allCampaignsMediaTypesIds = campaignsStore
    .reduce((ids: number[], campaign) => {
      return ids.concat(campaign.output_media_types.map((mediaType) => mediaType.id) || []);
    }, [])
    .filter((value, index, self) => self.indexOf(value) === index)
    .filter(
      (campaignId) =>
        campaignsFilter.media_types_ids?.length === 0 ||
        campaignsFilter.media_types_ids === undefined ||
        campaignsFilter.media_types_ids.length === allMediaTypes ||
        campaignsFilter.media_types_ids.some((id) => id === campaignId)
    );

  const groupTypes = Object.keys(groupBy).reduce((ac: Record<string, Array<MediaType>>, groupName) => {
    return {
      ...ac,
      [groupName]: groupBy[groupName].filter((mediaType) => allCampaignsMediaTypesIds.includes(mediaType.id)),
    };
  }, {});

  const cols = Object.entries(groupTypes)
    .map(([k, v], idx) => v.map((mediaType) => mediaType.title))
    .flat() as string[];

  const colsIds = Object.entries(groupTypes)
    .map(([k, v], idx) => v.map((mediaType) => mediaType.id))
    .flat() as number[];

  const setPage = (page) => {
    dispatch(campaigns.actions.setPagination({ ...pagination, current_page: page }));
  };

  const downloadMultiCampaignAssets = () => {
    client
      .downloadMultiCampaignAssets(Array.from(selectedIDsDownload), false)
      .then((response) => {
        setShowSpinnerDownload(false);
        const url = response.data.download_link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${formatISO(new Date(), { representation: 'date' })}_${'all'}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowSpinnerDownload(false);
        setIDsDownload(new Set());
      });
  };

  return (
    <>
      <div className=" relative max-h-[70vh] w-[fit-content] max-w-full overflow-auto border-[1px] border-gray-20 rounded-lg">
        <TableContainer className="max-w-full table-fixed border-separate border-0 ">
          <thead>
            <tr>
              {['id', 'download', 'name', 'placement', 'start_date', 'end_date'].map((col, index) => (
                <Th
                  key={`th-${col}`}
                  rowSpan={2}
                  className={`align-top z-[3] ${col === 'end_date' && 'border-r-4 border-gray-20'} `}
                  style={{
                    left: stickyColumnsSize[index].left,
                    width: stickyColumnsSize[index].width,
                    maxWidth: stickyColumnsSize[index].width,
                    minWidth: stickyColumnsSize[index].width,
                  }}
                >
                  {col === 'download' ? (
                    <div className="flex flex-nowrap gap-x-2 justify-center items-center px-2 truncate font-extrabold">{t.homePage.tabel[col]}</div>
                  ) : (
                    <button
                      className="flex flex-nowrap gap-x-2 justify-center items-center px-2 truncate font-extrabold"
                      onClick={() => {
                        let key = col as keyof Campaign;
                        dispatch(campaigns.actions.setSort(campaignsSort !== key ? key : campaignsSort === key ? `-${key}` : key));
                      }}
                    >
                      <>
                        {campaignsSort === col ? (
                          <BsFillCaretDownFill size={8} />
                        ) : campaignsSort === `-${col}` ? (
                          <BsFillCaretUpFill size={8} />
                        ) : (
                          <FaSort size={10} className="opacity-30" />
                        )}
                      </>
                      <div className="truncate">{t.homePage.tabel[col]}</div>
                    </button>
                  )}

                  {col === 'start_date' || col === 'end_date' ? (
                    <DateTimePicker
                      key={col}
                      type={'date'}
                      required={true}
                      name={col}
                      value={campaignsFilter && campaignsFilter[col] && parseISO(campaignsFilter[col]!)}
                      placeholder={t.global.date}
                      classNameContainer="w-full mt-2"
                      className="w-full p-2 rounded-md block shadow-lg border-gray-20 border-solid border text-sm focus:outline-none"
                      onChangeTime={(date) => {
                        dispatch(
                          campaigns.actions.setFilters({ ...campaignsFilter, [col]: date ? formatISO(date, { representation: 'date' }) : undefined })
                        );
                      }}
                    ></DateTimePicker>
                  ) : col === 'download' ? (
                    <div className="flex items-center justify-center w-full">
                      {showSpinnerDownload ? (
                        <Spinner size={30} strokeColor="var(--primary)" top="0.5rem" />
                      ) : (
                        <IconButton
                          className="w-[50%] p-2 mt-2 rounded-md text-white block shadow-lg focus:outline-none"
                          disabled={!selectedIDsDownload.size}
                          onClick={() => {
                            setShowSpinnerDownload(true);
                            downloadMultiCampaignAssets();
                          }}
                        >
                          <DownloadDoubleIcon size={20} />
                        </IconButton>
                      )}
                    </div>
                  ) : (
                    <SearchInput
                      type="search"
                      name={col}
                      defaultValue={campaignsFilter && campaignsFilter[col]}
                      title={(campaignsFilter && campaignsFilter[col]) || ''}
                      onChange={(e) => {
                        if (col === 'id') {
                          clearTimeout(typingTimerId);
                          typingTimerId = setTimeout(function () {
                            setFilterId(e.target.value.length ? e.target.value : undefined);
                          }, doneTypingInterval);
                        } else if (col === 'name') {
                          clearTimeout(typingTimerName);
                          typingTimerName = setTimeout(function () {
                            setFilterName(e.target.value.length ? e.target.value : undefined);
                          }, doneTypingInterval);
                        } else if (col === 'placement') {
                          clearTimeout(typingTimerPlacement);
                          typingTimerPlacement = setTimeout(function () {
                            setFilterPlacement(e.target.value.length ? e.target.value : undefined);
                          }, doneTypingInterval);
                        }
                      }}
                      placeholder={t.homePage.tabel.search}
                    />
                  )}
                </Th>
              ))}
              {Object.keys(groupTypes).map((grp, idx) => (
                <React.Fragment key={`fragment-${grp}-${idx}`}>
                  {[...Array(Object.keys(groupTypes[grp]).length)].map((i, index) => (
                    <Th
                      key={`type-${grp}-${index}`}
                      className={`font-extrabold truncate h-[40px] ${index !== 0 ? '!z-[1] bg-gray-10' : 'z-[2]'}`}
                      style={{
                        left: stickyColumnsSize.reduce((sum, column) => sum + column.width, 0),
                        width: 200,
                        maxWidth: 200,
                        minWidth: 200,
                      }}
                      colSpan={1}
                    >
                      {index === 0 && grp}
                    </Th>
                  ))}
                </React.Fragment>
              ))}
            </tr>
            <tr className="">
              {!cols.length && (
                <Th
                  style={{ top: 0, left: stickyColumnsSize.reduce((sum, stickyColum) => sum + stickyColum.width, 0) }}
                  className="z-[2] w-[200px] max-w-[200px] min-w-[200px] font-normal"
                >
                  {`[${t.homePage.tabel.no_output_medium}]`}
                </Th>
              )}
              {cols.map((col, index) => (
                <Th
                  key={`subtype-${col}-${index}`}
                  style={{ top: 40, left: stickyColumnsSize.reduce((sum, stickyColum) => sum + stickyColum.width, 0) }}
                  className="z-[2] w-[200px] max-w-[200px] min-w-[200px] font-normal"
                >
                  {col}
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="bg-white ">
                <td className="text-center h-40 text-lg font-extrabold text-gray-50" colSpan={5}>
                  <Spinner size={60} />
                </td>
              </tr>
            ) : (
              <>
                {!campaignsStore.length && (
                  <tr className="bg-white ">
                    <td className="text-center text-lg font-extrabold text-gray-50 h-[125px]" colSpan={5}>
                      {t.homePage.tabel.no_actions}
                    </td>
                  </tr>
                )}
                {campaignsStore.map((campaign, index) => (
                  <CampaignsTableRow
                    key={index}
                    campaign={campaign}
                    index={index}
                    colsIds={colsIds}
                    stickyColumnsSize={stickyColumnsSize}
                    selectedIDsDownload={selectedIDsDownload}
                    setIDsDownload={setIDsDownload}
                  />
                ))}
              </>
            )}
          </tbody>
        </TableContainer>
      </div>
      <Pagination currentPage={pagination.current_page} onSelect={setPage} totalCount={pagination.total_count} totalPages={pagination.total_pages} />
    </>
  );
}

const Th = tw.th`sticky top-0 p-2 text-left bg-gray-10`;
const SearchInput = tw.input`w-full p-2 mt-2 rounded-md block shadow-lg border-gray-20 border-solid border text-sm focus:outline-none`;

const TableContainer = styled.table`
  border-spacing: 0;
`;
