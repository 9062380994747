import { ScaleIconActionAdd, ScaleIconActionRemove, ScaleIconContentDraftFile } from '@telekom/scale-components-react';
import { Modal } from 'Components';
import { useAppDispatch, useAppSelector, useT } from 'Config';
import { authStore } from 'Features/Authentication/lib';
import { mediaTypes } from 'Features/MediaTypes';
import { zeroFill } from 'Helpers';
import { useState } from 'react';
import { mediaCampaign } from '../lib';
import AssignAsset from './AssignAsset';

interface OutputMediaProps {
  campaign: Campaign;
  campaignMediaType: CampaignMediaType;
}

export default function OutputMedia({ campaign, campaignMediaType }: OutputMediaProps) {
  const t = useT().mediaCampaign;
  const dispatch = useAppDispatch();
  const mediaTypesStore = useAppSelector(mediaTypes.selectMediaTypes);
  const userEmedia = useAppSelector(authStore.selectEmediaUser);
  const canGenerateAssignAssetID = useAppSelector(authStore.canGenerateAssignAssetID);
  const canUploadFineData = useAppSelector(authStore.canUploadFineData);
  const canDeleteOutputMedia = useAppSelector(authStore.canDeleteOutputMedia);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showAssignAsset, setShowAssignAsset] = useState<boolean>(false);

  const deleteOutputMedia = () => {
    dispatch(mediaCampaign.deleteOutputMedia(campaign.id, campaignMediaType.id)).finally(() => setShowConfirmation(false));
  };

  const generateAssetId = () => {
    dispatch(mediaCampaign.generateAssetForMediaType(campaign.id, campaignMediaType.id));
  };
  return (
    <>
      <AssignAsset
        campaignId={campaign.id}
        mediaTypeId={campaignMediaType.id}
        isOpen={showAssignAsset}
        onRequestClose={() => {
          setShowAssignAsset(false);
        }}
      ></AssignAsset>
      <Modal
        visible={showConfirmation}
        onRequestClose={() => {
          setShowConfirmation(false);
        }}
        bodyClasses="py-6 px-1 overflow-auto"
        header={
          <div className="flex flex-col gap-2 items-start flex-1">
            <h3 className="text-xl font-extrabold break-all flex-1">{t.modalDeleteOutputMedia.title}</h3>
          </div>
        }
      >
        <div className="flex gap-[13px] flex-col">
          <h3 className="text-base min-w-[350px]">{t.modalDeleteOutputMedia.subtitle}</h3>
          <div className="flex justify-end gap-2 pt-5">
            <button
              title={t.modalDeleteOutputMedia.no}
              aria-label={t.modalDeleteOutputMedia.no}
              className="py-2 px-4 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
              onClick={() => setShowConfirmation(false)}
            >
              {t.modalDeleteOutputMedia.no}
            </button>

            <button
              title={t.modalDeleteOutputMedia.yes}
              aria-label={t.modalDeleteOutputMedia.yes}
              className="py-2 px-4 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl"
              onClick={() => deleteOutputMedia()}
            >
              {t.modalDeleteOutputMedia.yes}
            </button>
          </div>
        </div>
      </Modal>
      <div
        key={`output-media-item-${campaignMediaType.id}`}
        className={`${
          !campaignMediaType.active && 'bg-gray-20'
        } inline-flex items-center justify-between gap-2 text-left whitespace-nowrap w-full h-20 shadow-media rounded-xl relative px-6 py-4 text-3xl `}
      >
        {!campaignMediaType.asset && <div className="absolute z-20 left-[4.5px] top-[4.5px] h-4 w-4 bg-red-70 rounded-full"></div>}
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-3 items-center">
            <div className="text-xl font-bold">{campaignMediaType.title}</div>
            {(canDeleteOutputMedia || (campaign.project_manager && userEmedia?.id === campaign.project_manager.id)) && (
              <button
                className="hover:text-primary-500"
                title={t.assetTable.delete}
                aria-label={t.assetTable.delete}
                onClick={() => {
                  setShowConfirmation(true);
                }}
              >
                <ScaleIconActionRemove size={16} />
              </button>
            )}
            <div className="text-white text-xs px-2.5 py-1 bg-gray-40 border-gray-40 rounded">
              {mediaTypesStore.find((mediaType) => mediaType.id === campaignMediaType.id)?.group || ''}
            </div>
            {!campaignMediaType.active && <p className="text-white text-xs px-2.5 py-1 bg-gray-40 border-gray-40 rounded">{t.disabled}</p>}
          </div>

          <div className="flex flex-row items-center gap-2 text-xs text-gray-60 ">
            <div>{`${t.asset_id}: `}</div>
            {campaignMediaType.asset ? (
              <a
                className="uppercase no-underline text-blue-60 hover:underline hover:text-blue-70 focus:underline focus:text-blue-70"
                href={`/asset/${campaign.id}/${campaignMediaType.asset.id}`}
              >
                {zeroFill(campaignMediaType.asset.id, 6, 'A-')}
              </a>
            ) : (
              <>
                <div>{`[${t.not_assigned}]`}</div>
                {canGenerateAssignAssetID && (
                  <>
                    <button
                      className="text-xs text-primary-500 underline disabled:text-primary-300 disabled:cursor-default"
                      title={t.generate}
                      aria-label={t.generate}
                      onClick={() => {
                        generateAssetId();
                      }}
                      disabled={!!campaignMediaType.asset}
                    >
                      {t.generate}
                    </button>
                    <button
                      className="text-xs text-primary-500 underline disabled:text-primary-300 disabled:cursor-default"
                      title={t.choose}
                      aria-label={t.choose}
                      onClick={() => {
                        setShowAssignAsset(true);
                      }}
                      disabled={!!campaignMediaType.asset}
                    >
                      {t.choose}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {campaignMediaType.asset ? (
          <>
            {campaignMediaType.asset.status === 'ready' ? (
              <div className="relative flex flex-nowrap gap-2 justify-center items-center text-[16px] text-primary-500 h-24 w-36 bg-gray-0 shadow-media rounded-xl">
                <a
                  className="flex flex-nowrap gap-2 justify-center items-center text-black rounded p-3"
                  title={t.view_fine_data}
                  aria-label={t.view_fine_data}
                  href={`/campaign/${campaign.id}/finedata`}
                >
                  <ScaleIconContentDraftFile size={35} />
                </a>
              </div>
            ) : (
              <>
                {canUploadFineData && (
                  <div className="relative flex flex-nowrap gap-2 justify-center items-center text-[16px] text-primary-500 h-24 w-36 bg-gray-0 shadow-media rounded-xl">
                    <div className="absolute z-20 left-[4.5px] top-[4.5px] h-4 w-4 bg-red-70 rounded-full"></div>
                    <a
                      className="flex flex-nowrap gap-2 justify-center items-center text-[16px] text-white bg-primary-500 h-10 max-w-full rounded p-3"
                      title={t.fine_data}
                      aria-label={t.fine_data}
                      href={`/campaign/${campaign.id}/finedata`}
                    >
                      <ScaleIconActionAdd style={{ stroke: 'white' }} size={16} />
                      <div className="truncate">{t.fine_data}</div>
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
