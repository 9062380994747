import { useRef } from 'react';
import { createGlobalStyle } from 'styled-components';

interface I<R, C> {
  stick: Array<number>;
  width: 200;
  cols: C[];
  rows: R[];
  renderCol: (n: C, idx: number) => JSX.Element;
  renderRow: (n: R, c: C, rowidx: number, colidx: number) => JSX.Element;
}

export default function App<R, C>({ stick = [0], width = 200, cols, rows, renderCol, renderRow }: I<R, C>) {
  const ref = useRef<HTMLDivElement>(null);

  function onScroll(e) {
    if (ref.current) {
      ref.current.scrollLeft = e.target.scrollLeft;
    }
    console.log(e.target.scrollLeft);
  }
  let o = 0;
  return (
    <>
      <GlobalScrollBarStyles />
      <div ref={ref} style={{ maxWidth: '100%', overflow: 'hidden' }}>
        <table style={{ marginRight: 7 }}>
          <tr>
            {cols.map((col, idx) => (
              <th
                style={
                  stick.indexOf(idx) > -1
                    ? {
                        position: 'sticky',
                        left: width * o++,
                        backgroundColor: 'gray',
                      }
                    : {}
                }
              >
                {renderCol(col, idx)}
              </th>
            ))}
          </tr>
        </table>
      </div>
      <div onScroll={onScroll} style={{ width: '100%', flex: 1, overflow: 'auto' }}>
        <table>
          <tbody>
            {rows.map((row, rowIdx) => (
              <tr>
                {((o = 0), null)}
                {cols.map((col, idx) => (
                  <td
                    style={
                      stick.indexOf(idx) > -1
                        ? {
                            position: 'sticky',
                            left: width * o++,
                          }
                        : {}
                    }
                  >
                    {renderRow(row, col, rowIdx, idx)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export const GlobalScrollBarStyles = createGlobalStyle`
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
`;
