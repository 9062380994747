import { useAppDispatch, useT } from 'Config';

import { FormatDate, zeroFill } from 'Helpers';
import { ScaleIconCommunicationMessage, ScaleIconNavigationLeft } from '@telekom/scale-components-react';
import { useNavigate } from 'react-router';
import { campaigns } from 'Features/Campaigns';
import AlertInformation from 'Assets/icons/AlertInformation';
interface Props {
  campaign: Campaign;
}

export default function OutputMediaHeader({ campaign }: Props) {
  const t = useT();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col justify-center items-start gap-2.5 mr-10 py-8 border-b-2 border-gray-20">
      <button
        className="flex flex-nowrap gap-2 items-center text-2xl text-primary-500"
        title={t.global.back_to_overview}
        aria-label={t.global.back_to_overview}
        onClick={() => {
          dispatch(campaigns.actions.setResetFilter(false));
          navigate(`/`);
        }}
      >
        <ScaleIconNavigationLeft size={16} />
        <span className="text-sm">{t.global.back_to_overview}</span>
      </button>

      <h5 className="text-xs text-gray-60">{zeroFill(campaign.id, 6, 'EMA_')}</h5>

      <div className="flex flex-row items-center gap-4">
        <h4 className="text-3xl font-extrabold">{campaign.name}</h4>
        <a
          className="flex flex-nowrap"
          title={t.homePage.tabel.action_details}
          aria-label={t.homePage.tabel.action_details}
          href={`/info/${campaign.id}`}
        >
          <AlertInformation size={22} {...(!campaign.description_viewed && { strokeColor: 'var(--primary)' })} />
        </a>
        <a className="flex flex-nowrap" title={t.global.comments} aria-label={t.global.comments} href={`/comments/${campaign.id}`}>
          <ScaleIconCommunicationMessage size={22} />
        </a>
      </div>
      <h5 className="text-xs text-gray-60">
        <span>{FormatDate(campaign.start_date || '', true)}</span>
        <span>{' - '}</span>
        <span>{campaign.end_date ? FormatDate(campaign.end_date, true) : t.newMedia.form.unlimited_term}</span>
      </h5>
    </div>
  );
}
