export const global = {
  e_media: 'E-Media',
  back: 'Zurück',
  info: 'Information',
  comments: 'Kommentare',
  completed: 'Erledigt',
  date: 'Datum',
  claim: 'Eleben, was verbindet.',
  to_home_page: 'Zur Startseite',
  back_to_overview: 'Zurück zur Übersicht',
};

export const login = {
  welcome: 'Welcome !',
  greet: (name = '') => `Welcome back ${name} to Amana Trading`,
};
export const appHeader = {
  new_media: 'Neue E-Media-Aktion',
  overview: 'Übersicht',
  overview_all_campaigns: 'Übersicht aller Aktionen',
  dropDownOptions: {
    profile: 'Profil',
    my_space: 'Mein Bereich',
    admin: 'Admin',
    logOut: 'Vom SSO abmelden',
  },
  serviceCategory: {
    selfService: 'Self-Service',
    faqs: 'FAQs',
    help: 'Hilfe',
  },
};

export const customAccordion = {
  hide: 'Verbergen',
  show: 'Anzeigen',
};

export const pagination = {
  first_page: 'Erste Seite',
  previous_page: 'Vorherige Seite',
  next_page: 'Nächste Seite',
  last_page: 'Letzte Seite',
  out_of: 'von',
  page: 'Seite',
  go_to_page: 'Gehe zu Seite',
  go: 'Geh',
};

export const flashMessages = {
  cart: {
    patch_success: 'Artikel wurde(n) erfolgreich in den Warenkorb gelegt',
    patch_error: 'Artikel konnte(n) nicht in den Warenkorb gelegt werden',
    delete_success: 'Artikel wurde(n) erfolgreich aus dem Warenkorb gelöscht',
    delete_error: 'Artikel konnte(n) nicht aus dem Warenkorb gelöscht werden',
  },
  assets_download: {
    success: 'Artikel wurde(n) erfolgreich heruntergeladen',
    error: 'Artikel konnte(n) nicht heruntergeladen werden',
  },
  assets_share: {
    success: 'Die E-Mail wurde erfolgreich gesendet',
    error: 'Die E-Mail konnte nicht gesendet werden',
  },
  excel_download_error: 'Bericht konnte nicht heruntergeladen werden',
  server_failure: 'Ops, Serverfehler!',
  server_500: 'Anfrage fehlgeschlagen. Bitte kontaktieren Sie den Systemadministrator.',
  server_404: 'Keine Route stimmt überein mit',
  server_422: 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut!',
};

export const homePage = {
  title: 'Übersicht aller Aktionen',
  dropdown_label: 'Anzeigen lassen:',
  find_asset_id: 'Asset-ID suchen:',
  excel_download: 'Excel herunterladen',
  activated_output_media: 'Aktivierte',
  deactivated_output_media: 'Deaktivierte',
  title_output_media: 'Ausgabemedien',
  all: 'Alle',
  selected: 'ausgewählte',
  dropdownOptions: {
    all: 'Alle Aktionen',
    active: 'Alle aktiven Aktionen',
    upcoming_campaigns: 'Alle anstehenden Aktionen',
    running_campaigns: 'Alle laufenden Aktionen',
    past_campaigns: 'Alle vergangenen Aktionen',
    disabled_campaigns: 'Alle deaktivierten Aktionen',
    output_media_without_asset_id: 'Alle Aktionen mit Ausgabemedien ohne Asset-ID',
    campaigns_with_asset_id_no_finedata: 'Alle Aktionen mit Asset-Ids ohne Feindaten',
    selected: '%{selections} von %{options} ausgewählt',
  },
  assetStatus: {
    ready: 'zum Download bereit',
    in_progress: 'in Erstellung',
    rejected: 'zurückgewiesen',
  },

  tabel: {
    id: 'ID',
    name: 'Aktion',
    download: 'Download',
    placement: 'Platzierung',
    start_date: 'Online am',
    end_date: 'Letzter Tag',
    search: 'Suche',

    to_the_desk: 'zum Desk',
    action_details: 'Aktions-Details',
    download_all_files: 'Alle Dateien herunterladen',
    download_files_preview: 'Alle Preview Dateien herunterladen',
    replaced_by: 'Wird ersetzt durch',
    no_id_yet: 'noch keine ID',
    unlimited: 'unbegrenzt',
    no_output_medium: 'Kein Ausgabemedium',
    no_actions: 'Es gibt keine Aktionen',
  },
  new_media: 'Neue E-Media Aktion',
};

export const newMedia = {
  page_title: 'Neue E-Media-Aktion',
  enter_data: 'Geben Sie alle benötigten Daten ein.',
  form: {
    id: 'ID',
    name: 'Name',
    online_from: 'Online am',
    online_until: 'Letzter Anzeigetag',
    online_until_validity: 'Bitte wählen Sie ein Datum oder aktivieren Sie "unbegrenzte Laufzeit"',
    unlimited_term: 'unbegrenzte Laufzeit',
    description: 'Beschreibung',
    checkbox_label: 'Löst die Aktion eine bestehende Aktion ab?',
    checkbox_description: 'Ja, folgende Aktion wird abgelöst:',
    action_already_blocked: 'Diese Aktion wird bereits durch %{campaignId} abgelöst. Bitte wählen Sie eine andere!',
    action_not_existing: 'Diese Aktion existiert nicht.',
    action_selected_is_valid: 'Die ausgewählte Aktion ist gültig',
    action_replace_remove: 'Es wird keine Aktion ersetzt',
    add_action_ad: 'ID muss ausgefüllt sein!',
  },
  discard: 'Verwerfen',
  create: 'Erstellen',
  campaign_created: 'Die Kampagne wurde erfolgreich erstellt',
  no_actions: 'Es gibt keine Aktionen',
};

export const copyMedia = {
  page_title: 'E-Media-Aktion kopieren',
  form: {
    action_name: 'Aktionsname',
    start_date: 'Online am',
    end_date: 'Letzter Tag',
    replace_action: 'Ersetzen Sie die kopierte Aktion',
    replace_action_hint: 'Wenn sie "Ja" wählen, wird das Ende Datum der kopierten Aktion automatisch angepasst',
  },
  discard: 'Verwerfen',
  create: 'Erstellen',
  copy: 'Kopie',
  yes: 'Ja',
  no: 'Nein',
  success: 'Erfolgreich erstellte Kampagne!',
};

export const mediaCampaign = {
  inactive_user: 'Inaktiver User',
  page_title: 'E-Media-Aktion',
  no_campaign_found: 'E-Media-Aktion mit der angegebenen ID ("%{campaignId}") nicht gefunden.',
  no_asset_found: 'E-Media-Aktion "%{campaignId}" enthält nicht das Asset "%{assetId}".',
  output_media: 'Ausgabemedien',
  fine_data: 'Feindaten',
  fine_data_of_action: 'Feindaten von Aktion',
  uploaded: 'hochgeladen',
  rejected: 'zurückgewiesen',
  upload_file: 'Datei hochladen',
  view_fine_data: 'Feindaten anzeigen',
  output_medium: 'Ausgabemedium',
  task_overview: 'Task-Übersicht',
  tasks_on_desk: 'Ihre Tasks auf diesem Desk',
  disabled: 'Deaktiviert',
  modalDeleteOutputMedia: {
    title: 'Sind Sie sicher?',
    subtitle: 'Wollen Sie das Ausgabemedium wirklich löschen? Alle Eingaben gehen dadurch verloren.',
    no: 'Nein, abbrechen',
    yes: 'Ja, löschen',
  },
  modalWerbeNetAsset: {
    title: 'Sind Sie sicher?',
    subtitle:
      'Mit dieser Funktion legen Sie die zuletzt hochgeladene Datei dieses Ausgabemediums als Asset im WerbeNet an. Die Datei kann dadurch den Handelspartnern zur Verfügung gestellt werden. Wollen Sie fortfahren?',
    no: 'Nein, abbrechen',
    yes: 'Ja, WerbeNet Asset erstellen',
  },
  werbeNetAssetCreation: {
    success: 'WerbeNet Asset wurde erfolgreich erstellt',
    error: 'WerbeNet Asset wurde nicht erstellt!',
  },
  werbeNetAsset: 'WerbeNet Asset erstellen',
  tasksMessages: {
    create_output_media: 'Legen Sie die Ausgabemedien für diese EMA an',
    completed_all_tasks: 'Sie haben keine offenen Tasks auf diesem Desk.',
    generate_asset_id: 'Generieren Sie eine Asset-ID für das Ausgabemedium %{mediaTypeName}.',
    upload_data: 'Laden Sie die Feindaten für das Asset %{assetId} hoch.',
  },
  addMedia: {
    title: 'Ausgabemedien auswählen',
    subtitle: 'Haken Sie die Ausgabemedien an, die Sie der Aktion hinzufügen möchten.',
    select_all: 'alle auswählen',
    deselect_all: 'alle abwählen',
    selection_from: 'Auswahl übernehmen von:',
    no_selected: 'keine EMA ausgewählt',
    discard_selection: 'Auswahl verwerfen',
    create_selection: 'Auswahl anlegen',
  },
  assetTable: {
    status: 'Status',
    version: 'Version',
    preview: 'Preview',
    file_name: 'Dateiname',
    uploaded_on: 'Hochgeladen am',
    uploaded_by: 'Hochgeladen von',
    comment: 'Bemerkung',
    reason_rejection: 'Ablehnungsgrund',
    correction_requested_on: 'Korrektur erbeten am',
    leave_comment: 'Kommentar speichern',
    exit: 'Ausfahrt',
    download: 'Herunterladen',
    delete: 'Löschen',
    reject: 'Ablehnen',
    modalDelete: {
      title: 'Sind Sie sicher?',
      subtitle: 'Wollen Sie die Datei wirklich löschen?',
      no: 'Nein, abbrechen',
      yes: 'Ja, löschen',
    },
    modalReject: {
      title: 'Asset ablehnen',
      id: 'Version',
      subtitle: 'Ablehnungsgrund',
      no: 'Abbrechen',
      yes: 'Zurückweisen',
    },
  },
  modalAssignAsset: {
    title: 'Asset-ID auswählen',
    subtitle: 'Wählen Sie das Asset aus, das Sie dem Ausgabemedium hinzufügen möchten.',
    first_created_for_action: 'Erstmals erstellt für Aktion',
    search: 'Suche',
    no_assets_found: 'Keine Assets gefunden',
    no: 'Abbrechen',
    yes: 'Asset-ID auswählen',
  },
  asset_id: 'Asset-ID',
  not_assigned: 'noch nicht zugewiesen',
  generate: 'Generieren',
  choose: 'Auswählen',
  error_download: 'Asset kann nicht heruntergeladen werden',
};

export const campaignComments = {
  page_title: 'Kommentare',
  newComment: {
    write_comment_title: 'Kommentar verfassen',
    write_comment: 'Schreibe einen Kommentar',
    write_answer_title: 'Antwort verfassen',
    write_answer: 'Schreibe eine Antwort',
    abort: 'Abbrechen',
    leave_comment: 'Kommentieren',
  },
  edit_comment: 'Kommentar bearbeiten',
  delete_comment: 'Kommentar löschen',
  update_comment: 'Kommentar aktualisieren',
  processed: 'bearbeitet',
  exit: 'Ausfahrt',
  answer: 'Antworten',
  answers_loading: 'Antworten werden geladen',
  hide_answers: 'Antworten verbergen',
  show_answers: 'Antworten anzeigen',
  modalDelete: {
    title: 'Sind Sie sicher?',
    subtitle: 'Wollen Sie den Kommentar wirklich löschen?',
    no: 'Nein, abbrechen',
    yes: 'Ja, löschen',
  },
};

export const dashboard = {
  page_title: 'Mein Bereich',
  tabs: {
    tasks: 'Tasks',
    comments: 'Kommentare',
    infos: 'Infos',
    e_mails: 'E-Mails',
  },
  tabTasks: {
    dropdown_label: 'Anzeigen:',
    filterOptions: {
      all: 'alle',
      completed: 'erledigt',
      created: 'ausstehend',
    },
    tabel: {
      no_data: 'Keine Daten',
      status: 'Status',
      action: 'Aktion',
      task: 'Task',
      received_at: 'Erhalten am',
      pending_since: 'Ausstehend seit',
      done_on: 'Erledigt am',
      to_the_desk: 'zum Desk',
    },
  },
  tabComments: {
    dropdown_label: 'Anzeigen:',
    filterOptions: {
      all: 'alle',
      new: 'neu',
      old: 'alt',
    },
    tabel: {
      no_data: 'Keine Daten',
      status: 'Status',
      action: 'Aktion',
      written_by: 'Verfasst von',
      comment: 'Kommentar',
      posted: 'Verfasst am',
      to_the_comment: 'zum Kommentar',
    },
  },
  tabInfos: {
    dropdown_label: 'Anzeigen:',
    filterOptions: {
      all: 'alle',
      new: 'neu',
      old: 'alt',
    },
    tabel: {
      no_data: 'Keine Daten',
      status: 'Status',
      action: 'Aktion',
      info: 'Info',
      received_at: 'Erhalten am',
      to_the_desk: 'zum Desk',
    },
  },
  tabEMails: {
    tab_title: 'E-Mail Einstellungen',
    any_time: 'Ich möchte jederzeit über neue Tasks, Kommentare und Infos per E-Mail benachrichtigt werden.',
    daily: 'Ich möchte nur einmal täglich per E-Mail benachrichtigt werden.',
    twice_a_week: 'Ich möchte nur zweimal pro Woche per E-Mail benachrichtigt werden.',
    disabled: 'Ich möchte keine E-Mails erhalten.',
  },
};

export const assetDetails = {
  page_title: 'Asset-Details',
  information: 'Informationen',
  asset_id: 'Asset-ID',
  output_medium: 'Ausgabemedium',
  output_medium_type: 'Typ des Ausgabemediums',
  files: 'Dateien',
};

export const campaignDetails = {
  page_title: 'Aktions-Details',
  edit: 'bearbeiten',
  cancel: 'abbrechen',
  save: 'speichern',
  loading_project_managers: 'Projektmanager werden geladen',
  form: {
    id: 'ID',
    name: 'Name',
    online_from: 'Online am',
    online_until: 'Letzter Anzeigetag',
    unlimited_term: 'unbegrenzte Laufzeit',
    description: 'Beschreibung',
    number_output_media: 'Anzahl Ausgabemedien',
    output_media: 'Ausgabemedien',
    created_on: 'Erstellt am',
    created_by: 'Erstellt von',
    last_changed_at: 'Zuletzt geändert am',
    project_manager: 'Projektmanager*in',
    job_number: 'Jobnummer',
    not_specified: 'keine Angabe',
    replaced_by: 'Wird abgelöst durch',
    replacing_action: 'Löst folgende Aktion ab',
    placement_category: 'Platzierungskategorie',
  },
  confirmationModal: {
    title: 'Sind Sie sicher?',
    subtitle: 'Wollen Sie die Änderungen wirklich speichern?',
    deactivate_action: 'Wollen Sie die Aktion wirklich deaktivieren?',
    reactivate_action: 'Wollen Sie die Aktion wirklich reaktivieren?',
    delete_action: 'Wollen Sie die Aktion wirklich endgültig löschen? Alle Daten gehen dadurch unwiderruflich verloren.',
    copy_action: 'Wollen Sie die Aktion wirklich kopieren?',
    no: 'Nein, abbrechen',
    yes: 'Ja, speichern',
    deactivate_yes: 'Ja, deaktivieren',
    reactivate_yes: 'Ja, reaktivieren',
    delete_yes: 'Ja, löschen',
    copy_yes: 'Ja, kopieren',
  },

  blockedByNote: 'Diese Aktion wird von der Aktion %{blockedById} abgelöst.',
  deactivate_action: 'Aktion deaktivieren',
  reactivate_action: 'Aktion reaktivieren',
  delete_action: 'Aktion löschen',
  copy_action: 'Aktion kopieren',
  action_is_disabled: 'Diese Aktion wurde deaktiviert.',
};

export const noData = {
  title: 'Es tut uns leid...',
  message: 'Aufgrund Ihrer Userberechtigungen kann Ihnen die Ansicht nicht angezeigt werden. Bitte wenden Sie sich an den Support.',
};
