import { Tabs, TabList, Tab, TabPanels, TabPanel, TabsOrientation } from '@reach/tabs';
import '@reach/tabs/styles.css';
import styled from 'styled-components';

import React from 'react';

interface TabsProps {
  Icon?: React.ReactNode;
  label?: string;
  tabIndex?: number;
  items: Array<{
    icon?: React.ReactNode;
    label: string;
    props?: any;
    tabContent: React.ReactNode;
  }>;
  handleTabsChange?(tabIndex: number): void;
}

export function CustomTabs(props: TabsProps) {
  const { tabIndex, items, handleTabsChange } = props;
  return (
    <Container className="flex flex-col flex-1" index={tabIndex} onChange={handleTabsChange} orientation={TabsOrientation.Horizontal}>
      <TabList>
        {(items || []).map((item, index) => (
          <Tab
            key={`tab_${index}`}
            className="transition flex flex-nowrap gap-1 justify-center items-center py-1.5 w-min whitespace-nowrap text-base font-bold rounded-lg hover:text-primary-500"
          >
            {item.icon}
            <div> {item.label}</div>
          </Tab>
        ))}
      </TabList>

      <TabPanels className="flex-1 flex flex-col">
        {(items || []).map((item, index) => (
          <TabPanel className="h-full" key={`tab_panel_${index}`} tabIndex={-1}>
            {item.tabContent}
          </TabPanel>
        ))}
      </TabPanels>
    </Container>
  );
}

const Container = styled(Tabs)`
  [data-reach-tab-list] {
    flex-wrap: wrap;
    background: unset !important;
    column-gap: 2rem;
    row-gap: 0.5rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    padding-bottom: 1rem;
  }

  [data-reach-tab] {
    border-color: #dcdcdc !important;
    border-radius: 5px;
  }

  [data-reach-tab][data-selected] {
    color: var(--primary);
    border-color: var(--primary) !important;
    border-bottom-width: 4px;
    font-weight: 500;
  }
`;
