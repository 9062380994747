import { AxiosError } from 'axios';
import { Pagination, Spinner } from 'Components';
import { client, useT } from 'Config';
import { Fragment, useEffect, useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { useToasts } from 'react-toast-notifications';

interface Props {
  campaign: Campaign;
}

export default function TaskOverview({ campaign }: Props) {
  const t = useT().mediaCampaign;
  const [tasksCompleted, setTasksCompleted] = useState<Task[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 0,
    total_count: 0,
    total_pages: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const toastManager = useToasts();
  useEffect(() => {
    setLoading(true);
    client
      .getCampaignTasks(campaign.id, { status: 'created' })
      .then((response) => {
        setPagination(response.data.pagination_meta);
        setTasksCompleted([
          ...tasksCompleted,
          ...tasks.filter((task) => response.data.tasks.findIndex((responseTask) => responseTask.id === task.id) < 0),
        ]);
        setTasks(response.data.tasks);
      })
      .catch((error: AxiosError) => {
        toastManager.addToast(
          <div role="alert">
            <div>{error.message}</div>
          </div>,
          {
            appearance: 'error',
            autoDismiss: true,
          }
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  const setPage = (page) => {
    setLoading(true);
    client
      .getCampaignTasks(campaign.id, { page: page, status: 'created' })
      .then((response) => {
        setPagination(response.data.pagination_meta);
        setTasks(response.data.tasks);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <div className="flex flex-col gap-7 ml-10 py-8">
        <h4 className="text-3xl font-extrabold pt-16">{t.tasks_on_desk}</h4>
        {loading ? (
          <div className="flex h-40 mx-auto">
            <Spinner size={60} />
          </div>
        ) : (
          <>
            {tasksCompleted?.map((task, index) => (
              <div key={`tasks-completed-${index}`} className="flex gap-4 flex-wrap items-center lg:flex-nowrap">
                <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
                <div className="flex-1 text-sm text-gray-60">{task.title}</div>
              </div>
            ))}
            {tasks?.map((task, index) => (
              <div key={`task-${index}`} className="flex gap-4 flex-wrap items-center lg:flex-nowrap">
                {task.status === 'completed' ? (
                  <div className={`flex-none inline-block h-4 w-4 rounded-full bg-green-60`}></div>
                ) : (
                  <div className={`flex-none inline-block h-4 w-4 rounded-full bg-red-70`}></div>
                )}
                <div className="flex-1 text-sm text-gray-60">{task.title}</div>
              </div>
            ))}

            <Pagination
              currentPage={pagination.current_page}
              onSelect={setPage}
              totalCount={pagination.total_count}
              totalPages={pagination.total_pages}
            />

            {(tasks?.length === 0 || tasks.every((task) => task.status === 'completed')) && (
              <div className="flex gap-4 items-center text-base text-gray-90 font-bold bg-green-10 p-3.5 rounded-2xl">
                <BsCheckCircle size={16} className="flex-none inline-block" />
                <div>{t.tasksMessages.completed_all_tasks}</div>
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
}
