type ComponentProps = {
  fill?: string;
};

const PublishIcon: React.FC<ComponentProps> = ({ fill }) => (
  <svg width="18" height="15" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.932129V13.4321C0 15.0891 1.343 16.4321 3 16.4321H5V14.9321H3C2.173 14.9321 1.5 14.2591 1.5 13.4321V3.93213H16.5V13.4321C16.5 14.2591 15.827 14.9321 15 14.9321H13V16.4321H15C16.657 16.4321 18 15.0891 18 13.4321V0.932129H0ZM2 3.05713C1.655 3.05713 1.375 2.77713 1.375 2.43213C1.375 2.08713 1.655 1.80713 2 1.80713C2.345 1.80713 2.625 2.08713 2.625 2.43213C2.625 2.77713 2.345 3.05713 2 3.05713ZM3.375 2.43213C3.375 2.77713 3.655 3.05713 4 3.05713C4.345 3.05713 4.625 2.77713 4.625 2.43213C4.625 2.08713 4.345 1.80713 4 1.80713C3.655 1.80713 3.375 2.08713 3.375 2.43213ZM6 3.05713C5.655 3.05713 5.375 2.77713 5.375 2.43213C5.375 2.08713 5.655 1.80713 6 1.80713C6.345 1.80713 6.625 2.08713 6.625 2.43213C6.625 2.77713 6.345 3.05713 6 3.05713ZM9.0005 5.68213L13.4185 12.4321H9.75V19.1821C9.75 19.5961 9.414 19.9321 9 19.9321C8.586 19.9321 8.25 19.5961 8.25 19.1821V12.4321H4.582L9.0005 5.68213Z"
      fill="#262626"
    />
  </svg>
);

export default PublishIcon;
