import { client } from 'Config';
import { AppAsyncThunk } from 'Config/store';
import { actions } from './model';

export const fetch =
  (active?: boolean): AppAsyncThunk =>
  (dispatch, _getState) =>
    client.getMediaTypes({ active: active }).then(({ data }) => {
      dispatch(actions.set(data));
    });
