import { client, useAppDispatch, useAppSelector, useT } from 'Config';
import { Modal, Spinner } from 'Components';
import React, { useEffect, useState } from 'react';
import { mediaCampaign } from '../lib';
import AssetPreview from './AssetPreview';
import AssetRejection from './AssetRejection';
import AssetTableRow from './AssetTableRow';

interface AssetTableProps {
  assetId?: number;
  options?: {
    preview?: boolean;
    download?: boolean;
    delete?: boolean;
    reject?: boolean;
  };
  showCheckMark?: boolean;
}

export default function AssetTable({
  assetId,
  options = { preview: true, download: true, delete: true, reject: true },
  showCheckMark,
}: AssetTableProps) {
  const t = useT().mediaCampaign;
  const dispatch = useAppDispatch();
  const [deleteVersion, setDeleteVersion] = useState<number>(-1);
  const [rejectVersion, setRejectVersion] = useState<number>(-1);
  const [loadingTable, setLoading] = useState<boolean>(false);
  const [assetVersionPreview, setAssetVersionPreview] = useState<AssetVersion>();
  const assetHistory = useAppSelector(mediaCampaign.getAssetHistory(assetId || -1));
  const [rejectedComment, setRejectedComment] = useState<string>('');

  useEffect(() => {
    if (assetId) {
      setLoading(true);
      dispatch(mediaCampaign.fetchAssetHistory(assetId)).finally(() => setLoading(false));
    }
  }, [assetId, dispatch]);

  const deleteAssetVersion = (version: number) => {
    if (assetId && assetHistory) {
      setLoading(true);
      client
        .deleteAssetVersion(assetId, version)
        .then(() => {
          assetId && dispatch(mediaCampaign.fetchAssetHistory(assetId));
        })
        .finally(() => {
          setLoading(false);
          setDeleteVersion(-1);
        });
    }
  };

  const rejectAssetVersion = (version: number) => {
    if (assetId && assetHistory) {
      setLoading(true);
      client
        .rejectAssetVersion(assetId, version, rejectedComment)
        .then(() => {
          assetId && dispatch(mediaCampaign.fetchAssetHistory(assetId));
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <React.Fragment>
      {assetVersionPreview && <AssetPreview assetVersion={assetVersionPreview} onClose={() => setAssetVersionPreview(undefined)}></AssetPreview>}
      <Modal
        visible={deleteVersion > 0}
        onRequestClose={() => {
          setDeleteVersion(-1);
        }}
        bodyClasses="py-6 px-1 overflow-auto"
        header={
          <div className="flex flex-col gap-2 items-start flex-1">
            <h3 className="text-xl font-extrabold break-all flex-1">{t.assetTable.modalDelete.title}</h3>
          </div>
        }
      >
        <div className="flex gap-[13px] flex-col">
          <h3 className="text-base min-w-[350px]">{t.assetTable.modalDelete.subtitle}</h3>
          <div className="flex justify-end gap-2 pt-5">
            <button
              title={t.assetTable.modalDelete.no}
              aria-label={t.assetTable.modalDelete.no}
              className="py-2 px-4 text-base font-bold text-gray-90 bg-gray-0 border-[1px] border-gray-90 rounded-xl"
              onClick={() => setDeleteVersion(-1)}
            >
              {t.assetTable.modalDelete.no}
            </button>

            <button
              title={t.assetTable.modalDelete.yes}
              aria-label={t.assetTable.modalDelete.yes}
              className="py-2 px-4 text-base font-bold text-white bg-primary-500 border-[1px] border-primary-500 rounded-xl"
              onClick={() => deleteAssetVersion(deleteVersion)}
            >
              {t.assetTable.modalDelete.yes}
            </button>
          </div>
        </div>
      </Modal>
      <AssetRejection
        rejectVersion={rejectVersion}
        setRejectVersion={setRejectVersion}
        rejectAssetVersion={rejectAssetVersion}
        setRejectedComment={setRejectedComment}
      />

      {loadingTable ? (
        <div className="flex h-24 mx-auto">
          <Spinner size={30} />
        </div>
      ) : (
        <div className="overflow-auto mb-8 mt-6 rounded-t-lg">
          {assetHistory && !!assetHistory.versions.length && (
            <table className="min-w-full divide-y divide-gray-200 break-all">
              <thead className="bg-gray-0 font-extrabold text-sm text-gray-100">
                <tr>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap w-[56px] min-w-[56px]">
                    {t.assetTable.status}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap w-[75px] min-w-[75px]">
                    {t.assetTable.version}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap w-[65px] min-w-[65px]">
                    {t.assetTable.preview}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[130px]">
                    {t.assetTable.file_name}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[116px]">
                    {t.assetTable.uploaded_on}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[116px]">
                    {t.assetTable.uploaded_by}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[116px]">
                    {t.assetTable.comment}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[170px]">
                    {t.assetTable.correction_requested_on}
                  </th>

                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[145px]">
                    {t.assetTable.reason_rejection}
                  </th>
                  <th scope="col" className="px-2 py-3 text-left whitespace-nowrap min-w-[85px]"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 font-normal text-sm">
                {assetHistory?.versions.map((assetVersion, index) => (
                  <AssetTableRow
                    assetVersion={assetVersion}
                    index={index}
                    options={options}
                    showCheckMark={showCheckMark}
                    setDeleteVersion={setDeleteVersion}
                    setRejectVersion={setRejectVersion}
                    setLoading={setLoading}
                    setAssetVersionPreview={setAssetVersionPreview}
                    setRejectedComment={setRejectedComment}
                    assetId={assetId}
                    key={index}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </React.Fragment>
  );
}
