import { BsChevronDown } from 'react-icons/bs';

export function SplashScreen() {
  return (
    <div className="w-60 h-24 border-2 rounded-md mx-auto mt-20">
      <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
        <div className="w-12 bg-gray-300 h-12 rounded-full "></div>
        <div className="flex flex-col space-y-3">
          <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
          <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
        </div>
      </div>
    </div>
  );
}

export function InputsSkeleton({ count = 3 }) {
  const elements: JSX.Element[] = [];
  for (let index = 0; index < count; index++) {
    elements.push(
      <div key={index} className="max-w-[220px] items-center w-full my-4 h-[40px] border-2 rounded-md flex animate-pulse">
        <div className="flex-1 h-full bg-gray-300 "></div>
        <div className="mx-4">
          <BsChevronDown />
        </div>
      </div>
    );
  }
  return <div className="w-full h-full flex flex-wrap mt-6">{elements}</div>;
}

export function CardSkeleton({ count = 3 }) {
  const elements: JSX.Element[] = [];
  for (let index = 0; index < count; index++) {
    elements.push(
      <div key={index} className="w-[220px] h-[320px] border-2 rounded-md m-4 mr-2">
        <div className="flex animate-pulse flex-col h-full justify-around items-center">
          <div className="w-2/3 bg-gray-300 h-2/4 rounded-xl "></div>
          <div className="w-2/3">
            <div className="w-full bg-gray-300 h-6 mb-4 rounded-md "></div>
            <div className="w-2/3 bg-gray-300 h-6 mb-4 rounded-md "></div>
          </div>
        </div>
      </div>
    );
  }
  return <div className="w-full h-full flex flex-wrap">{elements}</div>;
}

export function Skeleton() {
  return (
    <div className="w-60 h-24 border-2 rounded-md mx-auto mt-20">
      <div className="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
        <div className="w-12 bg-gray-300 h-12 rounded-full "></div>
        <div className="flex flex-col space-y-3">
          <div className="w-36 bg-gray-300 h-6 rounded-md "></div>
          <div className="w-24 bg-gray-300 h-6 rounded-md "></div>
        </div>
      </div>
    </div>
  );
}
