type ComponentProps = {
  fill?: string;
  size?: number;
  color?: string;
};

const DownloadDoubleIcon: React.FC<ComponentProps> = ({ fill = 'currentColor', size, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 17 16" fill={fill ?? 'none'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.42688 11.2639L8.85156 11.9473L9.27624 11.2639L12.2762 6.43632L12.751 5.67241H11.8516H9.84069V1.51724C9.84069 0.99193 9.41453 0.5 8.85156 0.5C8.28859 0.5 7.86243 0.99193 7.86243 1.51724V5.67241H5.85156H4.95217L5.42688 6.43632L8.42688 11.2639Z"
      stroke={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3609 14.8H1.56094C1.24094 14.8 0.960938 15.08 0.960938 15.4C0.960938 15.72 1.24094 16 1.56094 16H16.3609C16.6809 16 16.9609 15.72 16.9609 15.4C16.9609 15.08 16.6809 14.8 16.3609 14.8Z"
      fill={color}
    />
  </svg>
);

export default DownloadDoubleIcon;
